export default {
    "name": "ChangePassword",
    "kind": "HoudiniMutation",
    "hash": "02f79584b9663353b8583de5f4e2cf4db2315af1b53ca797e7356fa1bfcc797f",

    "raw": `mutation ChangePassword($newPassword: String!, $userName: String!, $resetToken: String) {
  changePassword(
    newPassword: $newPassword
    userName: $userName
    resetToken: $resetToken
  )
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "changePassword": {
                "type": "Boolean",
                "keyRaw": "changePassword(newPassword: $newPassword, resetToken: $resetToken, userName: $userName)",
                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "newPassword": "String",
            "userName": "String",
            "resetToken": "String"
        },

        "types": {},
        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=f1ae75b1d1fad624448abf2cfa78e1a609778168fb9bb460cb3b17f9f98e6d6f";