export default {
    "name": "GetPasswordRules",
    "kind": "HoudiniQuery",
    "hash": "5f4c7698c7a732fda4891fa8ebb1fb04d198def057e670b60ed4a7f49507d2d3",

    "raw": `query GetPasswordRules {
  getPasswordRules {
    minimumPasswordLength
    maximumPasswordLength
    numberOfRequiredLowercaseCharacters
    numberOfRequiredUppercaseCharacters
    numberOfRequiredNumericCharacters
    numberOfRequiredSpecialCharacters
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "getPasswordRules": {
                "type": "PasswordRulesResult",
                "keyRaw": "getPasswordRules",

                "selection": {
                    "fields": {
                        "minimumPasswordLength": {
                            "type": "String",
                            "keyRaw": "minimumPasswordLength",
                            "visible": true
                        },

                        "maximumPasswordLength": {
                            "type": "String",
                            "keyRaw": "maximumPasswordLength",
                            "visible": true
                        },

                        "numberOfRequiredLowercaseCharacters": {
                            "type": "String",
                            "keyRaw": "numberOfRequiredLowercaseCharacters",
                            "visible": true
                        },

                        "numberOfRequiredUppercaseCharacters": {
                            "type": "String",
                            "keyRaw": "numberOfRequiredUppercaseCharacters",
                            "visible": true
                        },

                        "numberOfRequiredNumericCharacters": {
                            "type": "String",
                            "keyRaw": "numberOfRequiredNumericCharacters",
                            "visible": true
                        },

                        "numberOfRequiredSpecialCharacters": {
                            "type": "String",
                            "keyRaw": "numberOfRequiredSpecialCharacters",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=08c0c1cd2b5844b5996115fb4a2365d4aa1c3173195cf40374699b0007232ede";