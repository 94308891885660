export default {
    "name": "UpdatePlants",
    "kind": "HoudiniMutation",
    "hash": "42e5d823f8084c51281da9eb77adaa05694712705498c66465c394a41818e7b3",

    "raw": `mutation UpdatePlants($plants: [PlantUpdate!]!) {
  updatePlants(plants: $plants) {
    id
    name
    code
    private
    street
    city
    state
    zip
    country
    phone
    fax
    timezone
    tags {
      id
      name
      entityType
      active
    }
    getPlantUsageCount {
      analyseCount
      productCount
      tagCount
    }
  }
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "updatePlants": {
                "type": "Plant",
                "keyRaw": "updatePlants(plants: $plants)",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "name": {
                            "type": "String",
                            "keyRaw": "name",
                            "visible": true
                        },

                        "code": {
                            "type": "String",
                            "keyRaw": "code",
                            "visible": true
                        },

                        "private": {
                            "type": "Boolean",
                            "keyRaw": "private",
                            "visible": true
                        },

                        "street": {
                            "type": "String",
                            "keyRaw": "street",
                            "visible": true
                        },

                        "city": {
                            "type": "String",
                            "keyRaw": "city",
                            "visible": true
                        },

                        "state": {
                            "type": "String",
                            "keyRaw": "state",
                            "visible": true
                        },

                        "zip": {
                            "type": "String",
                            "keyRaw": "zip",
                            "visible": true
                        },

                        "country": {
                            "type": "String",
                            "keyRaw": "country",
                            "visible": true
                        },

                        "phone": {
                            "type": "String",
                            "keyRaw": "phone",
                            "visible": true
                        },

                        "fax": {
                            "type": "String",
                            "keyRaw": "fax",
                            "visible": true
                        },

                        "timezone": {
                            "type": "String",
                            "keyRaw": "timezone",
                            "visible": true
                        },

                        "tags": {
                            "type": "EntityTag",
                            "keyRaw": "tags",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    },

                                    "entityType": {
                                        "type": "EntityTagType",
                                        "keyRaw": "entityType",
                                        "visible": true
                                    },

                                    "active": {
                                        "type": "Boolean",
                                        "keyRaw": "active",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "getPlantUsageCount": {
                            "type": "PlantUsageCount",
                            "keyRaw": "getPlantUsageCount",

                            "selection": {
                                "fields": {
                                    "analyseCount": {
                                        "type": "NonNegativeInt",
                                        "keyRaw": "analyseCount",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "productCount": {
                                        "type": "NonNegativeInt",
                                        "keyRaw": "productCount",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "tagCount": {
                                        "type": "NonNegativeInt",
                                        "keyRaw": "tagCount",
                                        "nullable": true,
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "plants": "PlantUpdate"
        },

        "types": {
            "PlantUpdate": {
                "city": "String",
                "code": "String",
                "country": "String",
                "fax": "String",
                "id": "PositiveInt",
                "logoFileId": "PositiveInt",
                "name": "String",
                "phone": "String",
                "private": "Boolean",
                "state": "String",
                "street": "String",
                "tagIds": "PositiveInt",
                "timezone": "String",
                "zip": "String"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=0847e3b0a11214193543223f73bc3a3ba65202c9e3317aa532992aa324c875b0";