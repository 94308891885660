import { getSession } from 'stores/session'
import type { AppContext } from 'types/common'
import component from './Configuration.svelte'
import { AsrNavTabBar } from '@isoftdata/svelte-nav-tab-bar'
import type { ComponentProps } from 'svelte'
import { writable, type Writable } from 'svelte/store'
import SaveResetButton from '@isoftdata/svelte-save-reset-button/dist/SaveResetButton.svelte'

type Tabs = ComponentProps<AsrNavTabBar>['tabs']

export default function ({ stateRouter, i18next }: AppContext) {
	stateRouter.addState({
		name: 'app.configuration',
		route: 'configuration',
		defaultChild: 'alert-subscription',
		template: {
			svelte: true,
			component,
		},
		resolve(_data, _parameters) {
			const session = getSession()

			const tabs: Tabs = [
				{
					name: 'app.configuration.alert-subscription',
					title: i18next.t('configuration.titles.alertSubscriptions', 'Alert Subscriptions'),
					icon: {
						class: 'fa-solid-alarm-clock-gear',
						prefix: 'fak',
					},
				},
				{
					name: 'app.configuration.user-group',
					title: i18next.t('configuration.titles.groups', 'Groups'),
					iconClass: 'users',
					disabled: session.user.permissions.CONFIGURATION_GROUP_MANAGER !== 'GLOBAL',
				},
				{
					name: 'app.configuration.plant',
					title: i18next.t('configuration.titles.plant', 'Plant'),
					iconClass: 'industry-windows',
					disabled: session.user.permissions.CONFIGURATION_CAN_CONFIGURE_PLANTS === 'NONE',
				},
				{
					name: 'app.configuration.settings',
					title: i18next.t('configuration.titles.settings', 'Settings'),
					iconClass: 'sliders',
					// All users can set their own settings but the screen has to manage the plant and global settings permissions
				},
				{
					name: 'app.configuration.user',
					title: i18next.t('configuration.titles.users', 'Users'),
					iconClass: 'user',
					disabled: session.user.permissions.CONFIGURATION_USER_MANAGER === 'NONE',
				},
				{
					name: 'app.configuration.work-order-type',
					title: i18next.t('configuration.titles.workOrderTypes', 'Work Order Types'),
					icon: {
						class: 'fa-solid-clipboard-list-check-gear',
						prefix: 'fak',
					},
					disabled: session.user.permissions.CONFIGURATION_CAN_CONFIGURE_WORK_ORDER_TYPES === 'NONE',
				},
			]

			return Promise.resolve({
				tabs,
				saveResetProps: writable<ComponentProps<SaveResetButton> | null>(null),
			})
		},
	})
}

export type SaveResetProps = Writable<ComponentProps<SaveResetButton> | null>
