export default {
    "name": "RerunScheduleNow",
    "kind": "HoudiniMutation",
    "hash": "ecbb9411fb75c1e8c9fc36689e056ca579ea2cc5e9475499d5612226437e5f75",

    "raw": `mutation RerunScheduleNow {
  rerunScheduledWorkOrdersNow {
    success
    lastDataCloneId
    dataClonesExecuted
    lastCloneError
  }
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "rerunScheduledWorkOrdersNow": {
                "type": "RerunScheduledWorkOrdersResult",
                "keyRaw": "rerunScheduledWorkOrdersNow",
                "nullable": true,

                "selection": {
                    "fields": {
                        "success": {
                            "type": "Boolean",
                            "keyRaw": "success",
                            "visible": true
                        },

                        "lastDataCloneId": {
                            "type": "NonNegativeInt",
                            "keyRaw": "lastDataCloneId",
                            "nullable": true,
                            "visible": true
                        },

                        "dataClonesExecuted": {
                            "type": "NonNegativeInt",
                            "keyRaw": "dataClonesExecuted",
                            "visible": true
                        },

                        "lastCloneError": {
                            "type": "String",
                            "keyRaw": "lastCloneError",
                            "nullable": true,
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    }
};

"HoudiniHash=8c4d0d8fde34274adce8bfef4e84f4a0f22e49aa793fc27e6ee282c5382146c8";