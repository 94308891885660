export default {
    "name": "ShGetGeneratedDescription",
    "kind": "HoudiniQuery",
    "hash": "b7b8ff6b9dcb48c4aebec8956516238319225ebbfed380ba519909fd13620c74",

    "raw": `query ShGetGeneratedDescription($searchState: SampleSearchStateInput!) {
  generateSampleSearchDescription(searchState: $searchState)
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "generateSampleSearchDescription": {
                "type": "String",
                "keyRaw": "generateSampleSearchDescription(searchState: $searchState)",
                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "input": {
        "fields": {
            "searchState": "SampleSearchStateInput"
        },

        "types": {
            "SampleAnalysisOptionsSearchState": {
                "expirationDateRange": "String",
                "expirationFrom": "String",
                "expirationTo": "String",
                "id": "Int",
                "lotNumber": "String",
                "resultStatus": "ResultStatus",
                "valueFrom": "String",
                "valueTo": "String"
            },

            "SampleAnalysesSearchState": {
                "id": "Int",
                "options": "SampleAnalysisOptionsSearchState",
                "resultStatus": "ResultStatus"
            },

            "SampleSearchDateRange": {
                "from": "Int",
                "to": "Int",
                "type": "SampleDateRangeType"
            },

            "SampleSearchStateInput": {
                "analyses": "SampleAnalysesSearchState",
                "dateRange": "SampleSearchDateRange",
                "isRetest": "Boolean",
                "locationName": "String",
                "matchAllOptionFilters": "Boolean",
                "optionsAreInclusive": "Boolean",
                "performedByUserId": "Int",
                "performedByUserName": "String",
                "plantIds": "Int",
                "processZoneId": "Int",
                "processZoneName": "String",
                "productBatchName": "String",
                "productName": "String",
                "productProximityId": "Int",
                "productProximityName": "String",
                "samplingComments": "String",
                "scheduleName": "String",
                "statuses": "DocumentStatus",
                "tagNumber": "String",
                "testingComments": "String",
                "workOrderInternalNotes": "String",
                "workOrderNumber": "String",
                "workOrderTitle": "String",
                "workOrderTypeId": "Int",
                "workOrderTypeName": "String"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=8975829ae42f0585912554814856374db8f0a2fef6104ff7fc9b4897c08149fc";