<script
	context="module"
	lang="ts"
>
	import type { WorkOrderSearch$result, WorkOrderSearch$input } from '$houdini'

	export type WorkOrderSearchFilter = WorkOrderSearch$input['filter']
	export type WorkOrderSearchPagination = WorkOrderSearch$input['pagination']
	export type WorkOrderSearchOrderBy = WorkOrderSearch$input['orderBy']
	export type WorkOrderSearchResult = WorkOrderSearch$result['workOrders']['data'][0]

	//end module section
</script>

<script lang="ts">
	import type { Writable } from 'type-fest'
	import type { SortDirection } from '@isoftdata/svelte-table'
	import type { CrudStore } from '@isoftdata/svelte-store-crud'
	import type { SvelteAsr, Mediator, i18n, HasPermission } from 'types/common'

	import { graphql } from '$houdini'
	import Button from '@isoftdata/svelte-button'
	import Select from '@isoftdata/svelte-select'
	import Checkbox from '@isoftdata/svelte-checkbox'
	import DateRange from '@isoftdata/svelte-date-range'
	import setUserSetting from 'utility/set-user-setting'
	import { booleanToString } from '@isoftdata/utility-boolean'
	import Table, { Td, type Column } from '@isoftdata/svelte-table'
	import SiteAutocomplete from '@isoftdata/svelte-site-autocomplete'
	import { datesFromRange, rangeFromDates } from '@isoftdata/utility-date-time'
	import { getContext, onMount, onDestroy, type ComponentEvents } from 'svelte'

	const workOrderSearchQuery = graphql(`
		query WorkOrderSearch($filter: ManyWorkOrdersFilter, $pagination: PaginatedInput, $orderBy: WorkOrderOrderByFilter) {
			workOrders(filter: $filter, pagination: $pagination, orderBy: $orderBy) {
				data {
					favorite
					documentStatus
					plant {
						id
						name
						code
					}
					id
					workOrderType {
						id
						name
					}
					title
					scheduled
					dateCreated
					due
					internalNotes
					instructions
					productBatch {
						name
					}
					assignedToGroup {
						name
					}
					verificationDue
					verifiedByUser {
						fullName
					}
					verifiedOn
				}
				info {
					pageNumber
					totalItems
					totalPages
				}
			}
		}
	`)

	const { t: translate } = getContext<i18n>('i18next')
	const hasPermission = getContext<HasPermission>('hasPermission')

	type Group = { id: number; name: string }
	type Plant = { id: number; name: string; code: string }
	type WorkOrderType = { id: number; name: string }

	export let workOrderFavoritesStore: CrudStore<WorkOrderSearchResult, 'id'>
	export let selectedPlantId: number
	export let plants: Array<Plant> = []
	export let workOrderTypes: Array<WorkOrderType> = []
	export let groups: Array<Group> = []
	export let showFavoriteCheckbox: boolean = false
	export let pageSize: number = 50
	export let tablePageNumber: number = 1
	export let orderByColumn: string | undefined = undefined // This is for the ss-pagination to know which column
	export let orderByDirection: SortDirection | undefined = undefined // This is for the ss-pagination to know which direction
	export let showOpenDocuments = true
	export let showClosedDocuments = false
	export let selectedWorkOrderIds: Array<number> = []
	export let selectedWorkOrderTypeId: number | null = null
	export let selectedGroupId: number | null = null
	export let favoriteWorkOrdersOnly: boolean = false
	export let dateRange: { from: string; to: string } = datesFromRange('Last 30 Days')
	export let asrGoToSearchPath: ((filter: NonNullable<WorkOrderSearchFilter>, pagination: NonNullable<WorkOrderSearchPagination>, orderBy: NonNullable<WorkOrderSearchOrderBy>) => void) | undefined =
		undefined
	export let asr: SvelteAsr | undefined = undefined
	export let disableSelection: boolean = false
	export let highlightOverdue: boolean = false
	export let showEditLink: boolean = false
	export let visibleColumns: Array<ColumnPropertyUnion> | null = null

	let workOrderSearchTable: Table<WorkOrderSearchResult>
	let loadingWorkOrders: boolean = false
	let rows: Array<WorkOrderSearchResult> = []
	let totalItemsCount: number = 0
	const columns: Array<Column<WorkOrderSearchResult>> = [
		{ property: 'plant[code]', name: translate('workOrderSearch.plantCode', 'Plant Code'), sortType: false },
		{ property: 'documentStatus', name: 'Status' },
		{ property: 'favorite', name: translate('workOrderSearch.favorite', 'Favorite'), sortType: false, align: 'center', width: '1%' },
		{ property: 'plant[name]', name: translate('workOrderSearch.plant', 'Plant'), sortType: false },
		{ property: 'id', name: translate('workOrderSearch.wO#', 'WO#'), numeric: true },
		{ property: 'title', name: translate('workOrderSearch.title', 'Title') },
		{ property: 'workOrderType[name]', name: translate('workOrderSearch.type', 'Type'), sortType: false },
		{ property: 'scheduled', name: translate('workOrderSearch.scheduled', 'Scheduled') },
		{ property: 'due', name: translate('workOrderSearch.due', 'Due') },
		{ property: 'productBatch[name]', name: translate('workOrderSearch.productBatch', 'Product Batch'), sortType: false },
		{ property: 'assignedToGroup[name]', name: translate('workOrderSearch.group', 'Group'), sortType: false },
		{ property: 'dateCreated', name: translate('workOrderSearch.dateCreated', 'Date Created') },
		{ property: 'internalNotes', name: translate('workOrderSearch.internalNotes', 'Internal Notes') },
		{ property: 'instructions', name: translate('workOrderSearch.instructions', 'Instructions') },
		{ property: 'verificationDue', name: translate('workOrderSearch.verificationDueDate', 'Verification Due') },
		{ property: 'verifiedByUser[fullName]', name: translate('workOrderSearch.verifiedBy', 'Verified By'), sortType: false },
		{ property: 'verifiedOn', name: translate('workOrderSearch.verifiedOnDate', 'Verified On') },
	]

	type PropertyUnion<T extends readonly { property: string }[]> = T[number]['property']
	type ColumnPropertyUnion = PropertyUnion<typeof columns>

	const isWithin24Hours = date => {
		const now = new Date()
		const oneDay = 24 * 60 * 60 * 1000 // milliseconds in a day

		const diff = now.getTime() - date.getTime()

		return Math.abs(diff) < oneDay
	}

	$: selectedPlant = plants.find(plant => plant.id === selectedPlantId) ?? null
	$: canViewWorkOrders = hasPermission('WORK_ORDERS_CAN_VIEW', selectedPlantId)
	$: canEditWorkOrders = hasPermission('WORK_ORDERS_CAN_EDIT', selectedPlantId)

	function changePlant({ detail: plant }: ComponentEvents<SiteAutocomplete<{ code: string; id: number; name: string } | null>>['change']) {
		selectedPlantId = plant?.id || selectedPlantId
	}

	function getSearchFilters(): NonNullable<WorkOrderSearchFilter> {
		return {
			plantId: selectedPlantId,
			from: dateRange.from,
			to: dateRange.to,
			openDocuments: showOpenDocuments,
			closedDocuments: showClosedDocuments,
			favoriteDocuments: favoriteWorkOrdersOnly || null, //The API supports passing false to get just non-favorites, but in this UI we don't support that, so we coerce to null.
			workOrderTypeId: selectedWorkOrderTypeId,
			groupId: selectedGroupId,
		}
	}

	async function getWorkOrderSearchResults() {
		if (!canViewWorkOrders) {
			return
		}

		loadingWorkOrders = true

		const { data } = await workOrderSearchQuery.fetch({
			variables: {
				filter: getSearchFilters(),
				pagination: {
					pageNumber: tablePageNumber,
					pageSize,
				},
				orderBy: {
					columnName: orderByColumn,
					direction: orderByDirection,
				},
			},
		})

		if (data?.workOrders) {
			rows = data.workOrders.data ?? []
			//console.log('first WO ID', rows[0].id)
			tablePageNumber = data.workOrders.info.pageNumber ?? 1
			totalItemsCount = data.workOrders.info.totalItems ?? 0
		}

		loadingWorkOrders = false
	}

	function updateFavorite(event: MouseEvent, row: WorkOrderSearchResult) {
		const foundWorkOrder: Writable<WorkOrderSearchResult> | undefined = rows.find(workOrder => workOrder.id === row.id)
		if (foundWorkOrder) {
			foundWorkOrder.favorite = !foundWorkOrder.favorite
			workOrderFavoritesStore.update(foundWorkOrder)
			rows = rows
		}
	}

	async function saveInterfaceHistory() {
		await Promise.all([
			setUserSetting({
				category: 'Work Orders',
				name: 'Automatic WOs: show open documents',
				settingType: 'INTERFACE_HISTORY',
				newValue: booleanToString(showOpenDocuments),
			}),
			setUserSetting({
				category: 'Work Orders',
				name: 'Automatic WOs: show closed documents',
				settingType: 'INTERFACE_HISTORY',
				newValue: booleanToString(showClosedDocuments),
			}),
			setUserSetting({
				category: 'Work Orders',
				name: 'Automatic WOs: date range filter',
				settingType: 'INTERFACE_HISTORY',
				newValue: rangeFromDates(dateRange.from, dateRange.to) || 'Last 30 Days',
			}),
		])
	}

	onMount(async () => {
		if (visibleColumns && visibleColumns.length) {
			const { visible, hidden } = columns.reduce(
				(acc, column) => {
					if (visibleColumns.includes(column.property)) {
						acc.visible.push(column.property)
					} else {
						acc.hidden.push(column.property)
					}
					return acc
				},
				{ visible: [], hidden: [] } as { visible: Array<ColumnPropertyUnion>; hidden: Array<ColumnPropertyUnion> },
			)

			workOrderSearchTable.setColumnVisibility(visible, true)
			workOrderSearchTable.setColumnVisibility(hidden, false)
		}

		await getWorkOrderSearchResults()
	})

	onDestroy(() => {
		saveInterfaceHistory()
	})

	function searchOrAsrGo() {
		if (asr && asrGoToSearchPath) {
			asrGoToSearchPath(getSearchFilters(), { pageNumber: tablePageNumber, pageSize }, { columnName: orderByColumn, direction: orderByDirection })
		} else {
			getWorkOrderSearchResults()
		}
	}
</script>

<div class="form-row">
	<div class="col-md-3 col-12">
		<SiteAutocomplete
			label={translate('workOrderSearch.plant', 'Plant')}
			bind:value={selectedPlant}
			options={plants}
			on:change={event => {
				changePlant(event)
				searchOrAsrGo()
			}}
		/>
	</div>
</div>

<div class="form-inline">
	<DateRange
		inline
		allowNone
		excludedRanges={[]}
		bind:dates={dateRange}
		defaultRange={rangeFromDates(dateRange.from, dateRange.to) || 'Last 30 Days'}
		on:change={() => searchOrAsrGo()}
	/>

	{#if workOrderTypes && workOrderTypes.length}
		<Select
			label={translate('workOrderSearch.wOType', 'WO Type')}
			labelClass="mr-1"
			labelParentClass="mr-3"
			options={workOrderTypes}
			bind:value={selectedWorkOrderTypeId}
			emptyText={translate('workOrderSearch.anyWorkOrderType', 'Any WO Type')}
			let:option={workOrderType}
			on:change={() => searchOrAsrGo()}
		>
			<option value={workOrderType?.id}>{workOrderType?.name}</option>
		</Select>
	{/if}

	{#if groups && groups.length}
		<Select
			label={translate('workOrderSearch.assignedToGroup', 'Assigned to Group')}
			labelClass="mr-1"
			labelParentClass="mr-3"
			options={groups}
			bind:value={selectedGroupId}
			emptyText={translate('workOrderSearch.anyGroup', 'Any Group')}
			let:option={group}
			on:change={() => searchOrAsrGo()}
		>
			<option value={group?.id}>{group?.name}</option>
		</Select>
	{/if}
</div>

<div class="form-row">
	<div class="col-12">
		{translate('workOrderSearch.status', 'Status:')}
		<Checkbox
			class="mx-1"
			bind:checked={showOpenDocuments}
			label={translate('workOrderSearch.open', 'Open')}
			inline
			on:change={() => searchOrAsrGo()}
		/>
		<Checkbox
			class="mx-1"
			bind:checked={showClosedDocuments}
			label={translate('workOrderSearch.closed', 'Closed')}
			inline
			on:change={() => searchOrAsrGo()}
		/>
	</div>
</div>
{#if showFavoriteCheckbox}
	<div class="form-row">
		<div class="col-12">
			<Checkbox
				bind:checked={favoriteWorkOrdersOnly}
				label={translate('workOrderSearch.onlyShowFavoriteWorkOrders', 'Only show favorite Work Orders')}
				inline
				on:change={() => searchOrAsrGo()}
			/>
		</div>
	</div>
{/if}

<div class="my-2">
	<Table
		responsive
		stickyHeader
		selectionEnabled
		columnHidingEnabled
		sortDirection={orderByDirection}
		sortColumn={columns.find(column => column.property === orderByColumn)}
		idProp="id"
		{rows}
		{columns}
		filterEnabled={false}
		perPageCount={pageSize}
		parentStyle="max-height: 50vh;"
		rowSelectionIdProp="id"
		selectionMode={disableSelection ? null : 'SINGLE'}
		bind:selectedRowIds={selectedWorkOrderIds}
		bind:currentPageNumber={tablePageNumber}
		{totalItemsCount}
		bind:this={workOrderSearchTable}
		lazySort
		pageChange={ctx => {
			tablePageNumber = ctx.pageNumber
			searchOrAsrGo()
		}}
		columnClickedMethod={async (column, direction) => {
			orderByDirection = direction
			orderByColumn = column.property
			console.log('columnClickedMethod', orderByColumn, orderByDirection)
			searchOrAsrGo()
		}}
	>
		{#snippet body()}
			{#each rows as row, index}
				{@const verificationOverdue = row.verificationDue && !row.verifiedOn && new Date() > new Date(row.verificationDue)}
				{@const verificationDueSoon = row.verificationDue && !row.verifiedOn && isWithin24Hours(new Date(row.verificationDue))}
				{@const workorderOverdue = highlightOverdue && row.documentStatus !== 'CLOSED' && row.due && new Date() > new Date(row.due)}
				{@const workorderDueSoon = highlightOverdue && row.documentStatus !== 'CLOSED' && row.due && isWithin24Hours(new Date(row.due))}
				<tr
					class:table-primary={selectedWorkOrderIds.includes(row.id)}
					class:cursor-pointer={!disableSelection}
					class={workorderOverdue ? 'table-danger' : workorderDueSoon ? 'table-warning' : ''}
					on:click={() => {
						if (!disableSelection) {
							workOrderSearchTable.rowClick({ ...row, originalIndex: index, uuid: '' })
						}
					}}
				>
					<Td property="plant[code]">
						{row.plant.code}
					</Td>
					<Td property="documentStatus">
						{row.documentStatus}
					</Td>
					<Td
						property="favorite"
						stopPropagation
					>
						<Button
							size="xs"
							outline
							icon={{
								icon: 'star',
								color: 'warning',
								prefix: row.favorite ? 'fas' : 'far',
							}}
							disabled={!canEditWorkOrders}
							on:click={event => updateFavorite(event, row)}
						/>
					</Td>
					<Td property="plant[name]">
						{row.plant.name}
					</Td>
					<Td property="id">
						{#if showEditLink && canEditWorkOrders}
							<a href="/#/work-order/edit/{row.id}">{row.id}</a>
						{:else}
							{row.id}
						{/if}
					</Td>
					<Td property="title">
						{row.title ?? ''}
					</Td>
					<Td property="workOrderType[name]">
						{row.workOrderType.name}
					</Td>
					<Td property="scheduled">
						{new Date(row.scheduled)?.toLocaleString() ?? ''}
					</Td>
					<Td property="due">
						{row.due ? new Date(row.due)?.toLocaleString() : ''}
					</Td>
					<Td property="productBatch[name]">
						{row.productBatch?.name ?? ''}
					</Td>
					<Td property="assignedToGroup[name]">
						{row.assignedToGroup?.name ?? ''}
					</Td>
					<Td property="dateCreated">
						{new Date(row.dateCreated)?.toLocaleString() ?? ''}
					</Td>
					<Td property="internalNotes">
						{row.internalNotes ?? ''}
					</Td>
					<Td property="instructions">
						{row.instructions ?? ''}
					</Td>

					<Td
						property="verificationDue"
						class={verificationOverdue ? 'bg-danger text-white' : verificationDueSoon ? 'bg-warning' : ''}
					>
						{row.verificationDue ? new Date(row.verificationDue)?.toLocaleString() : ''}
					</Td>
					<Td property="verifiedByUser[fullName]">
						{row.verifiedByUser?.fullName ?? ''}
					</Td>
					<Td property="verifiedOn">
						{row.verifiedOn ? new Date(row.verifiedOn)?.toLocaleString() : ''}
					</Td>
				</tr>
			{/each}
		{/snippet}
		{#snippet noRows()}
			<tr>
				<td
					colspan={columns.length}
					class="text-center"
				>
					{#if loadingWorkOrders}
						{translate('workOrderSearch.loading', 'Loading')}...
					{:else}
						{translate('workOrderSearch.noWorkOrdersFound', 'No Work Orders Found.')}
					{/if}
				</td>
			</tr>
		{/snippet}
	</Table>
</div>

<slot />
