import { QueryStore } from '../runtime/stores/query'
import artifact from '$houdini/artifacts/ShLocations'
import { initClient } from '$houdini/plugins/houdini-svelte/runtime/client'

export class ShLocationsStore extends QueryStore {
	constructor() {
		super({
			artifact,
			storeName: "ShLocationsStore",
			variables: false,
		})
	}
}

export async function load_ShLocations(params) {
	await initClient()

	const store = new ShLocationsStore()

	await store.fetch(params)

	return {
		ShLocations: store,
	}
}
