export default {
    "name": "CreateSavedSampleSearch",
    "kind": "HoudiniMutation",
    "hash": "a96aad7eab47c12e8d4e31f1e5c7acd1e14eb73bdaefa8c9bcecb9300cdf2fda",

    "raw": `mutation CreateSavedSampleSearch($input: CreateSavedSampleSearchInput!) {
  createSavedSampleSearch(input: $input) {
    ...SavedSearchFields
    id
  }
}

fragment SavedSearchFields on SavedSampleSearch {
  id
  createdOn
  name
  description
  scope
  plant {
    id
    code
  }
  createdByUser {
    id
    fullName
  }
  __typename
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "createSavedSampleSearch": {
                "type": "SavedSampleSearch",
                "keyRaw": "createSavedSampleSearch(input: $input)",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "createdOn": {
                            "type": "DateTime",
                            "keyRaw": "createdOn",
                            "visible": true
                        },

                        "name": {
                            "type": "String",
                            "keyRaw": "name",
                            "visible": true
                        },

                        "description": {
                            "type": "String",
                            "keyRaw": "description",
                            "nullable": true,
                            "visible": true
                        },

                        "scope": {
                            "type": "SeachScope",
                            "keyRaw": "scope",
                            "visible": true
                        },

                        "plant": {
                            "type": "Plant",
                            "keyRaw": "plant",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "code": {
                                        "type": "String",
                                        "keyRaw": "code",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "createdByUser": {
                            "type": "UserAccount",
                            "keyRaw": "createdByUser",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "fullName": {
                                        "type": "NonEmptyString",
                                        "keyRaw": "fullName",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "__typename": {
                            "type": "String",
                            "keyRaw": "__typename",
                            "visible": true
                        }
                    },

                    "fragments": {
                        "SavedSearchFields": {
                            "arguments": {}
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "input": {
        "fields": {
            "input": "CreateSavedSampleSearchInput"
        },

        "types": {
            "SampleAnalysisOptionsSearchState": {
                "expirationDateRange": "String",
                "expirationFrom": "String",
                "expirationTo": "String",
                "id": "Int",
                "lotNumber": "String",
                "resultStatus": "ResultStatus",
                "valueFrom": "String",
                "valueTo": "String"
            },

            "SampleAnalysesSearchState": {
                "id": "Int",
                "options": "SampleAnalysisOptionsSearchState",
                "resultStatus": "ResultStatus"
            },

            "SampleSearchDateRange": {
                "from": "Int",
                "to": "Int",
                "type": "SampleDateRangeType"
            },

            "SampleSearchStateInput": {
                "analyses": "SampleAnalysesSearchState",
                "dateRange": "SampleSearchDateRange",
                "isRetest": "Boolean",
                "locationName": "String",
                "matchAllOptionFilters": "Boolean",
                "optionsAreInclusive": "Boolean",
                "performedByUserId": "Int",
                "performedByUserName": "String",
                "plantIds": "Int",
                "processZoneId": "Int",
                "processZoneName": "String",
                "productBatchName": "String",
                "productName": "String",
                "productProximityId": "Int",
                "productProximityName": "String",
                "samplingComments": "String",
                "scheduleName": "String",
                "statuses": "DocumentStatus",
                "tagNumber": "String",
                "testingComments": "String",
                "workOrderInternalNotes": "String",
                "workOrderNumber": "String",
                "workOrderTitle": "String",
                "workOrderTypeId": "Int",
                "workOrderTypeName": "String"
            },

            "CreateSavedSampleSearchInput": {
                "description": "String",
                "name": "String",
                "scope": "SeachScope",
                "search": "SampleSearchStateInput"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=1ff42a7be445d029aa63352d5355f4d8cd7b7d9e0ddb9d94bb97a4cdbf98a3c6";