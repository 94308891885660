import logOut from 'utility/log-out'
import component from './Login.svelte'
import type { AppContext, ResolveParameters } from 'types/common'
import { stringToBoolean } from '@isoftdata/utility-string'
import { plantsQuery } from 'utility/plants'
import { graphql } from '$houdini'

const getServerInfoQuery = graphql(`
	query GetServerInformation {
		getServerInformation {
			releaseVersionNumber
			schemaVersion
		}
	}
`)

interface LoginParameters {
	message: string
	username: string
	accountActivationSuccess: string
}

export default ({ mediator, stateRouter }: AppContext) => {
	stateRouter.addState({
		name: 'login',
		route: 'login',
		template: {
			svelte: true,
			component,
		},
		querystringParameters: ['message', 'username', 'accountActivationSuccess'],
		async resolve(_data, { username, accountActivationSuccess }: ResolveParameters<LoginParameters>) {
			//They came back to the login state, clear any auth token they may have.
			await logOut()

			const defaultLanguage = 'en'
			const selectedLanguage = localStorage.getItem('i18nextLng') ?? defaultLanguage
			const lastSelectedPlantId = localStorage.getItem('lastSelectedPlantId')

			const [plantList, serverInformation] = await Promise.all([
				plantsQuery.fetch({ variables: { pagination: { pageSize: 0 } } }).then(res => res?.data?.plants?.data ?? []),
				getServerInfoQuery.fetch().then(res => res?.data?.getServerInformation),
			])

			return Promise.resolve({
				plantList,
				selectedPlantId: lastSelectedPlantId ? parseInt(lastSelectedPlantId, 10) : 1,
				userName: username ?? localStorage.getItem('lastUsername') ?? '',
				rememberMe: !!localStorage.getItem('lastUsername'),
				showLanguageSelector: selectedLanguage !== defaultLanguage,
				languages: [
					{ code: 'en', name: 'English' },
					{ code: 'cs', name: 'Czech' },
					{ code: 'es', name: 'Spanish' },
					{ code: 'de', name: 'German' },
					{ code: 'fr', name: 'French' },
				],
				selectedLanguage,
				apiVersionNumber: serverInformation?.releaseVersionNumber ?? '',
				apiSchemaVersion: serverInformation?.schemaVersion ?? '',
				accountActivationSuccess: stringToBoolean(accountActivationSuccess) || undefined,
			})
		},
	})
}
