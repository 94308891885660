export default {
    "name": "AutomaticWorkOrders",
    "kind": "HoudiniQuery",
    "hash": "8f28342728f35d112745d26ca536e2af53df57a08865587be30a835617448dab",

    "raw": `query AutomaticWorkOrders($filter: AutomaticWorkOrderFilter, $pagination: PaginatedInput) {
  automaticWorkOrders(filter: $filter, pagination: $pagination) {
    data {
      id
      workOrderId
      scheduleType
      monthOffset
      timeOffset
      dayOffset
      timezone
      active
      description
      dateCreated
      dayOfWeek
      rank
      cloneAdditionalData
      cloneMostRecent
      nextOccurance
      workOrder {
        id
        plant {
          id
          name
          code
        }
      }
    }
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "automaticWorkOrders": {
                "type": "AutomaticWorkOrderResponse",
                "keyRaw": "automaticWorkOrders(filter: $filter, pagination: $pagination)",
                "nullable": true,

                "selection": {
                    "fields": {
                        "data": {
                            "type": "AutomaticWorkOrder",
                            "keyRaw": "data",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "workOrderId": {
                                        "type": "NonNegativeInt",
                                        "keyRaw": "workOrderId",
                                        "visible": true
                                    },

                                    "scheduleType": {
                                        "type": "ScheduleType",
                                        "keyRaw": "scheduleType",
                                        "visible": true
                                    },

                                    "monthOffset": {
                                        "type": "NonNegativeInt",
                                        "keyRaw": "monthOffset",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "timeOffset": {
                                        "type": "String",
                                        "keyRaw": "timeOffset",
                                        "visible": true
                                    },

                                    "dayOffset": {
                                        "type": "NonNegativeInt",
                                        "keyRaw": "dayOffset",
                                        "visible": true
                                    },

                                    "timezone": {
                                        "type": "String",
                                        "keyRaw": "timezone",
                                        "visible": true
                                    },

                                    "active": {
                                        "type": "Boolean",
                                        "keyRaw": "active",
                                        "visible": true
                                    },

                                    "description": {
                                        "type": "String",
                                        "keyRaw": "description",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "dateCreated": {
                                        "type": "DateTime",
                                        "keyRaw": "dateCreated",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "dayOfWeek": {
                                        "type": "NonNegativeInt",
                                        "keyRaw": "dayOfWeek",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "rank": {
                                        "type": "NonNegativeInt",
                                        "keyRaw": "rank",
                                        "visible": true
                                    },

                                    "cloneAdditionalData": {
                                        "type": "Boolean",
                                        "keyRaw": "cloneAdditionalData",
                                        "visible": true
                                    },

                                    "cloneMostRecent": {
                                        "type": "Boolean",
                                        "keyRaw": "cloneMostRecent",
                                        "visible": true
                                    },

                                    "nextOccurance": {
                                        "type": "DateTime",
                                        "keyRaw": "nextOccurance",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "workOrder": {
                                        "type": "WorkOrder",
                                        "keyRaw": "workOrder",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "plant": {
                                                    "type": "Plant",
                                                    "keyRaw": "plant",

                                                    "selection": {
                                                        "fields": {
                                                            "id": {
                                                                "type": "PositiveInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "name": {
                                                                "type": "String",
                                                                "keyRaw": "name",
                                                                "visible": true
                                                            },

                                                            "code": {
                                                                "type": "String",
                                                                "keyRaw": "code",
                                                                "visible": true
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "filter": "AutomaticWorkOrderFilter",
            "pagination": "PaginatedInput"
        },

        "types": {
            "AutomaticWorkOrderFilter": {
                "active": "Boolean",
                "plantId": "PositiveInt"
            },

            "PaginatedInput": {
                "pageNumber": "PositiveInt",
                "pageSize": "NonNegativeInt"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=facf764d18ccc69a73c3b072d4bf811fc082f33be5381f263375fd7eda7a9acf";