export default {
    "name": "DeletePlants",
    "kind": "HoudiniMutation",
    "hash": "1cc989cc5d97a2cca3d981a3dbc969225dfe085393be8c341c4cf9a4690381e9",

    "raw": `mutation DeletePlants($ids: [ID!]!) {
  deletePlants(ids: $ids)
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "deletePlants": {
                "type": "Boolean",
                "keyRaw": "deletePlants(ids: $ids)",
                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "ids": "ID"
        },

        "types": {},
        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=c916ddba120b5cfab8edddfbd37b6b2be23fff3121fcec581e4aa3ed94381ee1";