<svelte:options accessors={true} />

<script lang="ts">
	import type { SaveResetProps } from '../configuration'
	import type { i18n, SvelteAsr } from 'types/common'
	import type { Mediator } from 'client/services/api-fetch'
	import type { Group } from './user-group'

	import Autocomplete from '@isoftdata/svelte-autocomplete'
	import Button from '@isoftdata/svelte-button'
	import Checkbox from '@isoftdata/svelte-checkbox'
	import Input from '@isoftdata/svelte-input'
	import Modal from '@isoftdata/svelte-modal'
	import UserGroup from '@isoftdata/svelte-user-group'
	import { getContext, tick } from 'svelte'
	import makeCrudStore from '@isoftdata/svelte-store-crud'
	import { v4 as uuid } from '@lukeed/uuid'
	import { graphql } from '$houdini'

	export let asr: SvelteAsr
	// having the groups as an object with the groupId/uuid as the key makes it easier to update the group
	export let groups: Record<number, Group>
	export let selectedGroup: Group
	export let hasUnsavedChanges = false
	export let saveResetProps: SaveResetProps

	$: $saveResetProps = {
		save: saveChanges,
		disabled: !hasUnsavedChanges,
		resetHref: asr.makePath(null, { lastResetTime: Date.now(), lastSavedTime: null }, { inherit: true }),
	}

	const mediator = getContext<Mediator>('mediator')
	const { t: translate } = getContext<i18n>('i18next')
	const groupCrudStore = makeCrudStore<Group, 'uuid'>('uuid')

	const createGroups = graphql(`
		mutation CreateGroups($inputs: [NewGroup!]!) {
			createGroups(inputs: $inputs) {
				id
				name
				workerGroup
				userAccounts {
					id
					name
				}
				groupPermissions {
					permissionId
					value
				}
			}
		}
	`)

	const updateGroups = graphql(`
		mutation UpdateGroups($inputs: [UpdateGroup!]!) {
			updateGroups(inputs: $inputs) {
				id
				name
				workerGroup
				userAccounts {
					id
					name
				}
				groupPermissions {
					permissionId
					value
				}
			}
		}
	`)

	const deleteGroups = graphql(`
		mutation DeleteGroups($ids: [PositiveInt!]!) {
			deleteGroups(ids: $ids)
		}
	`)

	let groupNameInput: HTMLInputElement | undefined
	let groupModal = {
		show: false,
		newGroup: false,
		groupName: '',
		modalTitle: '',
		confirmButtonText: '',
	}
	let deleteGroupModal = {
		show: false,
		modalTitle: '',
		confirmButtonText: '',
		cancelButtonText: '',
		hasConflict: false,
		alertSubscriptionCount: 0,
		analysisCount: 0,
		workOrderTypeCount: 0,
	}

	$: groupList = Object.values(groups) // this is used for the autocomplete options

	function setWorkOrderDisplay(event: Event) {
		const checked = (event.target as HTMLInputElement).checked
		selectedGroup.workerGroup = checked
		updateGroup()
	}

	async function openGroupModal(newGroup: boolean) {
		groupModal = {
			show: true,
			newGroup,
			groupName: newGroup ? '' : selectedGroup.name,
			modalTitle: newGroup ? translate('configuration.groups.enterNewGroupName', 'Enter New Group Name') : translate('configuration.groups.editGroupName', 'Edit Group Name'),
			confirmButtonText: newGroup ? translate('configuration.groups.createGroup', 'Create Group') : translate('common:save', 'Save'),
		}
		await tick()
		groupNameInput?.focus()
	}

	async function openDeleteGroupModal() {
		let alertSubscriptionCount = 0
		let analysisCount = 0
		let workOrderTypeCount = 0
		let hasConflict = false
		if (selectedGroup.groupInUseData) {
			alertSubscriptionCount = selectedGroup?.groupInUseData.alertSubscriptionCount
			analysisCount = selectedGroup?.groupInUseData.analysisCount
			workOrderTypeCount = selectedGroup?.groupInUseData.workOrderTypeCount
			hasConflict = alertSubscriptionCount > 0 || analysisCount > 0 || workOrderTypeCount > 0
		}
		deleteGroupModal = {
			show: true,
			modalTitle: hasConflict
				? `${translate('common:error', 'Error')} - ${translate('configuration.groups.groupInUse', 'Group In Use')}`
				: `${translate('common:warning', 'Warning')} - ${translate('configuration.groups.deleteGroup', 'Delete Group')}`,
			confirmButtonText: translate('configuration.groups.deleteGroup', 'Delete Group'),
			cancelButtonText: hasConflict ? translate('common:close', 'Close') : translate('common:cancel', 'Cancel'),
			hasConflict,
			alertSubscriptionCount,
			analysisCount,
			workOrderTypeCount: workOrderTypeCount,
		}
	}

	function createGroup() {
		const newGroup = {
			id: Number(uuid()), // use uuid as a temporary id for the new group
			name: groupModal.groupName,
			nameLabel: 'NEW',
			workerGroup: false,
			userAccounts: selectedGroup.userAccounts.map(userAccount => {
				return {
					...userAccount,
					isMember: false,
				}
			}),
			groupPermissions: selectedGroup.groupPermissions.map(groupPermission => {
				return {
					...groupPermission,
					value: 'NONE' as 'NONE',
				}
			}),
			groupInUseData: null,
			uuid: uuid(),
		}
		// use the uuid as the key for the new group
		groups[newGroup.uuid] = newGroup
		groupCrudStore.create(newGroup)
		selectedGroup = newGroup
		hasUnsavedChanges = true
	}

	function updateGroup(updateGroupName: boolean = false) {
		// if the selectedGroup is a new group, there is still no id, so we use uuid
		const selectedGroupId = selectedGroup.id ?? selectedGroup.uuid
		groups[selectedGroupId].nameLabel = groups[selectedGroupId].nameLabel ?? 'MODIFIED'
		if (updateGroupName) {
			groups[selectedGroupId].name = groupModal.groupName
			selectedGroup.name = groupModal.groupName
		}
		groupCrudStore.update(selectedGroup)
		hasUnsavedChanges = true
	}

	function deleteGroup() {
		// if the selectedGroup is a new group, there is still no id, so we use uuid
		const selectedGroupId = selectedGroup.id ?? selectedGroup.uuid
		groups[selectedGroupId].nameLabel = 'DELETED'
		selectedGroup.nameLabel = 'DELETED'
		groupCrudStore.delete(selectedGroup)
		hasUnsavedChanges = true
	}

	async function saveChanges() {
		const groupToBeCreated = groupCrudStore.createdValues
		const groupToBeUpdated = groupCrudStore.updatedValues
		const groupToBeDeleted = groupCrudStore.deletedValues

		const createGroupInputs = groupToBeCreated?.map(newGroup => {
			return {
				name: newGroup.name,
				workerGroup: newGroup.workerGroup,
				userIds: newGroup.userAccounts.filter(userAccount => userAccount.isMember).map(userAccount => userAccount.id),
				groupPermissions: newGroup.groupPermissions.map(groupPermission => {
					return {
						permissionId: groupPermission.id,
						permissionValue: groupPermission.value,
					}
				}),
			}
		})

		const updateGroupInputs = groupToBeUpdated?.map(group => {
			return {
				groupId: group.id,
				name: group.name,
				workerGroup: group.workerGroup,
				userIds: group.userAccounts.filter(userAccount => userAccount.isMember).map(userAccount => userAccount.id),
				groupPermissions: group.groupPermissions.map(groupPermission => {
					return {
						permissionId: groupPermission.id,
						permissionValue: groupPermission.value,
					}
				}),
			}
		})

		try {
			await Promise.all([
				createGroupInputs.length > 0 ? createGroups.mutate({ inputs: createGroupInputs }) : null,
				updateGroupInputs.length > 0 ? updateGroups.mutate({ inputs: updateGroupInputs }) : null,
				groupToBeDeleted.length > 0 ? deleteGroups.mutate({ ids: groupToBeDeleted.map(group => group.id) }) : null,
			])

			hasUnsavedChanges = false
			mediator.call('showMessage', { heading: 'Saved!', message: 'Changes have been saved successfully.', type: 'success', time: 10 })
			asr.go(null, { lastSavedTime: Date.now(), lastResetTime: null }, { inherit: true })
		} catch (error: any) {
			console.error(error)
			mediator.call('showMessage', { heading: 'Error!', type: 'danger', time: false, message: error?.extensions?.notes })
		}
	}
</script>

<div class="form-row align-items-end">
	<div class="col-12 col-lg-3">
		<Autocomplete
			label={translate('common:group', 'Group')}
			bind:options={groupList}
			bind:value={selectedGroup}
			getLabel={option => (option.nameLabel ? `${option.name} - ${option.nameLabel}` : option.name)}
			labelProp="name"
		>
			<svelte:fragment
				slot="option"
				let:option
			>
				<div class="d-flex justify-content-between">
					{option.name}
					{#if option.nameLabel}
						<div class="text-right">
							<span
								class="badge text-white"
								class:badge-danger={option.nameLabel === 'DELETED'}
								class:badge-success={option.nameLabel === 'NEW'}
								class:badge-warning={option.nameLabel === 'MODIFIED'}
							>
								{option.nameLabel}
							</span>
						</div>
					{/if}
				</div>
			</svelte:fragment>
		</Autocomplete>
	</div>
	<div class="col-12 col-sm-auto mb-1 d-flex justify-content-between">
		<Button
			class="mr-1"
			size="sm"
			color="success"
			outline
			disabled={selectedGroup === null}
			icon={{
				icon: 'plus',
				fixedWidth: true,
			}}
			on:click={() => openGroupModal(true)}
		>
			{translate('configuration.groups.newGroup', 'New Group')}...
		</Button>
		<Button
			class="mr-1"
			size="sm"
			color="primary"
			outline
			disabled={selectedGroup === null}
			icon={{
				icon: 'pen',
				fixedWidth: true,
			}}
			on:click={() => openGroupModal(false)}
		>
			{translate('configuration.groups.editName', 'Edit Name')}...
		</Button>
		<Button
			size="sm"
			color="danger"
			outline
			disabled={selectedGroup === null}
			icon={{
				icon: 'trash',
				fixedWidth: true,
			}}
			on:click={openDeleteGroupModal}
		>
			{translate('common:delete', 'Delete')}...
		</Button>
	</div>
</div>

<div class="my-2">
	<Checkbox
		label={translate('configuration.groups.showOnWorkOrders', 'Show Group on Work Orders')}
		checked={selectedGroup.workerGroup}
		disabled={selectedGroup === null}
		on:change={setWorkOrderDisplay}
	/>
</div>

<UserGroup
	siteLabel="Plant"
	bind:userAccounts={selectedGroup.userAccounts}
	bind:groupPermissions={selectedGroup.groupPermissions}
	{updateGroup}
/>

<Modal
	bind:show={groupModal.show}
	title={groupModal.modalTitle}
	confirmButtonText={groupModal.confirmButtonText}
	cancelButtonText={translate('common:cancel', 'Cancel')}
	confirmButtonType="submit"
	confirmButtonFormId="groupModalForm"
	on:close={() => (groupModal.show = false)}
>
	<form
		id="groupModalForm"
		on:submit={event => {
			event.preventDefault()
			groupModal.newGroup ? createGroup() : updateGroup(true)
			groupModal.show = false
		}}
	>
		<Input
			label={translate('configuration.groups.groupName', 'Group Name')}
			bind:value={groupModal.groupName}
			bind:input={groupNameInput}
		/>
	</form>
</Modal>

<Modal
	bind:show={deleteGroupModal.show}
	title={deleteGroupModal.modalTitle}
	confirmButtonText={deleteGroupModal.confirmButtonText}
	cancelButtonText={deleteGroupModal.cancelButtonText}
	confirmButtonColor="danger"
	cancelButtonColor="primary"
	confirmButtonDisabled={deleteGroupModal.hasConflict}
	on:close={() => (deleteGroupModal.show = false)}
	on:confirm={() => {
		deleteGroup()
		deleteGroupModal.show = false
	}}
>
	<div
		class="alert alert-warning"
		role="alert"
	>
		<span
			><i class="fa-solid fa-triangle-exclamation fa-lg text-warning"></i> {translate('configuration.groups.deleteWarningText', 'By deleting this group, some possible side effects may occur')}:</span
		><br />
		<ul>
			<li>{translate('conifguration.groups.userInGroupRemoveWarning', 'All users in this group will be removed from this group')}.</li>
			<li>
				{translate('configuration.groups.workOrderChangesWarning', 'All Work Orders that are assigned to this group will be assigned to All Groups')}.
			</li>
		</ul>
		<span>{translate('configuration.groups.deleteWarningReminderText', 'Please be sure to make necessary changes if needed after deleting this group')}.</span>
	</div>
	{#if deleteGroupModal.hasConflict}
		<hr />
		<div
			class="alert alert-danger"
			role="alert"
		>
			<span><i class="fa-solid fa-circle-xmark fa-lg text-danger"></i> {translate('configuration.groups.deleteErrorText', 'Cannot delete this group while it is in use on')}:</span><br />
			<ul>
				<li>{deleteGroupModal.alertSubscriptionCount} {translate('configuration.titles.alertSubscriptions', 'Alert Subscriptions')}</li>
				<li>{deleteGroupModal.analysisCount} {translate('configuration.titles.analyses', 'Analyses')}</li>
				<li>{deleteGroupModal.workOrderTypeCount} {translate('configuration.titles.workOrderTypes', 'Work Order Types')}</li>
			</ul>
			<strong>{translate('configuration.groups.deleteErrorFixInstructionText', 'Please remove this group from the above before deleting')}.</strong>
		</div>
	{/if}
</Modal>
