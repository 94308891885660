import component from './Verify.svelte'
import { getSession } from 'stores/session'

export default ({ mediator, stateRouter }) => {
	stateRouter.addState({
		name: 'app.verify',
		route: 'verify',
		querystringParameters: [ 'workOrderId' ],
		defaultParameters: {
			workOrderId: null,
		},
		template: {
			svelte: true,
			component,
		},
		resolve(data, parameters) {
			const authToken = getSession().authToken
			const { workOrderId } = parameters
			let iframeURL = null
			if (workOrderId) {
				iframeURL = `/verify/workorderdetail.php?session_token=${authToken}&embedded=true&woid=${workOrderId}`
			} else {
				iframeURL = `/verify/index.php?session_token=${authToken}&embedded=true`
			}

			return Promise.resolve({
				iframeURL,
			})
		},
	})
}
