export default {
    "name": "GetUserSetting",
    "kind": "HoudiniQuery",
    "hash": "9df3b7cd732624397a70ba3e6aa21e8eea9a3c8c1154c47580340bd7436ff5dd",

    "raw": `query GetUserSetting($lookup: SettingLookup!) {
  getCurrentUserSetting(lookup: $lookup) {
    value
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "getCurrentUserSetting": {
                "type": "SettingResult",
                "keyRaw": "getCurrentUserSetting(lookup: $lookup)",

                "selection": {
                    "fields": {
                        "value": {
                            "type": "String",
                            "keyRaw": "value",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "lookup": "SettingLookup"
        },

        "types": {
            "SettingLookup": {
                "category": "String",
                "defaultValue": "String",
                "name": "String",
                "settingType": "SettingType"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=c77ec8340fa53f0cbfd7f1cfb658be39f1432268b7facfea64578301cbb6a99e";