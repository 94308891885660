import { graphql, Scope$options, type GetCurrentUserSetting$input } from '$houdini'
// TODO - delete this file and use Houdini's generated types instead

export type SettingSaveType = {
	settingId: number
	value: string
}

export type DefaultSettingSaveType = {
	settingId: number
	newDefaultValue: string
}

export type UserSetting = {
	id: number
	name: string
	category: string // Location
	type: string //SettingType
	scope: Scope$options
	defaultValue: string | null
	value: string | number | boolean | null
	dataType: string
	dirty: boolean
	defaultDirty: boolean
}

export enum SettingType {
	INTERFACE_PREFERENCE = 'Interface Preference',
	INTERFACE_HISTORY = 'Interface History',
	PREFERENCE = 'Preference',
	OPTIONAL_CONFIGURATION = 'Optional Configuration',
	IMPORTANT_CONFIGURATION = 'Important Configuration',
	ACCESSIBILITY = 'Accessibility',
}

export const getUserSettingValue = async (lookup: GetCurrentUserSetting$input['lookup']) => {
	const res = await getUserSetting.fetch({ variables: { lookup } })
	return res.data?.getCurrentUserSetting?.value
}

const getUserSetting = graphql(`
	query GetCurrentUserSetting($lookup: SettingLookup!) {
		getCurrentUserSetting(lookup: $lookup) {
			value
		}
	}
`)
