<script lang="ts">
	import type { CrudStore } from '@isoftdata/svelte-store-crud'
	import type { Mediator, SvelteAsr, i18n } from 'types/common'
	import type { WorkOrderSearchResult } from './work-order'
	import type { NewSampleOnUnsavedWorkOrder, UpdateSample, NewSampleValue, UpdateSampleValue } from '$houdini'
	import type { MetaSampleAttachment, Sample, WoDocumentStore, SampleValue, WoMediatorProviders } from './work-order'

	import { AsrNavTabBar } from '@isoftdata/svelte-nav-tab-bar'
	import SaveResetButton from '@isoftdata/svelte-save-reset-button'
	import Icon from '@isoftdata/svelte-icon'
	import Modal from '@isoftdata/svelte-modal'
	import Input from '@isoftdata/svelte-input'
	import Button from '@isoftdata/svelte-button'

	import { graphql } from '$houdini'
	import { getContext, type ComponentProps, onDestroy, tick } from 'svelte'
	import { AutomaticWorkOrder } from 'types/automatic-work-order'
	import pMap from 'p-map'
	import b64ify from 'utility/b64ify'
	import session from 'stores/session'
	import { slide } from 'svelte/transition'
	import hasPermission from 'utility/has-permission'
	import { getErrorCode } from 'utility/error'
	import StateCardHeader from 'components/StateCardHeader.svelte'

	const mediator = getContext<Mediator<WoMediatorProviders>>('mediator')
	const { t: translate } = getContext<i18n>('i18next')

	export let asr: SvelteAsr
	export let childStates: ComponentProps<AsrNavTabBar>['tabs']
	export let lastSavedTime: number
	export let canEditWorkOrders: boolean = false
	export let workOrderFavoritesStore: CrudStore<WorkOrderSearchResult, 'id'>
	export let automaticWorkOrderCrudStore: CrudStore<AutomaticWorkOrder, 'uuid'>
	export let sampleCrudStore: CrudStore<Sample, 'uuid'>
	export let woDocumentStore: WoDocumentStore
	export let sampleAttachmentCrudStore: CrudStore<MetaSampleAttachment, 'uuid'>

	let automaticWorkOrdersToCreate: AutomaticWorkOrder[] = []
	let automaticWorkOrdersToUpdate: AutomaticWorkOrder[] = []
	let automaticWorkOrdersToDelete: AutomaticWorkOrder[] = []
	let workOrderFavoritesToUpdate: WorkOrderSearchResult[] = []
	let selectedTab: string

	let authModalData: {
		show: boolean
		password: string
		error: string
		loading: boolean
		passwordInput: HTMLInputElement | undefined
	} = {
		show: false,
		password: '',
		error: '',
		loading: false,
		passwordInput: undefined,
	}

	type CompleteModalData = {
		show: boolean
		userAccessId: number | null
		status: 'SAMPLED' | 'CLOSED'
	}

	let completeModalData: CompleteModalData = {
		show: false,
		userAccessId: null,
		status: 'SAMPLED',
	}

	$: formattedLastSavedTime = lastSavedTime ? formatTime(lastSavedTime) : null
	$: automaticWorkOrdersToCreate = Object.values($automaticWorkOrderCrudStore.created)
	$: automaticWorkOrdersToUpdate = Object.values($automaticWorkOrderCrudStore.updated)
	$: automaticWorkOrdersToDelete = Object.values($automaticWorkOrderCrudStore.deleted)
	$: workOrderFavoritesToUpdate = Object.values($workOrderFavoritesStore.updated)
	$: hasAwoChanges = ($automaticWorkOrderCrudStore && automaticWorkOrderCrudStore.hasChanges()) || workOrderFavoritesToUpdate.length > 0
	$: hasEditChanges = ($sampleCrudStore && sampleCrudStore.hasChanges()) || ($woDocumentStore && woDocumentStore.hasChanges())
	$: hasChanges = hasAwoChanges || hasEditChanges

	function formatTime(unixTimestamp: number) {
		return new Intl.DateTimeFormat('en-US', {
			timeStyle: 'medium',
		}).format(new Date(unixTimestamp))
	}

	async function saveAutomaticWorkOrders() {
		const saves: Array<Promise<any>> = []

		if (automaticWorkOrdersToCreate.length > 0) {
			saves.push(
				createAutomaticWorkOrdersMutation.mutate({
					automaticWorkOrders: automaticWorkOrdersToCreate.map(awo => AutomaticWorkOrder.toCreateSave(awo)),
				}),
			)
		}

		if (automaticWorkOrdersToUpdate.length > 0) {
			saves.push(
				updateAutomaticWorkOrdersMutation.mutate({
					automaticWorkOrders: automaticWorkOrdersToUpdate.map(awo => AutomaticWorkOrder.toUpdateSave(awo)),
				}),
			)
		}

		if (automaticWorkOrdersToDelete.length > 0) {
			saves.push(
				deleteAutomaticWorkOrdersMutation.mutate({
					ids: automaticWorkOrdersToDelete.map(awo => awo.id).filter((x: number | null): x is number => typeof x === 'number'),
				}),
			)
		}

		if (workOrderFavoritesToUpdate.length > 0) {
			saves.push(
				saveFavoriteWorkOrdersMutation.mutate({
					workOrders: workOrderFavoritesToUpdate.map(({ id, favorite }) => ({
						id,
						favorite,
					})),
				}),
			)
		}

		await Promise.all(saves)
	}

	//#region Save Work Order
	//#region WO Format Functions
	// This return type is used for both saved and unsaved workorders, so if the name changes, update this to use the new one.
	function formatNewSample(sample: Sample): NewSampleOnUnsavedWorkOrder {
		// only send sample values if sample has been sampled or if they've entered a value
		const shouldSendSampleValues = sample.status !== 'OPEN' || sample.sampleValues.some(sv => sv.result)
		const sampleValuesToAdd = shouldSendSampleValues ? sample.sampleValues.map(formatNewSampleValue) : []
		return {
			analysisId: sample.analysis.id,
			collectedByUserId: sample.collectedBy?.id,
			comments: sample.samplingComments,
			due: sample.due,
			findings: sample.testingComments,
			incubationBegan: sample.incubationBegan,
			incubationEnded: sample.incubationEnded,
			investigationId: sample.investigationId,
			locationId: sample.location?.id,
			performed: sample.performed,
			plantId: sample.plant?.id,
			platesReadByUserId: sample.platesReadBy?.id,
			productId: sample.product?.id,
			sampleValuesToAdd,
			scheduled: sample.scheduled,
			status: sample.status,
			tagNumber: sample.tagNumber || undefined,
		}
	}

	function formatUpdateSample(sample: Sample): UpdateSample {
		// only send sample values if sample has been sampled or if they've entered a value
		const shouldSendSampleValues = sample.status !== 'OPEN' || sample.sampleValues.some(sv => sv.result)
		const svToUpdateSet = woDocumentStore.sampleValuesToUpdate
		const { sampleValuesToAdd, sampleValuesToUpdate } = shouldSendSampleValues
			? sample.sampleValues.reduce(
					(acc, sv) => {
						if (sv.id && svToUpdateSet.has(sv.id)) {
							acc.sampleValuesToUpdate.push(formatUpdateSampleValue(sv))
						} else if (!sv.id) {
							acc.sampleValuesToAdd.push(formatNewSampleValue(sv))
						}
						return acc
					},
					{ sampleValuesToAdd: new Array<NewSampleValue>(), sampleValuesToUpdate: [] as UpdateSampleValue[] },
				)
			: { sampleValuesToAdd: [], sampleValuesToUpdate: [] }

		return {
			analysisId: sample.analysis.id,
			collectedByUserId: sample.collectedBy?.id,
			comments: sample.samplingComments,
			due: sample.due,
			findings: sample.testingComments,
			id: sample.id,
			incubationBegan: sample.incubationBegan,
			incubationEnded: sample.incubationEnded,
			investigationId: sample.investigationId,
			locationId: sample.location?.id,
			performed: sample.performed,
			plantId: sample.plant?.id,
			platesReadByUserId: sample.platesReadBy?.id,
			productId: sample.product?.id,
			sampleValuesToAdd,
			sampleValuesToUpdate,
			scheduled: sample.scheduled,
			status: sample.status,
			tagNumber: sample.tagNumber || undefined,
		}
	}

	function formatNewSampleValue(sampleValue: SampleValue): NewSampleValue {
		return {
			analysisOptionId: sampleValue.analysisOption.id,
			defaultValue: sampleValue.defaultValue,
			// This *should* always be defined, but a user hit a bug where it maybe wasn't
			result: sampleValue.result?.toString() ?? '',
		}
	}

	function formatUpdateSampleValue(sampleValue: SampleValue): UpdateSampleValue {
		return {
			analysisOptionId: sampleValue.analysisOption.id,
			defaultValue: sampleValue.defaultValue,
			id: sampleValue.id,
			result: sampleValue.result?.toString() ?? '',
		}
	}
	//#endregion
	/*
	 * Work Order Saving works in "Stages" because we may need to confirm multiple things with a user before saving.
	 * Each stage will call the next one in order, or abort early and show its own error message.
	 * Stages:
	 * 1: Confirm Auth: If a sample requires authentication to perform, we need to confirm the user's password before saving.
	 * 2: Check Required: Check if all required fields are filled out on all samples being saved.
	 * 3: Ask to Complete/Close: If all samples are filled out, ask the user if they want to complete/close the work order.
	 * 3.5: Ask to Close: If all samples are closed, ask the user if they want to close the work order.
	 * 4: Save: Save the work order.
	 */

	function showWoSaveError(message: string, heading = translate('workOrder.errorSavingWoHeading', 'Error Saving Work Order')) {
		mediator.call('showMessage', {
			type: 'danger',
			heading,
			message,
			time: false,
		})
	}

	/** Force the user to re-auth if they're performing a sample whose analysis requires auth*/
	async function confirmAuth() {
		const plantId = $woDocumentStore?.plantId
		const recordId = $woDocumentStore?.id
		if (!plantId) {
			authModalData.error = translate('workOrder.plantIdNotFoundError', 'Plant ID not found')
			return
		}
		try {
			authModalData.loading = true
			const { data } = await verifyPermission.mutate({
				input: {
					action: 'AUTH_SAMPLE_ON_WORK_ORDER',
					password: authModalData.password,
					plantId,
					recordId,
				},
			})
			if (data?.verifyPermission) {
				authModalData = {
					show: false,
					password: '',
					error: '',
					loading: false,
					passwordInput: authModalData.passwordInput,
				}
				await checkRequiredWoFields(data.verifyPermission)
			}
		} catch (err) {
			const message = err instanceof Error ? err.message : translate('workOrder.unknownError', 'An unknown error occurred')
			authModalData.loading = false
			authModalData.error = message
			console.log(getErrorCode(err), authModalData)
			if (!authModalData.show) {
				showWoSaveError(message)
			} else if (authModalData.show && getErrorCode(err) === 'VERIFY_PERMISSION_ERROR_BAD_CREDENTIALS') {
				authModalData.passwordInput?.select()
			}
		}
	}

	async function checkRequiredWoFields(userAccessId: number | null = null) {
		const wo = $woDocumentStore
		if (!wo) {
			return
		}

		// If we're closing the WO, set all samples to closed.
		// If this is invalid, then we'll catch it when we validate each sample.
		// Desktop doesn't care if you mark a WO with Open samples as Completed, so don't worry about that.
		if (wo.documentStatus === 'CLOSED') {
			mediator.call('updateSampleStatuses', 'CLOSED')
		}

		// Check Samples before we check the WO
		let samplesBeingSaved = [...sampleCrudStore.createdValues, ...sampleCrudStore.updatedValues]
		const canPerformPartial = hasPermission('WORK_ORDERS_CAN_PERFORM_PARTIALLY_COMPLETED', wo.plantId)
		const canClosePartial = hasPermission('WORK_ORDERS_CAN_CLOSE_PARTIALLY_COMPLETED', wo.plantId)

		try {
			if (!wo.id && !samplesBeingSaved.length) {
				throw new Error(translate('workOrder.mustHaveSamplesError', 'A work order must have at least one sample.'))
			} else if (samplesBeingSaved.some(sample => !sample.analysis)) {
				throw new Error(translate('workOrder.mustHaveAnalysisError', 'All samples must have an analysis.\nPlease choose a valid analysis or delete the sample.'))
			}
			// Check created/updated samples for missing required values
			// If sample is performed, make sure all required to perform are filled out
			// If sample is closed, make sure all required to close are filled out
			samplesBeingSaved.forEach(sample => {
				const missingValuesForStatus = mediator.call('sampleMissingRequiredValuesForStatus', sample, sample.status)
				const hasRequiredValuesForStatus = !missingValuesForStatus.length
				// separate strings for with/without tag# for translation
				const missingValuesString = sample.tagNumber
					? translate(
							'workOrder.missingValuesString',
							`Analysis "{{- analysisName}}" for sample with tag #{{- sample.tagNumber}} is missing values for the following required fields:\n{{- missingFields}}.`,
							{
								analysisName: sample.analysis.name,
								tagNumber: sample.tagNumber,
								missingFields: missingValuesForStatus.map(sv => sv.analysisOption.option).join(', '),
							},
						)
					: translate('workOrder.missingValuesStringNoTag', `Analysis "{{- analysisName}}" on an unsaved sample is missing values for the following required fields:\n{{- missingFields}}.`, {
							analysisName: sample.analysis.name,
							missingFields: missingValuesForStatus.map(sv => sv.analysisOption.option).join(', '),
						})

				if (!hasRequiredValuesForStatus && sample.status === 'CLOSED' && !canClosePartial) {
					// Tell them they can't close it
					throw new Error(`${missingValuesString}\n${translate('workOrder.allRequiredOptionsMustBeFilled', 'All required options must be filled out before the sample can be closed.')}`)
				} else if (!hasRequiredValuesForStatus && sample.status === 'CLOSED' && canClosePartial) {
					// Ask them if they want to close it anyways
					if (!confirm(`${missingValuesString}\n${translate('workOrder.wouldYouLikeToClose', 'Would you like to close it anyways?')}`)) {
						throw new Error(missingValuesString)
					}
					// Mark samples as closed and proceed!
				} else if (!hasRequiredValuesForStatus && sample.status === 'SAMPLED' && !canPerformPartial) {
					// Tell them they can't perform it
					throw new Error(`${missingValuesString}\n${translate('workOrder.allRequiredOptionsMustBeFilled', 'All required options must be filled out before the sample can be performed.')}`)
				} else if (!hasRequiredValuesForStatus && sample.status === 'SAMPLED' && canPerformPartial) {
					// Ask them if they want to perform it anyways
					if (!confirm(`${missingValuesString}\n${translate('workOrder.wouldYouLikeToPerform', 'Would you like to perform it anyways?')}`)) {
						throw new Error(missingValuesString)
					}
				}
			})

			// Now that the samples are good, check the WO has everything needed to close
			const { titleMissing, productBatchMissing, verificationMissing } = mediator.call('woTypeFieldsMissing')
			const closed = wo.documentStatus === 'CLOSED'

			if (closed && verificationMissing) {
				throw new Error(translate('workOrder.verificationRequired', 'Verification is required to close this Work Order'))
			} else if (closed && titleMissing && productBatchMissing) {
				throw new Error(translate('workOrder.titleBatchRequired', 'Title and product batch are required to close this Work Order'))
			} else if (closed && titleMissing) {
				throw new Error(translate('workOrder.titleRequired', 'Title is required to close this Work Order'))
			} else if (closed && productBatchMissing) {
				throw new Error(translate('workOrder.batchRequired', 'Product batch is required to close this Work Order'))
			}
		} catch (err) {
			showWoSaveError((err as Error).message, translate('workOrder.unableToSaveWoHeading', 'Unable to Save Work Order'))
			return
		}

		return await askToCompleteOrClose(userAccessId)
	}

	async function askToCompleteOrClose(userAccessId: number | null = null) {
		// Don't offer to close if we're missing required fields!
		const { titleMissing, productBatchMissing, verificationMissing } = mediator.call('woTypeFieldsMissing')
		const missingRequiredFields = titleMissing || productBatchMissing || verificationMissing

		// allPerformed includes closed samples
		const allPerformed = mediator.call('allSamplesPerformed')
		// return early to save some sample looping if we don't need to
		if (!allPerformed || $woDocumentStore?.documentStatus === 'CLOSED' || ($woDocumentStore?.documentStatus === 'SAMPLED' && missingRequiredFields)) {
			return await saveWorkOrder(userAccessId)
		}

		async function openModal(status: 'SAMPLED' | 'CLOSED') {
			completeModalData = {
				show: true,
				userAccessId,
				status,
			}
			await tick()
			// focus the first button, either close or complete
			document.querySelector<HTMLButtonElement>('#complete-modal ~ .modal-footer button')?.focus()
		}

		const allClosed = mediator.call('allSamplesClosed')
		if (allClosed && !missingRequiredFields) {
			return await openModal('CLOSED')
		}

		const allSamplesComplete = mediator.call('allSamplesCompleted')
		if (allSamplesComplete && $woDocumentStore?.documentStatus === 'OPEN') {
			return await openModal('SAMPLED')
		}
		return await saveWorkOrder(userAccessId)
	}

	async function completeOrClose(status: 'CLOSED' | 'SAMPLED' | null) {
		completeModalData.show = false
		if ($woDocumentStore && status) {
			$woDocumentStore.documentStatus = status
			$woDocumentStore.statusChanged = true
		}
		await saveWorkOrder(completeModalData.userAccessId)
	}

	async function saveWorkOrder(userAccessId: number | null = null) {
		try {
			// Create/Update document fields
			const woDocument = woDocumentStore.get()

			if (!woDocument) {
				throw new Error(translate('workOrder.noUnsavedChanges', 'No unsaved changes found.'))
			}

			let workOrderId: number | null = woDocument.id ?? null

			const samplesToCreate = sampleCrudStore.createdValues
			const samplesToUpdate = sampleCrudStore.updatedValues
			const formattedSamplesToCreate = samplesToCreate.map(formatNewSample)
			const formattedSamplesToUpdate = samplesToUpdate.map(formatUpdateSample)

			if (
				woDocument &&
				woDocumentStore.allSamplesPerformed &&
				woDocument.documentStatus === 'OPEN' &&
				confirm(translate('workOrder.confirmCompleteWo', 'All items on this WO have been completely filled out, would you like to complete this WO?'))
			) {
				woDocument.documentStatus = 'SAMPLED'
				woDocument.statusChanged = true
			}

			// Do not optional chain tehse conditionals, we only want to check the type of id if woDocument is not null
			if (woDocument && typeof woDocument?.id !== 'undefined') {
				// update
				const { documentStatus, statusChanged, ...updateFields } = woDocument
				await updateWorkOrdersMutation.mutate({
					workOrders: [
						{
							userAccessId,
							...updateFields,
							samplesToAdd: formattedSamplesToCreate,
							samplesToUpdate: formattedSamplesToUpdate,
							samplesToRemove: sampleCrudStore.deletedValues.map(s => s.id).filter(id => id),
						},
					],
				})
			} else if (woDocument && typeof woDocument.id === 'undefined') {
				const { statusChanged, documentStatus, ...createFields } = woDocument
				const { data } = await createWorkOrderMutation.mutate({
					workOrder: {
						userAccessId,
						...createFields,
						// Save it as open first, then the changeStatus mutation will update it.
						documentStatus: 'OPEN',
						samples: formattedSamplesToCreate,
					},
				})
				if (!data?.createWorkOrder.id) {
					throw new Error(translate('workOrder.failedToCreateWo', 'Failed to create work order'))
				}
				workOrderId = data.createWorkOrder.id
			}

			if (workOrderId === null) {
				console.error(workOrderId, woDocument)
				throw new Error(translate('workOrder.woSaveNoId', 'Work Order save did not return a valid ID.'))
			}

			// Update status if it changed
			if ((workOrderId && woDocument?.statusChanged) || (woDocument && !woDocument.id && woDocument.documentStatus !== 'OPEN')) {
				await changeWorkOrderStatus.mutate({
					status: woDocument.documentStatus,
					changeWorkOrderStatusId: workOrderId.toString(),
				})
			}

			// Create Sample Attachments
			const sampleAttachmentsToCreate = sampleAttachmentCrudStore.createdValues.filter(
				(attachment): attachment is MetaSampleAttachment & { File: File; sampleId: number } => !!attachment.File && !!attachment.sampleId,
			)

			if (sampleAttachmentsToCreate.length) {
				await pMap(
					sampleAttachmentsToCreate,
					async input => {
						await attachFileToSample.mutate({
							input: {
								base64String: await b64ify(input.File),
								fileName: input.name,
								sampleId: input.sampleId,
								rank: input.rank,
							},
						})
					},
					{ concurrency: 2 },
				)
			}
			// Delete Sample Attachments
			const sampleAttachmentsToDelete = Object.entries(
				sampleAttachmentCrudStore.deletedValues.reduce((acc: Record<number, Array<number>>, { sampleId, fileId }) => {
					if (sampleId && fileId) {
						if (acc[sampleId]) {
							acc[sampleId].push(fileId)
						} else {
							acc[sampleId] = [fileId]
						}
					}
					return acc
				}, {}),
			)
			if (sampleAttachmentsToDelete.length) {
				await pMap(
					sampleAttachmentsToDelete,
					async ([sampleId, fileIds]) => {
						await detachFilesFromSample.mutate({
							fileIds,
							sampleId: parseInt(sampleId, 10),
						})
					},
					{ concurrency: 2 },
				)
			}
			sampleCrudStore.clear()
			woDocumentStore.clear()
			mediator.call('showMessage', {
				type: 'success',
				heading: translate('workOrder.savedHeading', 'Saved!'),
				message: translate('workOrder.savedMessage', 'Work order saved successfully.'),
				time: 10,
			})
			asr.go(null, { lastSavedTime: Date.now(), workOrderId }, { inherit: false })
		} catch (err) {
			showWoSaveError((err as Error).message)
		}
	}
	//#endregion

	function sampleWasPerformed(sample: Sample) {
		return sample.status === 'SAMPLED' || sample.status === 'CLOSED' || sample.sampleValues.some(sv => !sv.id || woDocumentStore.sampleValuesToUpdate.has(sv.id))
	}

	async function save() {
		if (selectedTab === 'app.work-order.automatic') {
			try {
				await saveAutomaticWorkOrders()
				workOrderFavoritesStore.clear()
				automaticWorkOrderCrudStore.clear()
				asr.go(null, { lastSavedTime: Date.now() }, { inherit: true })
			} catch (err: any) {
				mediator.call('showMessage', {
					type: 'danger',
					heading: translate('common:automaticWorkOrderSaveError', 'Error Saving Automatic Work Orders'),
					message: err.message,
					time: false,
				})
				console.error(err)
			}
		} else if (selectedTab === 'app.work-order.edit') {
			try {
				const samplesBeingSaved = [...sampleCrudStore.createdValues, ...sampleCrudStore.updatedValues]
				const requiresAuth = samplesBeingSaved.some(sample => sample.analysis.requireAuthentication && sampleWasPerformed(sample))
				if (requiresAuth) {
					authModalData = {
						show: true,
						password: '',
						error: '',
						loading: false,
						passwordInput: authModalData.passwordInput,
					}
					await tick()
					document.querySelector<HTMLInputElement>('#auth-modal-password')?.focus()
					return
				}
				await checkRequiredWoFields()
			} catch (err: any) {
				showWoSaveError(err.message)
				console.error(err)
			}
		}
	}
	const removeSaveProvider = mediator.provide('saveWorkOrder', save)
	onDestroy(() => {
		removeSaveProvider()
	})

	// #region GQL Documents

	const createAutomaticWorkOrdersMutation = graphql(`
		mutation CreateAutomaticWorkOrders($automaticWorkOrders: [NewAutomaticWorkOrder!]!) {
			createAutomaticWorkOrders(automaticWorkOrders: $automaticWorkOrders) {
				id
			}
		}
	`)
	const updateAutomaticWorkOrdersMutation = graphql(`
		mutation UpdateAutomaticWorkOrders($automaticWorkOrders: [UpdateAutomaticWorkOrder!]!) {
			updateAutomaticWorkOrders(automaticWorkOrders: $automaticWorkOrders) {
				id
			}
		}
	`)
	const deleteAutomaticWorkOrdersMutation = graphql(`
		mutation DeleteAutomaticWorkOrders($ids: [PositiveInt!]!) {
			deleteAutomaticWorkOrders(ids: $ids)
		}
	`)
	const saveFavoriteWorkOrdersMutation = graphql(`
		mutation SaveFavoriteWorkOrders($workOrders: [UpdateWorkOrder!]!) {
			updateWorkOrders(workOrders: $workOrders) {
				id
				favorite
			}
		}
	`)

	const createWorkOrderMutation = graphql(`
		mutation WoCreateWorkOrder($workOrder: NewWorkOrder!) {
			createWorkOrder(workOrder: $workOrder) {
				id
			}
		}
	`)

	const updateWorkOrdersMutation = graphql(`
		mutation WoUpdateWorkOrders($workOrders: [UpdateWorkOrder!]!) {
			updateWorkOrders(workOrders: $workOrders) {
				id
			}
		}
	`)

	const changeWorkOrderStatus = graphql(`
		mutation WoChangeWorkOrderStatus($status: DocumentStatus!, $changeWorkOrderStatusId: ID!) {
			changeWorkOrderStatus(status: $status, id: $changeWorkOrderStatusId) {
				documentStatus
			}
		}
	`)

	const attachFileToSample = graphql(`
		mutation AttachFileToSample($input: NewSampleFile!) {
			attachFileToSample(input: $input) {
				fileId
			}
		}
	`)

	const detachFilesFromSample = graphql(`
		mutation DetachFilesFromSample($fileIds: [PositiveInt!]!, $sampleId: PositiveInt!) {
			detachFilesFromSample(fileIds: $fileIds, sampleId: $sampleId)
		}
	`)

	const verifyPermission = graphql(`
		mutation VerifyPermission($input: VerifyPermissionInput!) {
			verifyPermission(input: $input)
		}
	`)

	// #endregion
</script>

<div class="card">
	<StateCardHeader
		title={translate('common:workOrders', 'Work Orders')}
		icon="clipboard-list-check"
	>
		<svelte:fragment slot="right">
			<span class="mr-3 align-self-center">
				{translate('common:lastSaved', 'Last saved')}: {formattedLastSavedTime ? formattedLastSavedTime : translate('common:never', 'never')}
			</span>
			<SaveResetButton
				{save}
				btnGroupClass="mobile-button-100"
				disabled={!canEditWorkOrders || !hasChanges}
				resetHref={asr.makePath(null, { lastResetTime: Date.now(), lastSavedTime: null }, { inherit: true })}
			/>
		</svelte:fragment>
		<AsrNavTabBar
			tabs={childStates}
			{asr}
			breakpoint="md"
			bind:selectedTab
		></AsrNavTabBar>
	</StateCardHeader>

	<uiView></uiView>
</div>

<Modal
	bind:show={authModalData.show}
	title={translate('workOrder.authenticateTitle', 'Authenticate to Save')}
	confirmButtonDisabled={!authModalData.password}
	confirmButtonIsLoading={authModalData.loading}
	confirmButtonType="submit"
	confirmButtonFormId="auth-form"
	backdropClickCancels={false}
	on:close={() => {
		authModalData = {
			show: false,
			password: '',
			error: '',
			loading: false,
			passwordInput: undefined,
		}
	}}
>
	<h6>{translate('workOrder.authenticateSubtitle1', 'One or more samples require authentication to perform.')}</h6>
	<h6>{translate('workOrder.authenticateSubtitle2', 'Please enter your password to continue.')}</h6>
	<form
		id="auth-form"
		on:submit|preventDefault={confirmAuth}
	>
		<Input
			readonly
			showLabel={false}
			value={$session.userName}
		/>
		<Input
			id="auth-modal-password"
			form="auth-form"
			showLabel={false}
			placeholder={translate('workOrder.passwordPlaceholder', 'Password')}
			type="password"
			bind:value={authModalData.password}
			bind:input={authModalData.passwordInput}
		></Input>
	</form>
	{#if authModalData.error}
		<div
			class="alert alert-danger"
			role="alert"
			transition:slide={{ duration: 200 }}
		>
			<Icon icon="exclamation-triangle" />
			{authModalData.error}
		</div>
	{/if}
</Modal>

<Modal
	modalId="complete-modal"
	bind:show={completeModalData.show}
	title={translate('workOrder.completeWoTitle', 'Complete Work Order?')}
	confirmShown={false}
	cancelShown={false}
	backdropClickCancels={false}
	on:close={() => (completeModalData.show = false)}
>
	{completeModalData.status === 'SAMPLED'
		? translate('workOrder.completeWoSubtitle', 'All items on this Work Order have been completely filled out. Would you like to complete the Work Order?')
		: translate('workOrder.completeWoCloseSubtitle', 'All items on this Work Order are marked as closed. Would you like to close the Work Order?')}
	<svelte:fragment slot="modalFooter">
		{#if completeModalData.status === 'CLOSED'}
			<Button
				outline
				color="danger"
				iconClass="lock"
				class="flex-grow-1"
				on:click={() => completeOrClose('CLOSED')}>{translate('workOrder.closeWoLabel', 'Close WO')}</Button
			>
		{/if}
		<Button
			outline
			iconClass="clipboard-check"
			class="flex-grow-1"
			on:click={() => completeOrClose('SAMPLED')}>{translate('workOrder.completeWoLabel', 'Complete WO')}</Button
		>
		<Button
			outline
			color="success"
			iconClass="clipboard"
			class="flex-grow-1"
			on:click={() => completeOrClose(null)}>{translate('workOrder.leaveOpenLabel', 'Leave Open')}</Button
		>
	</svelte:fragment>
</Modal>

<style>
	@media (max-width: 767px) {
		:global(.mobile-button-100.btn-group),
		:global(.mobile-button-100.btn) {
			width: 100%;
			margin-bottom: 0.25rem;
		}

		:global(.mobile-button-50.btn) {
			width: 50%;
			margin-bottom: 0.25rem;
		}
	}

	:global(#complete-modal ~ .modal-footer div) {
		display: flex;
		flex-grow: 1;
		flex-wrap: wrap;
		justify-content: space-between;
		gap: 0.25rem;
	}
</style>
