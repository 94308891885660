import { QueryStore } from '../runtime/stores/query'
import artifact from '$houdini/artifacts/ShProductBatches'
import { initClient } from '$houdini/plugins/houdini-svelte/runtime/client'

export class ShProductBatchesStore extends QueryStore {
	constructor() {
		super({
			artifact,
			storeName: "ShProductBatchesStore",
			variables: false,
		})
	}
}

export async function load_ShProductBatches(params) {
	await initClient()

	const store = new ShProductBatchesStore()

	await store.fetch(params)

	return {
		ShProductBatches: store,
	}
}
