import { QueryStore } from '../runtime/stores/query'
import artifact from '$houdini/artifacts/ShGetGeneratedDescription'
import { initClient } from '$houdini/plugins/houdini-svelte/runtime/client'

export class ShGetGeneratedDescriptionStore extends QueryStore {
	constructor() {
		super({
			artifact,
			storeName: "ShGetGeneratedDescriptionStore",
			variables: true,
		})
	}
}

export async function load_ShGetGeneratedDescription(params) {
	await initClient()

	const store = new ShGetGeneratedDescriptionStore()

	await store.fetch(params)

	return {
		ShGetGeneratedDescription: store,
	}
}
