export default {
    "name": "PlantConfigurationData",
    "kind": "HoudiniQuery",
    "hash": "64f4f2a76ebd951ca3f4c2fb9a546bb321384f0843c223b54f31b9c08e8ac0af",

    "raw": `query PlantConfigurationData($entityTagFilter: EntityTagFilter!) {
  plants(pagination: {pageSize: 0}) {
    data {
      id
      name
      code
      private
      street
      city
      state
      zip
      country
      phone
      fax
      timezone
      tags {
        id
        name
        entityType
        active
      }
      getPlantUsageCount {
        analyseCount
        productCount
        tagCount
      }
    }
  }
  states {
    abbreviation: stateAbbreviation
    name: stateName
  }
  entityTags(filter: $entityTagFilter) {
    id
    name
    entityType
    active
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "plants": {
                "type": "PlantResponse",
                "keyRaw": "plants(pagination: {pageSize: 0})",

                "selection": {
                    "fields": {
                        "data": {
                            "type": "Plant",
                            "keyRaw": "data",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    },

                                    "code": {
                                        "type": "String",
                                        "keyRaw": "code",
                                        "visible": true
                                    },

                                    "private": {
                                        "type": "Boolean",
                                        "keyRaw": "private",
                                        "visible": true
                                    },

                                    "street": {
                                        "type": "String",
                                        "keyRaw": "street",
                                        "visible": true
                                    },

                                    "city": {
                                        "type": "String",
                                        "keyRaw": "city",
                                        "visible": true
                                    },

                                    "state": {
                                        "type": "String",
                                        "keyRaw": "state",
                                        "visible": true
                                    },

                                    "zip": {
                                        "type": "String",
                                        "keyRaw": "zip",
                                        "visible": true
                                    },

                                    "country": {
                                        "type": "String",
                                        "keyRaw": "country",
                                        "visible": true
                                    },

                                    "phone": {
                                        "type": "String",
                                        "keyRaw": "phone",
                                        "visible": true
                                    },

                                    "fax": {
                                        "type": "String",
                                        "keyRaw": "fax",
                                        "visible": true
                                    },

                                    "timezone": {
                                        "type": "String",
                                        "keyRaw": "timezone",
                                        "visible": true
                                    },

                                    "tags": {
                                        "type": "EntityTag",
                                        "keyRaw": "tags",

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "name": {
                                                    "type": "String",
                                                    "keyRaw": "name",
                                                    "visible": true
                                                },

                                                "entityType": {
                                                    "type": "EntityTagType",
                                                    "keyRaw": "entityType",
                                                    "visible": true
                                                },

                                                "active": {
                                                    "type": "Boolean",
                                                    "keyRaw": "active",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "getPlantUsageCount": {
                                        "type": "PlantUsageCount",
                                        "keyRaw": "getPlantUsageCount",

                                        "selection": {
                                            "fields": {
                                                "analyseCount": {
                                                    "type": "NonNegativeInt",
                                                    "keyRaw": "analyseCount",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "productCount": {
                                                    "type": "NonNegativeInt",
                                                    "keyRaw": "productCount",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "tagCount": {
                                                    "type": "NonNegativeInt",
                                                    "keyRaw": "tagCount",
                                                    "nullable": true,
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "states": {
                "type": "State",
                "keyRaw": "states",
                "nullable": true,

                "selection": {
                    "fields": {
                        "abbreviation": {
                            "type": "NonEmptyString",
                            "keyRaw": "abbreviation",
                            "visible": true
                        },

                        "name": {
                            "type": "NonEmptyString",
                            "keyRaw": "name",
                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "entityTags": {
                "type": "EntityTag",
                "keyRaw": "entityTags(filter: $entityTagFilter)",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "name": {
                            "type": "String",
                            "keyRaw": "name",
                            "visible": true
                        },

                        "entityType": {
                            "type": "EntityTagType",
                            "keyRaw": "entityType",
                            "visible": true
                        },

                        "active": {
                            "type": "Boolean",
                            "keyRaw": "active",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "entityTagFilter": "EntityTagFilter"
        },

        "types": {
            "EntityTagFilter": {
                "active": "Boolean",
                "entityTypes": "EntityTagType",
                "ids": "PositiveInt"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=5e75cb291e596ad849db5704a3e1583b0d30680ccbf36c372311455f4b147981";