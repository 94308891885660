export default {
    "name": "CreateEntityTags",
    "kind": "HoudiniMutation",
    "hash": "7fcc35aae84acf32efcffb9892ebbbb2be8634e7a707682de2316cf1a17ac58d",

    "raw": `mutation CreateEntityTags($input: [NewEntityTag!]!) {
  createEntityTags(input: $input) {
    active
    entityType
    id
    name
  }
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "createEntityTags": {
                "type": "EntityTag",
                "keyRaw": "createEntityTags(input: $input)",

                "selection": {
                    "fields": {
                        "active": {
                            "type": "Boolean",
                            "keyRaw": "active",
                            "visible": true
                        },

                        "entityType": {
                            "type": "EntityTagType",
                            "keyRaw": "entityType",
                            "visible": true
                        },

                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "name": {
                            "type": "String",
                            "keyRaw": "name",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "input": "NewEntityTag"
        },

        "types": {
            "NewEntityTag": {
                "active": "Boolean",
                "entityType": "EntityTagType",
                "name": "String"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=6c4830d7756865cc61c55656ec94737f7a53e35fa60c78d7de4b08fdbfd39124";