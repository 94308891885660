export default {
    "name": "PlantsForDropdown",
    "kind": "HoudiniQuery",
    "hash": "ebb6f88560cbf19c2971602551912010524777fec8dc8e7fefbab6d9bf6b4c4d",

    "raw": `query PlantsForDropdown($pagination: PaginatedInput) {
  plants(pagination: $pagination) {
    data {
      id
      name
      code
    }
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "plants": {
                "type": "PlantResponse",
                "keyRaw": "plants(pagination: $pagination)",

                "selection": {
                    "fields": {
                        "data": {
                            "type": "Plant",
                            "keyRaw": "data",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    },

                                    "code": {
                                        "type": "String",
                                        "keyRaw": "code",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "pagination": "PaginatedInput"
        },

        "types": {
            "PaginatedInput": {
                "pageNumber": "PositiveInt",
                "pageSize": "NonNegativeInt"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=e645961642132f609f6fb55f4551f7f669fd97e4168e0aadf5b18b3e0284d611";