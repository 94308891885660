import { graphql } from '$houdini'

export const createEntityTags = graphql(`
	mutation CreateEntityTags($input: [NewEntityTag!]!) {
		createEntityTags(input: $input) {
			active
			entityType
			id
			name
		}
	}
`)

export const updateEntityTags = graphql(`
	mutation UpdateEntityTags($input: [EntityTagUpdate!]!) {
		updateEntityTags(input: $input) {
			id
		}
	}
`)

export const deleteEntityTags = graphql(`
	mutation DeleteEntityTags($ids: [PositiveInt!]!) {
		deleteEntityTags(ids: $ids)
	}
`)
