<script lang="ts">
	import { loadProductSpecificationsQuery, makeSpecificationCache, type Analysis, type Batch, type Plant, type ProductNode, type SeverityClass } from 'utility/product-helper'
	import type { ProductBatchesLocations$result } from '$houdini'
	import type { CrudMap } from '@isoftdata/svelte-store-crud'
	import type { i18n } from 'types/common'

	type Location = ProductBatchesLocations$result['locations']['data'][number]

	import Modal from '@isoftdata/svelte-modal'
	import { Table, Td, type Column } from '@isoftdata/svelte-table'
	import ConfigureSpecificationCard, { type MetaSpecification } from 'components/ConfigureSpecificationCard.svelte'
	import TextArea from '@isoftdata/svelte-textarea'
	import Autocomplete from '@isoftdata/svelte-autocomplete'
	import Select from '@isoftdata/svelte-select'
	import Button from '@isoftdata/svelte-button'
	import { Dropdown } from '@isoftdata/svelte-dropdown'
	import { DropdownCheckboxItem } from '@isoftdata/svelte-context-menu'
	import Input from '@isoftdata/svelte-input'

	import { graphql } from '$houdini'
	import { createEventDispatcher, getContext, onMount, tick } from 'svelte'
	import { v4 as uuid } from '@lukeed/uuid'
	import { getEventValue, getEventValueEnum } from '@isoftdata/browser-event'
	import session from 'stores/session'
	import { formatDateTimeForInput, getEventUtcDate } from 'utility/timezone'
	import { klona } from 'klona'
	import getDuplicateName from 'utility/get-duplicate-name'
	import userLocalWritable from '@isoftdata/svelte-store-user-local-writable'
	import makeCrudStore from '@isoftdata/svelte-store-crud'

	export let plantId: number
	export let plants: Array<Plant>
	export let analyses: Array<Analysis>
	export let analysesById: Record<number, Analysis>
	export let severityClasses: Array<SeverityClass>
	export let canEditChoice: (plantId: number | null) => boolean
	export let canEditProduct: boolean

	let specifications: MetaSpecification[] = []
	let selectedSpecification: MetaSpecification | null = null
	let selectedProduct: ProductNode | null = null
	let selectedBatch: Batch | null = null
	let show = false
	let clipboard: Batch | null = null
	let specificationsClipboard: Array<MetaSpecification> | null = null

	let locations: Array<Location> = []
	let batches: Array<Batch> = []
	let specificationCache = makeSpecificationCache() // has to be let b/c it's bound and not a store

	/** Cache batches by product uuid */
	const batchesCache: Map<string, Array<Batch>> = new Map()
	const batchCrudStore = makeCrudStore<Batch, 'uuid'>('uuid')
	const specificationCrudStore = makeCrudStore<MetaSpecification, 'uuid'>('uuid')
	const copyWithSpecifications = userLocalWritable($session.userAccountId, 'product-batches-copy-with-specifications', false)
	const dispatch = createEventDispatcher<{
		confirm: {
			batchCrud: CrudMap<Batch>
			specificationCrud: CrudMap<MetaSpecification>
		}
	}>()
	const { t: translate } = getContext<i18n>('i18next')
	const batchColumns: Array<Column<Batch>> = [
		{
			name: translate('product.batchModal.nameColumnName', 'Name/Number'),
			property: 'name',
			minWidth: '200px',
			title: translate('product.batchModal.nameColumnTitle', 'A name or number that identifies the batch'),
		},
		{
			name: translate('product.batchModal.descriptionColumnName', 'Description'),
			property: 'description',
			minWidth: '200px',
			title: translate('product.batchModal.descriptionColumnTitle', 'A full description of the batch'),
		},
		{
			name: translate('product.batchModal.locationColumnName', 'Location'),
			property: 'location[location]',
			minWidth: '200px',
			title: translate('product.batchModal.locationColumnTitle', '(Optional) A location to associate with the batch'),
		},
		{
			name: translate('product.batchModal.startDateColumnName', 'Start Date'),
			property: 'start',
			minWidth: '150px',
			title: translate('product.batchModal.startDateColumnTitle', 'The beginning of when this batch is in effect and can be chosen on Work Orders'),
		},
		{
			name: translate('product.batchModal.endDateColumnName', 'End Date'),
			property: 'end',
			minWidth: '150px',
			title: translate('product.batchModal.endDateColumnTitle', '(Optional) the last date this batch will be in effect and can be chosen on Work Orders'),
		},
		{
			name: translate('product.batchModal.statusColumnName', 'Status'),
			property: 'status',
			minWidth: '120px',
			title: translate(
				'product.batchModal.statusColumnTitle',
				'Current batch status.\r\nActive: can be chosen on new work orders.\r\nInactive: manually ended batches. These can no longer be chosen on new work orders.\r\nExpired: batches automatically closed out due to the batch end date passing.',
			),
		},
	]

	// Watch the specification crud store for changes (IE sub to it)
	// If the change has a batchUuid we need to update that batch in the batch store to say it has updated as well

	function specsChangedForBatch() {
		const batchUuidsChanged = new Set([...specificationCrudStore.updatedValues, ...specificationCrudStore.createdValues, ...specificationCrudStore.deletedValues].map(spec => spec.productBatchUuid))
		batchUuidsChanged.forEach(batchUuid => {
			const batch = batches.find(batch => batch.uuid === batchUuid)
			if (batch) {
				batchCrudStore.update(batch)
			}
			// If we didn't find the batch its probably new and the specifications will get picked up passivly
		})
	}

	export function open(product: ProductNode, { batchCrud, specificationCrud }: { batchCrud: CrudMap<Batch>; specificationCrud: CrudMap<MetaSpecification> }) {
		selectedProduct = product
		show = true
		$batchCrudStore = klona(batchCrud)
		$specificationCrudStore = klona(specificationCrud)
		fetchBatches()
	}

	function confirm() {
		const productUuid = selectedProduct?.uuid
		if (!productUuid) {
			return
		}

		batchesCache.set(productUuid, batches)
		if (selectedBatch) {
			specificationCache.set(productUuid, selectedBatch.uuid, specifications)
		}
		specsChangedForBatch()
		dispatch('confirm', {
			batchCrud: klona($batchCrudStore),
			specificationCrud: klona($specificationCrudStore),
		})
		reset()
	}

	function close() {
		// Clear spec cache for any batches that had spec changes so next time we open the modal we don't have discarded changes
		const specChanges = [...specificationCrudStore.createdValues, ...specificationCrudStore.updatedValues]
		const batchesWithSpecChanges = new Set(specChanges.map(spec => spec.productBatchUuid))
		const selectedProductUuid = selectedProduct?.uuid
		if (batchesWithSpecChanges.size && selectedProductUuid) {
			batchesWithSpecChanges.forEach(batchUuid => {
				if (batchUuid) {
					specificationCache.delete(selectedProductUuid, batchUuid)
				}
			})
		}
		// Clear batches cache for this product
		if (selectedProductUuid) {
			batchesCache.delete(selectedProductUuid)
		}
		reset()
	}

	function reset() {
		show = false
		specifications = []
		batches = []
		batchCrudStore.clear()
		specificationCrudStore.clear()
	}

	async function fetchBatches() {
		const productId = selectedProduct?.id
		const productUuid = selectedProduct?.uuid
		// don't re-fetch if we already have the data from the last time they opened the modal
		if (productUuid && batchesCache.has(productUuid)) {
			batches = batchesCache.get(productUuid) ?? []
			selectBatch(batches[0] ?? null)
		} else if (productId && productUuid && (!batches.length || batches[0].productId !== productId)) {
			const { data } = await batchesQuery.fetch({
				variables: {
					filter: {
						productIds: [productId],
						plantIds: [plantId],
					},
				},
			})

			batches = (data?.productBatches.data ?? []).map(batch => ({ ...batch, productUuid, uuid: uuid() }))
			// Merge in previously created and updated batches. This might happen if the user confirms some changes, discards others, then re-opens the modal.
			batches.push(...batchCrudStore.createdValues.filter(batch => batch.productId === productId || batch.productUuid === productUuid))
			batches = bulkUpdate(
				batches,
				batchCrudStore.updatedValues.filter(batch => batch.productId === productId || batch.productUuid === productUuid),
				'uuid',
			)

			batchesCache.set(productUuid, batches)
			selectBatch(batches[0] ?? null)
		}
	}

	async function selectBatch(batch: Batch | null) {
		if (!selectedProduct) {
			return
		}
		const productUuid = selectedProduct.uuid

		selectedBatch = batch
		specifications = []

		if (!selectedBatch) {
			return
		}
		const productBatchUuid = selectedBatch.uuid

		if (specificationCache.has(productUuid, productBatchUuid)) {
			specifications = specificationCache.get(productUuid, productBatchUuid)
		} else if (selectedBatch.id && selectedProduct.id) {
			const { data } = await loadProductSpecificationsQuery.fetch({
				variables: {
					filter: {
						plantId,
						productIds: [selectedProduct.id],
						productBatchIds: [selectedBatch.id],
					},
				},
			})
			specifications =
				data?.analysisOptionChoices.data.map((spec): MetaSpecification => {
					return {
						...spec,
						productUuid,
						productBatchUuid,
						uuid: uuid(),
					}
				}) ?? []

			specifications.push(...specificationCrudStore.createdValues.filter(spec => spec.productBatchUuid === productBatchUuid))
			specifications = bulkUpdate(
				specifications,
				specificationCrudStore.updatedValues.filter(spec => spec.productBatchUuid === productBatchUuid),
				'uuid',
			)
			specificationCache.set(productUuid, productBatchUuid, specifications)
		}
	}

	async function newBatch() {
		if (!selectedProduct) {
			return
		}
		const newBatch: Batch = {
			id: null,
			uuid: uuid(),
			name: '',
			description: '',
			location: null,
			start: new Date().toISOString(),
			end: null,
			status: 'ACTIVE',
			productId: selectedProduct.id,
			productUuid: selectedProduct.uuid,
			plantId,
		}
		batches.push(newBatch)
		batches = batches
		batchCrudStore.create(newBatch)
		// set batch here so the user doesn't have to wait for data to be loaded before
		selectedBatch = newBatch
		await tick()
		document.querySelector<HTMLInputElement>('tr:last-child input')?.focus()
		selectBatch(newBatch)
	}

	async function pasteBatch() {
		if (!clipboard || !selectedProduct) {
			return
		}
		const newBatch = {
			...klona(clipboard),
			uuid: uuid(),
			id: null,
			name: getDuplicateName(clipboard, batches, 'name'),
			productId: selectedProduct.id,
			productUuid: selectedProduct.uuid,
		}
		batches.push(newBatch)
		batches = batches
		batchCrudStore.create(newBatch)
		if ($copyWithSpecifications && specificationsClipboard) {
			const productUuid = selectedProduct.uuid
			const productId = selectedProduct.id
			const productBatchUuid = newBatch.uuid
			const newSpecs: Array<MetaSpecification> = specificationsClipboard.map(spec => {
				return {
					...klona(spec),
					uuid: uuid(),
					productId,
					productUuid,
					productBatchId: null,
					productBatchUuid,
				}
			})
			specificationCache.set(productUuid, productBatchUuid, newSpecs)
			specificationCrudStore.create(newSpecs)
		}
		await selectBatch(newBatch)
	}

	function deleteBatch() {
		if (!selectedProduct || !selectedBatch || selectedBatch.id) {
			return
		}
		const selectedBatchUuid = selectedBatch.uuid
		batches = batches.filter(batch => batch.uuid !== selectedBatchUuid)
		batchCrudStore.delete(selectedBatch)
		specificationCrudStore.delete(specifications)
		specificationCache.delete(selectedProduct.uuid, selectedBatchUuid)
		selectBatch(batches[0] ?? null)
	}

	/** Updates existing elements in an array, without inserting new values (which there shouldn't be any of anyways)*/
	function bulkUpdate<T>(arr: Array<T>, values: Array<T>, idKey: keyof T) {
		values.forEach(value => {
			const index = arr.findIndex(item => item[idKey] === value[idKey])
			if (index !== -1) {
				arr[index] = value
			}
		})
		return arr
	}

	function updateBatchKeypath<T extends keyof Batch>(index: number, key: T, value: Batch[T]) {
		batches[index][key] = value
		batchCrudStore.update(batches[index])
	}

	// #region GraphQL
	const locationsQuery = graphql(`
		query ProductBatchesLocations($filter: LocationFilter) {
			locations(filter: $filter) {
				data {
					id
					location
				}
			}
		}
	`)

	const batchesQuery = graphql(`
		query ProductBatchesBatches($filter: ProductBatchFilter) {
			productBatches(filter: $filter) {
				data {
					id
					name
					description
					location {
						id
						location
					}
					start
					end
					status
					productId
					plantId
				}
			}
		}
	`)
	// #endregion

	onMount(async () => {
		const { data } = await locationsQuery.fetch({
			variables: {
				filter: {
					activeOnly: true,
					plantIds: [plantId],
					testableOnly: true,
				},
			},
		})
		locations = data?.locations.data ?? []
	})
</script>

<Modal
	bind:show
	modalSize="xxl"
	title={translate('product.batchModal.title', 'Product Batches')}
	confirmButtonText={translate('common:confirm', 'Confirm')}
	cancelButtonText={translate('common:close', 'Close')}
	backdropClickCancels={false}
	on:close={close}
	on:confirm={confirm}
>
	<div class="card mb-3">
		<div class="card-header"><h5 class="mb-0">{translate('product.batchModal.batchHeader', 'Batches')}</h5></div>
		<div class="card-body">
			<Table
				responsive
				columns={batchColumns}
				rows={batches}
			>
				{#snippet children({ row })}
					<tr
						class="text-nowrap"
						class:table-primary={selectedBatch?.uuid === row.uuid}
						on:click={() => selectBatch(row)}
					>
						<Td
							enterGoesDown
							stopPropagation
							property="name"
						>
							<Input
								required
								showLabel={false}
								value={row.name}
								validation={{
									validator: value => (value ? true : ''),
								}}
								on:change={e => updateBatchKeypath(row.originalIndex, 'name', getEventValue(e))}
							></Input>
						</Td>
						<Td
							enterGoesDown
							stopPropagation
							property="description"
						>
							<TextArea
								showLabel={false}
								value={row.description}
								style="min-height: 31px; height: 31px;"
								on:change={e => updateBatchKeypath(row.originalIndex, 'description', getEventValue(e))}
							></TextArea>
						</Td>
						<Td
							enterGoesDown
							stopPropagation
							property="location[location]"
						>
							<Autocomplete
								label="Location"
								showLabel={false}
								emptyValue={null}
								options={locations}
								value={row.location ?? null}
								getLabel={location => location?.location ?? ''}
								on:change={e => updateBatchKeypath(row.originalIndex, 'location', e.detail)}
							></Autocomplete>
						</Td>
						<Td
							enterGoesDown
							stopPropagation
							property="start"
						>
							<Input
								required
								showLabel={false}
								type="datetime-local"
								validation={{
									validator: value => (value ? true : ''),
								}}
								value={formatDateTimeForInput(row.start, $session.plant.timezone)}
								on:change={e => updateBatchKeypath(row.originalIndex, 'start', getEventUtcDate(e, $session.plant.timezone))}
							></Input>
						</Td>
						<Td
							enterGoesDown
							stopPropagation
							property="end"
						>
							<Input
								showLabel={false}
								type="datetime-local"
								value={formatDateTimeForInput(row.end, $session.plant.timezone)}
								on:change={e => updateBatchKeypath(row.originalIndex, 'end', getEventUtcDate(e, $session.plant.timezone))}
							></Input>
						</Td>
						<Td
							enterGoesDown
							stopPropagation
							property="status"
						>
							<Select
								label="Status"
								showLabel={false}
								showEmptyOption={false}
								value={row.status}
								on:change={e => updateBatchKeypath(row.originalIndex, 'status', getEventValueEnum(e, 'ACTIVE', 'EXPIRED', 'INACTIVE'))}
							>
								<option value="ACTIVE">{translate('common:active', 'Active')}</option>
								<option value="EXPIRED">{translate('common:expired', 'Expired')}</option>
								<option value="INACTIVE">{translate('common:inactive', 'Inactive')}</option>
							</Select></Td
						>
					</tr>
				{/snippet}

				{#snippet noRows()}
					<tr>
						<td
							colspan={batchColumns.length}
							class="text-center">{translate('product.batchModal.noBatchRows', 'No batches, click "New Batch" to create one.')}</td
						>
					</tr>
				{/snippet}
			</Table>
		</div>
		<div class="card-footer">
			<Button
				outline
				size="sm"
				color="success"
				iconClass="plus"
				on:click={newBatch}
				>{translate('product.batchModal.newBatch', 'New Batch')}
			</Button>
			<Dropdown
				split
				outline
				size="sm"
				iconClass="copy"
				disabled={!selectedBatch}
				menuItemClickCloses={false}
				on:click={() => {
					clipboard = klona(selectedBatch)
					specificationsClipboard = $copyWithSpecifications ? klona(specifications) : null
				}}
			>
				{translate('product.copyButton', 'Copy')}
				<svelte:fragment slot="dropdownItems">
					<DropdownCheckboxItem bind:checked={$copyWithSpecifications}>{translate('product.batchModal.includeSpecificationsCheckbox', 'Include Specifications')}</DropdownCheckboxItem>
				</svelte:fragment>
			</Dropdown>
			<Button
				outline
				size="sm"
				iconClass="paste"
				disabled={!clipboard}
				on:click={pasteBatch}>{translate('product.pasteButton', 'Paste')}</Button
			>
			<!-- Desktop only supports deleting unsaved batches, and I don't want to diverge from that yet. -->
			<Button
				outline
				size="sm"
				color="danger"
				iconClass="trash"
				disabled={!selectedBatch || !!selectedBatch.id}
				on:click={deleteBatch}
			>
				{translate('common:delete', 'Delete')}
			</Button>
		</div>
	</div>

	{#if selectedProduct && selectedBatch}
		<ConfigureSpecificationCard
			{plants}
			{plantId}
			{analyses}
			{analysesById}
			{canEditChoice}
			{selectedProduct}
			{severityClasses}
			{specificationCrudStore}
			{canEditProduct}
			{selectedBatch}
			bind:selectedSpecification
			bind:specificationCache
			bind:specifications
		></ConfigureSpecificationCard>
	{/if}
</Modal>
