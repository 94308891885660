export default {
    "name": "WorkOrderTypes",
    "kind": "HoudiniQuery",
    "hash": "5edc5a527cd6b0222eb02a5a6d1430a9e20a1f6273774b9e0a1106a0feede373",

    "raw": `query WorkOrderTypes {
  workOrderTypes {
    data {
      id
      name
    }
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "workOrderTypes": {
                "type": "WorkOrderTypeResponse",
                "keyRaw": "workOrderTypes",

                "selection": {
                    "fields": {
                        "data": {
                            "type": "WorkOrderType",
                            "keyRaw": "data",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=81790b14ade7dcbabd3ed0825f448e2fd36a65e108820d3754bf1a8dc3b5176e";