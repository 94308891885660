export default {
    "name": "WorkOrderSettings",
    "kind": "HoudiniQuery",
    "hash": "524d8676cb0244f3a5832ca1d6fdd50ce8d34c039a18183efc5473f771465b9b",

    "raw": `query WorkOrderSettings {
  session {
    user {
      showOptions: getUserSetting(
        lookup: {category: "Sampling", settingType: INTERFACE_HISTORY, name: "Show analysis options and values in sample detail", defaultValue: "True"}
      ) {
        value
      }
      showCollectionDetail: getUserSetting(
        lookup: {category: "Sampling", settingType: INTERFACE_HISTORY, name: "Show collection information in sample detail", defaultValue: "True"}
      ) {
        value
      }
      showTestingDetail: getUserSetting(
        lookup: {category: "Sampling", settingType: INTERFACE_HISTORY, name: "Show testing information in sample detail", defaultValue: "True"}
      ) {
        value
      }
      showWorkOrderDetail: getUserSetting(
        lookup: {category: "Sampling", settingType: INTERFACE_HISTORY, name: "Show work order information in sample detail", defaultValue: "False"}
      ) {
        value
      }
      showModifiedIcons: getUserSetting(
        lookup: {category: "Sampling", settingType: INTERFACE_HISTORY, name: "Show icon for any values modified from their initial version", defaultValue: "True"}
      ) {
        value
      }
      changeLogShowDeleted: getUserSetting(
        lookup: {category: "Work Orders", settingType: INTERFACE_HISTORY, name: "Change log: show deleted records", defaultValue: "True"}
      ) {
        value
      }
      changeLogShowCreated: getUserSetting(
        lookup: {category: "Work Orders", settingType: INTERFACE_HISTORY, name: "Change log: show inserted records", defaultValue: "True"}
      ) {
        value
      }
      changeLogShowUpdated: getUserSetting(
        lookup: {category: "Work Orders", settingType: INTERFACE_HISTORY, name: "Change log: show updated records", defaultValue: "True"}
      ) {
        value
      }
      samplesPerPage: getUserSetting(
        lookup: {category: "Work Orders", settingType: INTERFACE_HISTORY, name: "Work order history per page", defaultValue: "100"}
      ) {
        value
      }
      changeLogShowHistoricalValues: getUserSetting(
        lookup: {category: "Work Orders", settingType: INTERFACE_HISTORY, name: "Change log: show historical unmodified fields", defaultValue: "False"}
      ) {
        value
      }
      showOnlyApplicableThresholds: getUserSetting(
        lookup: {category: "Work Orders", name: "Test Thresholds: Show only applicable thresholds", defaultValue: "True", settingType: INTERFACE_HISTORY}
      ) {
        value
      }
      id
    }
  }
  allowShowThresholdsTable: getGlobalSetting(
    lookup: {category: "Scanner", name: "showthresholds", settingType: INTERFACE_PREFERENCE, defaultValue: "False"}
  ) {
    value
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "session": {
                "type": "Session",
                "keyRaw": "session",
                "nullable": true,

                "selection": {
                    "fields": {
                        "user": {
                            "type": "UserAccount",
                            "keyRaw": "user",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "showOptions": {
                                        "type": "SettingResult",
                                        "keyRaw": "showOptions(lookup: {category: \"Sampling\", settingType: INTERFACE_HISTORY, name: \"Show analysis options and values in sample detail\", defaultValue: \"True\"})",

                                        "selection": {
                                            "fields": {
                                                "value": {
                                                    "type": "String",
                                                    "keyRaw": "value",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "showCollectionDetail": {
                                        "type": "SettingResult",
                                        "keyRaw": "showCollectionDetail(lookup: {category: \"Sampling\", settingType: INTERFACE_HISTORY, name: \"Show collection information in sample detail\", defaultValue: \"True\"})",

                                        "selection": {
                                            "fields": {
                                                "value": {
                                                    "type": "String",
                                                    "keyRaw": "value",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "showTestingDetail": {
                                        "type": "SettingResult",
                                        "keyRaw": "showTestingDetail(lookup: {category: \"Sampling\", settingType: INTERFACE_HISTORY, name: \"Show testing information in sample detail\", defaultValue: \"True\"})",

                                        "selection": {
                                            "fields": {
                                                "value": {
                                                    "type": "String",
                                                    "keyRaw": "value",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "showWorkOrderDetail": {
                                        "type": "SettingResult",
                                        "keyRaw": "showWorkOrderDetail(lookup: {category: \"Sampling\", settingType: INTERFACE_HISTORY, name: \"Show work order information in sample detail\", defaultValue: \"False\"})",

                                        "selection": {
                                            "fields": {
                                                "value": {
                                                    "type": "String",
                                                    "keyRaw": "value",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "showModifiedIcons": {
                                        "type": "SettingResult",
                                        "keyRaw": "showModifiedIcons(lookup: {category: \"Sampling\", settingType: INTERFACE_HISTORY, name: \"Show icon for any values modified from their initial version\", defaultValue: \"True\"})",

                                        "selection": {
                                            "fields": {
                                                "value": {
                                                    "type": "String",
                                                    "keyRaw": "value",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "changeLogShowDeleted": {
                                        "type": "SettingResult",
                                        "keyRaw": "changeLogShowDeleted(lookup: {category: \"Work Orders\", settingType: INTERFACE_HISTORY, name: \"Change log: show deleted records\", defaultValue: \"True\"})",

                                        "selection": {
                                            "fields": {
                                                "value": {
                                                    "type": "String",
                                                    "keyRaw": "value",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "changeLogShowCreated": {
                                        "type": "SettingResult",
                                        "keyRaw": "changeLogShowCreated(lookup: {category: \"Work Orders\", settingType: INTERFACE_HISTORY, name: \"Change log: show inserted records\", defaultValue: \"True\"})",

                                        "selection": {
                                            "fields": {
                                                "value": {
                                                    "type": "String",
                                                    "keyRaw": "value",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "changeLogShowUpdated": {
                                        "type": "SettingResult",
                                        "keyRaw": "changeLogShowUpdated(lookup: {category: \"Work Orders\", settingType: INTERFACE_HISTORY, name: \"Change log: show updated records\", defaultValue: \"True\"})",

                                        "selection": {
                                            "fields": {
                                                "value": {
                                                    "type": "String",
                                                    "keyRaw": "value",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "samplesPerPage": {
                                        "type": "SettingResult",
                                        "keyRaw": "samplesPerPage(lookup: {category: \"Work Orders\", settingType: INTERFACE_HISTORY, name: \"Work order history per page\", defaultValue: \"100\"})",

                                        "selection": {
                                            "fields": {
                                                "value": {
                                                    "type": "String",
                                                    "keyRaw": "value",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "changeLogShowHistoricalValues": {
                                        "type": "SettingResult",
                                        "keyRaw": "changeLogShowHistoricalValues(lookup: {category: \"Work Orders\", settingType: INTERFACE_HISTORY, name: \"Change log: show historical unmodified fields\", defaultValue: \"False\"})",

                                        "selection": {
                                            "fields": {
                                                "value": {
                                                    "type": "String",
                                                    "keyRaw": "value",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "showOnlyApplicableThresholds": {
                                        "type": "SettingResult",
                                        "keyRaw": "showOnlyApplicableThresholds(lookup: {category: \"Work Orders\", name: \"Test Thresholds: Show only applicable thresholds\", defaultValue: \"True\", settingType: INTERFACE_HISTORY})",

                                        "selection": {
                                            "fields": {
                                                "value": {
                                                    "type": "String",
                                                    "keyRaw": "value",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "allowShowThresholdsTable": {
                "type": "SettingResult",
                "keyRaw": "allowShowThresholdsTable(lookup: {category: \"Scanner\", name: \"showthresholds\", settingType: INTERFACE_PREFERENCE, defaultValue: \"False\"})",

                "selection": {
                    "fields": {
                        "value": {
                            "type": "String",
                            "keyRaw": "value",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=4a65f12456b6d3b2aa3f012aefdff2ead725db5689378de1ef53576dfaafa923";