import { QueryStore } from '../runtime/stores/query'
import artifact from '$houdini/artifacts/GetUserSetting'
import { initClient } from '$houdini/plugins/houdini-svelte/runtime/client'

export class GetUserSettingStore extends QueryStore {
	constructor() {
		super({
			artifact,
			storeName: "GetUserSettingStore",
			variables: true,
		})
	}
}

export async function load_GetUserSetting(params) {
	await initClient()

	const store = new GetUserSettingStore()

	await store.fetch(params)

	return {
		GetUserSetting: store,
	}
}
