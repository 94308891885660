export default {
    "name": "GetCurrentUserSetting",
    "kind": "HoudiniQuery",
    "hash": "a7015974fc878477bd5de5a12d807da3b76252f23a0ac41e9d147b62cd8226df",

    "raw": `query GetCurrentUserSetting($lookup: SettingLookup!) {
  getCurrentUserSetting(lookup: $lookup) {
    value
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "getCurrentUserSetting": {
                "type": "SettingResult",
                "keyRaw": "getCurrentUserSetting(lookup: $lookup)",

                "selection": {
                    "fields": {
                        "value": {
                            "type": "String",
                            "keyRaw": "value",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "lookup": "SettingLookup"
        },

        "types": {
            "SettingLookup": {
                "category": "String",
                "defaultValue": "String",
                "name": "String",
                "settingType": "SettingType"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=47f3fc4544e5c4fa869c6e846a19e29e1697361e0e0ee6c0db0d5ff05e960f82";