export default {
    "name": "ShProducts",
    "kind": "HoudiniQuery",
    "hash": "8fefcd086ef6670a0811c723c39e87c22adde3dc38281aa7ce8aa3c3800f68f2",

    "raw": `query ShProducts($filter: ProductFilter) {
  products(filter: $filter, pagination: {pageSize: 0}) {
    data {
      id
      name
      parentProductId
      inUseAtPlantIDs
    }
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "products": {
                "type": "ProductResponse",
                "keyRaw": "products(filter: $filter, pagination: {pageSize: 0})",

                "selection": {
                    "fields": {
                        "data": {
                            "type": "Product",
                            "keyRaw": "data",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    },

                                    "parentProductId": {
                                        "type": "PositiveInt",
                                        "keyRaw": "parentProductId",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "inUseAtPlantIDs": {
                                        "type": "PositiveInt",
                                        "keyRaw": "inUseAtPlantIDs",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "filter": "ProductFilter"
        },

        "types": {
            "ProductFilter": {
                "active": "Boolean",
                "activeOnly": "Boolean",
                "plantIds": "PositiveInt",
                "plantIdsFilteringByChildren": "PositiveInt",
                "plants": "PositiveInt",
                "productName": "NonEmptyString",
                "productType": "ProductType"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=4048e846cb8db62cf1b1e6b64bc018cd0388ec11f349d344558dbf33915c7816";