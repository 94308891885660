export default {
    "name": "ShProcessZones",
    "kind": "HoudiniQuery",
    "hash": "07f34de66595d3f113336fcf8bd75c2741ad72a1b42158330c446e45df9ab683",

    "raw": `query ShProcessZones($filter: ProcessZoneFilter) {
  processZones(filter: $filter, pagination: {pageSize: 0}) {
    data {
      id
      plantId
      name
    }
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "processZones": {
                "type": "ProcessZoneResponse",
                "keyRaw": "processZones(filter: $filter, pagination: {pageSize: 0})",

                "selection": {
                    "fields": {
                        "data": {
                            "type": "ProcessZone",
                            "keyRaw": "data",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "plantId": {
                                        "type": "PositiveInt",
                                        "keyRaw": "plantId",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "filter": "ProcessZoneFilter"
        },

        "types": {
            "ProcessZoneFilter": {
                "name": "NonEmptyString",
                "plantIds": "PositiveInt",
                "plants": "PositiveInt"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=4a0a58bfee451539a37c7ec639ca0e8361a31c60f76ec6646f164e64de48fe19";