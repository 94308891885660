export default {
    "name": "UpdateEntityTags",
    "kind": "HoudiniMutation",
    "hash": "2eaf16c9645240562fe46a0965943ec47a8a42276e7f69b20c3a4b5ad18b6c59",

    "raw": `mutation UpdateEntityTags($input: [EntityTagUpdate!]!) {
  updateEntityTags(input: $input) {
    id
  }
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "updateEntityTags": {
                "type": "EntityTag",
                "keyRaw": "updateEntityTags(input: $input)",
                "nullable": true,

                "selection": {
                    "fields": {
                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "input": "EntityTagUpdate"
        },

        "types": {
            "EntityTagUpdate": {
                "active": "Boolean",
                "entityType": "EntityTagType",
                "id": "PositiveInt",
                "name": "String"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=e0a85a6b6d36b6384ae20bce6fc59e887ccbb7d9f75f6b4bf7d3cf61b551ed08";