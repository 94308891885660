import { localWritable } from '@macfja/svelte-persistent-store'
import { get } from 'svelte/store'
import type { Login$result } from '$houdini'
import type { Merge } from 'type-fest'

const session = localWritable<ClientSession | Record<string, never>>('session', {})

export default session

/** Runs `get` from `svelte/store` on the session store. You should only use this in the case where subscribing doesn't make sense. */
export function getSession() {
	return get(session) ?? {}
}

// We mutate the session after receiving it from the API, so this is slightly different than what they return
export type ClientSession = Merge<
	Omit<Login$result['createSession'], 'sessionToken'>,
	{
		authToken: NonNullable<Login$result['createSession']['sessionToken']>
		userAccountId: NonNullable<Login$result['createSession']['userAccountId']>
		plant: NonNullable<Login$result['createSession']['plant']>
		user: Merge<
			NonNullable<Login$result['createSession']['user']>,
			{
				permissions: {
					[permissionName: string]: NonNullable<Login$result['createSession']['user']>['permissions'][number]['value']
				}
				userGroupIds: NonNullable<Login$result['createSession']['user']>['userGroups'][number]['id'][]
			}
		>
		siteId: NonNullable<Login$result['createSession']['plant']>['id']
		authorizedPlantIDs: NonNullable<Login$result['createSession']['user']>['authorizedPlants'][number]['id'][]
	}
>
