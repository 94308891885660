export default {
    "name": "ShAnalyses",
    "kind": "HoudiniQuery",
    "hash": "e92e3e2e7b9abde31da98ca5a3a8a34b7fb0f3a115dc24871951c87913d36948",

    "raw": `query ShAnalyses($filter: AnalysisFilter) {
  analyses(filter: $filter) {
    data {
      id
      active
      analysisType
      name
      category
      options {
        id
        active
        option
        valueType
        unit
        defaultValue
        choices {
          id
          choice
        }
      }
    }
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "analyses": {
                "type": "AnalysisResponse",
                "keyRaw": "analyses(filter: $filter)",

                "selection": {
                    "fields": {
                        "data": {
                            "type": "Analysis",
                            "keyRaw": "data",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "active": {
                                        "type": "Boolean",
                                        "keyRaw": "active",
                                        "visible": true
                                    },

                                    "analysisType": {
                                        "type": "AnalysisType",
                                        "keyRaw": "analysisType",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    },

                                    "category": {
                                        "type": "String",
                                        "keyRaw": "category",
                                        "visible": true
                                    },

                                    "options": {
                                        "type": "AnalysisOption",
                                        "keyRaw": "options",

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "active": {
                                                    "type": "Boolean",
                                                    "keyRaw": "active",
                                                    "visible": true
                                                },

                                                "option": {
                                                    "type": "String",
                                                    "keyRaw": "option",
                                                    "visible": true
                                                },

                                                "valueType": {
                                                    "type": "ValueType",
                                                    "keyRaw": "valueType",
                                                    "visible": true
                                                },

                                                "unit": {
                                                    "type": "String",
                                                    "keyRaw": "unit",
                                                    "visible": true
                                                },

                                                "defaultValue": {
                                                    "type": "String",
                                                    "keyRaw": "defaultValue",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "choices": {
                                                    "type": "AnalysisOptionChoice",
                                                    "keyRaw": "choices",

                                                    "selection": {
                                                        "fields": {
                                                            "id": {
                                                                "type": "PositiveInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "choice": {
                                                                "type": "String",
                                                                "keyRaw": "choice",
                                                                "visible": true
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "filter": "AnalysisFilter"
        },

        "types": {
            "AnalysisFilter": {
                "activeOnly": "Boolean",
                "category": "NonEmptyString",
                "ids": "PositiveInt",
                "name": "NonEmptyString",
                "plantIds": "PositiveInt",
                "plants": "PositiveInt",
                "type": "AnalysisType"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=fa722158cf0034a3146c946c1736e18b6ee86483032b405e17ca91b5f3eb5dd5";