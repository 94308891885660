<script
	lang="ts"
	module
>
	export type MetaSpecification = {
		id: number | null
		analysisOption: {
			analysis: {
				id: number
				name: string
			}
			id: number
			option: string
			valueType: ValueType$options
		} | null
		boundaryType: BoundaryType$options
		choice: string
		constraint: ConstraintType$options
		plantId: number | null
		requiredAnalysisOption: {
			id: number
			option: string
			valueType: ValueType$options
		} | null
		requiredChoice: string | null
		requiredConstraint: ConstraintType$options | null
		severityClass: {
			id: number
			name: string
		} | null
		productBatchId: number | null
		productBatchUuid: string | null
		productId?: number | null
		productUuid?: string | null
		uuid: string
		delete?: unknown
	}

	//
</script>

<script lang="ts">
	import type { BoundaryType$options, ConstraintType$options, ValueType$options } from '$houdini/graphql'
	import type { i18n, Mediator } from 'types/common'
	import type { Plant, ProductNode, SeverityClass, Analysis, SpecificationCache, Batch } from 'utility/product-helper'
	import type { CrudStore } from '@isoftdata/svelte-store-crud'

	import Table, { type Column, Td } from '@isoftdata/svelte-table'
	import Icon from '@isoftdata/svelte-icon'
	import Button from '@isoftdata/svelte-button'
	import Select from '@isoftdata/svelte-select'
	import MightyMorphingInput from './MightyMorphingInput.svelte'

	import { buildTranslatedConstants } from 'utility/constants'
	import { getEventValue, getEventValueEnum } from '@isoftdata/browser-event'
	import { getContext } from 'svelte'
	import { makeGroupedEntityLoader } from 'utility/entity-proxy-map'
	import { graphql } from '$houdini'
	import { v4 as uuid } from '@lukeed/uuid'
	import { getConstraintsForValueType } from 'utility/analysis-management-helper'

	const mediator = getContext<Mediator>('mediator')
	const { t: translate } = getContext<i18n>('i18next')
	const { constraintsMap, thresholdsWhenMap } = buildTranslatedConstants(translate)

	interface Props {
		plantId: number
		specifications?: Array<MetaSpecification>
		selectedSpecification?: MetaSpecification | null
		specificationCrudStore: CrudStore<MetaSpecification, 'uuid'>
		plants: Array<Plant>
		analyses: Array<Analysis>
		analysesById: Record<number, Analysis>
		severityClasses: Array<SeverityClass>
		canEditChoice: (plantId: number | null) => boolean
		// The batch these specifications belong to, or NULL for specifications without a batch
		selectedBatch?: Batch | null
		specificationCache: SpecificationCache
		canEditProduct: boolean
		selectedProduct: ProductNode
	}

	let {
		plantId,
		specifications = $bindable([]),
		selectedSpecification = $bindable(null),
		specificationCrudStore,
		plants,
		analyses,
		analysesById,
		severityClasses,
		canEditChoice,
		selectedBatch = null,
		specificationCache = $bindable(),
		canEditProduct,
		selectedProduct,
	}: Props = $props()

	let loading = false
	let canEditSpecifications = $derived(canEditProduct && canEditChoice(plantId))
	let analysesAtCurrentPlant = $derived(analyses.filter(analysis => analysis.inUseAtPlantIDs.includes(plantId)))
	let productBatchId = $derived(selectedBatch?.id ?? null)
	let productBatchUuid = $derived(selectedBatch?.uuid ?? null)

	const authorizedPlantIds = new Set(plants.map(plant => plant.id))
	const hasPermissionForAllPlants = $derived(selectedProduct.inUseAtPlantIDs.every(plantId => authorizedPlantIds.has(plantId)))

	/** Proxy map for the choices shown in the choice dropdown, not for the specifications. */
	const choiceLoader = makeGroupedEntityLoader<string>([], -1, async optionId => {
		// If we have a batch but no id, the batch is new, so it has no saved choices
		if (selectedBatch && !productBatchId) {
			return []
		}

		const { data } = await choicesQuery.fetch({
			variables: {
				filter: {
					optionId,
					plantId,
					productBatchIds: productBatchId ? [productBatchId] : undefined,
					hasProductBatch: !!productBatchId,
				},
			},
		})
		return data?.analysisOptionChoices.data.map(choice => choice.choice) ?? []
	})

	const specificationColumns: Column<MetaSpecification>[] = [
		{
			name: '',
			property: 'delete',
			icon: 'save',
			width: '1rem',
			title: translate('specificationCard.dirtyColumnTitle', 'Rows with a save icon have unsaved changes, and will be saved when you hit the "Save" button.'),
		},
		{
			name: translate('specificationCard.analysisName', 'Analysis'),
			property: 'analysisOption[analysis][name]',
			minWidth: '200px',
			title: translate('specificationCard.analysisNameColumnTitle', 'What analysis this specification applies to'),
		},
		{
			name: translate('specificationCard.option', 'Option'),
			property: 'analysisOption[option]',
			minWidth: '200px',
			title: translate('specificationCard.optionColumnTitle', 'What analysis option this specification applies to'),
		},
		{
			name: translate('specificationCard.plant', 'Plant'),
			property: 'plantId',
			minWidth: '150px',
			title: translate('specificationCard.plantColumnTitle', 'Controls whether this choice/threshold applies to just the one plant or all plants'),
		},
		{
			name: translate('specificationCard.severity', 'Severity'),
			property: 'severityClass[name]',
			minWidth: '150px',
			title: translate('specificationCard.severityColumnTitle', "(Optional) Which severity class this threshold/choice applies to (Or choose 'All Severities' for everything)"),
		},
		{
			name: translate('specificationCard.threshold', 'Threshold'),
			property: 'boundaryType',
			minWidth: '170px',
			title: translate(
				'specificationCard.thresholdColumnTitle',
				`What type of choice is this?\r\n\t'Marginal' values are the threshold for triggering a warning;\r\n\t'Unacceptable' values are the threshold for triggering an error;\r\n\t'Acceptable' values are used to specify predefined options for choice-based fields, informational thresholds on the graphs, or minimum/maximum values on numeric fields`,
			),
		},
		{
			name: translate('specificationCard.constraint', 'Constraint'),
			property: 'constraint',
			minWidth: '150px',
			title: translate('specificationCard.constraintColumnTitle', 'Is this the maximum or minimum boundary/marginal/or unacceptable value?'),
		},
		{
			name: translate('specificationCard.choiceBoundaryValue', 'Choice/Boundary Value'),
			property: 'choice',
			minWidth: '150px',
			title: translate(
				'specificationCard.choiceBoundaryValueColumnTitle',
				'For numeric fields, enter a value that represents a boundary for a warning level/etc.  For text/choice based options, enter possible choices.',
			),
		},
		{
			name: translate('specificationCard.requiredOption', 'Required Option'),
			property: 'requiredAnalysisOption[option]',
			minWidth: '200px',
			title: translate('specificationCard.requiredOptionColumnTitle', '(Optional) Allows specifying another field that controls whether this choice shows up'),
		},
		{
			name: translate('specificationCard.requiredConstraint', 'Required Constraint'),
			property: 'requiredConstraint',
			minWidth: '150px',
			title: translate(
				'specificationCard.requiredConstraintColumnTitle',
				"(Optional) Allows specifying a restriction on the value of the 'Required Option' that must be met for this choice to be shown. (Required Option must be chosen first)",
			),
		},
		{
			name: translate('specificationCard.requiredChoice', 'Required Choice'),
			property: 'requiredChoice',
			minWidth: '150px',
			title: translate(
				'specificationCard.requiredChoiceColumnTitle',
				"(Optional) Allows specifying a value that the 'Required Option' must meet for this choice to be shown. (Requried Option must be chosen first)",
			),
		},
	]

	function getApplicableSeverityClasses(spec: MetaSpecification): Array<SeverityClass> {
		return severityClasses.filter(severityClass => severityClass.plantId === spec.plantId)
	}

	function updateSpecificationAnalysis(index: number, newAnalysisId: number | null) {
		let newAnalysis = newAnalysisId ? analysesById[newAnalysisId] : (Object.values(analysesById)[0] ?? Object.values(analysesById)[0])

		updateSpecificationKeypath(index, 'analysisOption', {
			...newAnalysis.options[0],
			analysis: {
				id: newAnalysis.id,
				name: newAnalysis.name,
			},
		})
	}

	function updateSpecificationKeypath<K extends keyof MetaSpecification>(index: number, key: K, value: MetaSpecification[K]) {
		specifications[index][key] = value
		specifications = specifications
		specificationCrudStore.update(specifications[index])
		specificationCache.set(selectedProduct.uuid, productBatchUuid, specifications)
	}

	function updateSpecificationAnalysisOption(index: number, newAnalysisOptionId: number | null) {
		const specification = specifications[index]
		if (!specification.analysisOption) {
			console.error('updateSpecificationAnalysisOption, Analysis option is required.')
			mediator.call('showMessage', {
				heading: translate('specificationCard.analysisOptionRequired', 'Analysis option is required.'),
				message: translate('specificationCard.analysisOptionRequiredErrorMessage', 'Analysis option is required for the selected analysis.'),
				type: 'danger',
				time: false,
			})
			throw new Error('Analysis option is required.')
		}
		const relatedAnalysis = specification.analysisOption?.analysis.id ? analysesById[specification.analysisOption?.analysis.id] : null
		if (!relatedAnalysis) {
			console.error('updateSpecificationAnalysisOption, Analysis not found.')
			mediator.call('showMessage', {
				heading: translate('specificationCard.analysisNotFound', 'Analysis not found.'),
				message: translate('specificationCard.analysisNotFoundErrorMessage', 'Analysis not found for the selected analysis.'),
				type: 'danger',
				time: false,
			})
			throw new Error('Analysis not found.')
		}
		const newAnalysisOption = relatedAnalysis.options.find(option => option.id === newAnalysisOptionId)
		if (!newAnalysisOption) {
			console.error('updateSpecificationAnalysisOption, Analysis option not found.')
			mediator.call('showMessage', {
				heading: translate('specificationCard.analysisOptionNotFound', 'Analysis option not found.'),
				message: translate('specificationCard.analysisOptionNotFoundErrorMessage', 'Analysis option not found for the selected analysis.'),
				type: 'danger',
				time: false,
			})
			throw new Error('Analysis option not found.')
		}
		updateSpecificationKeypath(index, 'analysisOption', {
			...newAnalysisOption,
			analysis: {
				id: relatedAnalysis.id,
				name: relatedAnalysis.name,
			},
		})
		// Not all options have all constraints, so make sure we're using a valid one after changing the option.
		const constraints = getConstraintsForValueType(constraintsMap, newAnalysisOption.valueType)
		if (!constraints.some(([key]) => key === specifications[index].constraint)) {
			updateSpecificationKeypath(index, 'constraint', constraints[0][0])
		}
	}

	function getApplicablePlants(): Array<Plant> {
		return plants.filter(plant => selectedProduct.inUseAtPlantIDs.includes(plant.id))
	}

	function getApplicableAnalyses(analysisId: number | null): Array<Analysis> {
		const dropdownHasAllNeededAnalyses = analysesAtCurrentPlant.find(analysis => analysis.id === analysisId)
		if (dropdownHasAllNeededAnalyses) {
			return analysesAtCurrentPlant
		}
		// If the dropdown doesn't have all the analyses we need, we need to add the one we're using
		const foundAnalysis = analysisId ? analysesById[analysisId] : null
		if (foundAnalysis) {
			return analysesAtCurrentPlant.concat(foundAnalysis)
		} else {
			// Fallback to all analyses if we can't find the one we're using
			return analysesAtCurrentPlant
		}
	}

	function addSpecification() {
		if (!canEditChoice(plantId)) {
			mediator.call('showMessage', {
				type: 'danger',
				heading: translate('product.errorAddingSpecificationHeading', 'Unauthorized'),
				message: translate('product.errorAddingSpecificationMessage', 'You do not have permission to add a specification to this product.'),
				time: false,
			})
			console.error(translate('product.errorAddingSpecificationMessage', 'You do not have permission to add a specification to this product.'))
			return
		}
		if (!selectedProduct) {
			return
		}

		const newSpecification: MetaSpecification = {
			id: null,
			uuid: uuid(),
			productId: selectedProduct?.id,
			productUuid: selectedProduct.uuid,
			plantId,
			analysisOption: null,
			productBatchId: selectedBatch?.id ?? null,
			productBatchUuid: selectedBatch?.uuid ?? null,
			requiredAnalysisOption: null,
			requiredChoice: null,
			requiredConstraint: 'NONE',
			severityClass: null,
			boundaryType: 'UNACCEPTABLE',
			choice: '',
			constraint: 'MINIMUM',
		}

		specifications.push(newSpecification)
		specifications = specifications
		specificationCache.set(selectedProduct.uuid, productBatchUuid, specifications)
		specificationCrudStore.create(newSpecification)
	}

	function restoreSpecification(specification: MetaSpecification) {
		if (specification.id) {
			specificationCrudStore.unDelete(specification)
		}
	}

	function deleteSpecification(specification: MetaSpecification) {
		if (specification.id && confirm(translate('product.confirmDeleteSavedSpecification', 'Are you sure you want to delete this saved specification? This cannot be undone after save.'))) {
			// We don't filter out the deleted specification from the cache because we want to keep it in case of an undo
			specificationCrudStore.delete(specification)
		} else if (!specification.id && confirm(translate('product.confirmDeleteUnsavedSpecification', 'Are you sure you want to delete this unsaved specification? This cannot be undone.'))) {
			specifications = specifications.filter(spec => spec.uuid !== specification.uuid)
			specificationCache.set(selectedProduct.uuid, productBatchUuid, specifications)
			selectedSpecification = null
		}
	}

	const choicesQuery = graphql(`
		query AnalysisOptionChoicesForSpecifications($filter: AnalysisOptionChoiceFilter) {
			analysisOptionChoices(filter: $filter) {
				data {
					id
					choice
				}
			}
		}
	`)
</script>

<div
	id="specifications-card"
	class="card"
>
	<div class="card-header d-flex h5 justify-content-between border-radius-2rem">
		{translate('specificationCard.specificationCardHeader', 'Specifications')}
	</div>
	<div class="card-body">
		<Table
			stickyHeader
			responsive
			columnHidingEnabled
			filterEnabled={false}
			rows={specifications}
			columns={specificationColumns}
			rowSelectionIdProp="uuid"
			parentClass="mh-40vh"
			size="sm"
			selectedRowIds={selectedSpecification?.uuid ? [selectedSpecification.uuid] : []}
		>
			{#snippet body({ rows })}
				{#if rows.length === 0}
					{#if loading}
						<tr>
							<td
								class="text-center"
								colspan={specificationColumns.length}
							>
								<i class="fas fa-spinner fa-spin mr-1"></i>{translate('specificationCard.loadingSpecifications', 'Loading Specifications')}...
							</td>
						</tr>
					{:else}
						<tr>
							<td
								class="text-center"
								colspan={specificationColumns.length}
							>
								{translate('specificationCard.noSpecifications', 'No Specifications! Click "New Specification" to add one.')}
							</td>
						</tr>
					{/if}
				{:else}
					{#each rows as row (row.uuid)}
						{@const isDirty = $specificationCrudStore && (specificationCrudStore.isUpdated(row) || specificationCrudStore.isCreated(row))}
						{@const deleted = $specificationCrudStore && specificationCrudStore.isDeleted(row)}
						{@const originalIndex = row.originalIndex}
						{@const disabled = deleted || !canEditChoice(row.plantId)}
						<tr
							class="cursor-pointer"
							class:table-primary={selectedSpecification?.uuid === row.uuid}
							class:table-danger={deleted}
							onclick={() => (selectedSpecification = row === selectedSpecification ? null : row)}
						>
							<Td property="delete">
								{#if isDirty}
									<Icon
										fixedWidth
										icon="save"
									/>
								{:else if deleted}
									<Icon
										fixedWidth
										icon="trash"
									/>
								{/if}
							</Td>
							<Td
								stopPropagation
								enterGoesDown
								property="analysisOption[analysis][name]"
							>
								<Select
									required
									id="analysis-{row.uuid}"
									showLabel={false}
									value={row.analysisOption?.analysis.id ?? null}
									showEmptyOption={true}
									emptyText="-- {translate('specificationCard.emptyAnalysis', 'Select Analysis')} --"
									{disabled}
									validation={{
										value: row.analysisOption?.analysis.id,
										validator: value => (value ? true : ''),
									}}
									on:change={e => updateSpecificationAnalysis(originalIndex, parseInt(getEventValue(e), 10) || null)}
								>
									{#each getApplicableAnalyses(row.analysisOption?.analysis.id ?? null) as analysis}
										<option value={analysis.id}>{analysis.name}</option>
									{/each}
								</Select>
							</Td>
							<Td
								stopPropagation
								enterGoesDown
								property="analysisOption[option]"
							>
								<Select
									required
									id="option-{row.uuid}"
									showLabel={false}
									value={row.analysisOption?.id ?? null}
									showEmptyOption={!row.analysisOption}
									emptyText="-- {translate('specificationCard.emptyOption', 'Select Option')} --"
									{disabled}
									validation={{
										value: row.analysisOption?.id,
										validator: value => (value ? true : ''),
									}}
									on:change={e => updateSpecificationAnalysisOption(originalIndex, parseInt(getEventValue(e), 10) || null)}
								>
									{#if row.analysisOption?.analysis.id && analysesById[row.analysisOption?.analysis.id]}
										{#each analysesById[row.analysisOption?.analysis.id].options as analysisOption}
											<option value={analysisOption.id}>{analysisOption.option}</option>
										{/each}
									{/if}
								</Select>
							</Td>
							<Td
								stopPropagation
								enterGoesDown
								property="plantId"
							>
								<Select
									id="plant-{row.uuid}"
									showLabel={false}
									showEmptyOption={hasPermissionForAllPlants}
									emptyText={translate('common:allPlants', 'All Plants')}
									{disabled}
									value={row.plantId}
									on:change={e => updateSpecificationKeypath(originalIndex, 'plantId', parseInt(getEventValue(e), 10) || null)}
								>
									{#each getApplicablePlants() as plant}
										<option value={plant.id}>{plant.code} - {plant.name}</option>
									{/each}
								</Select>
							</Td>
							<Td
								stopPropagation
								enterGoesDown
								property="severityClass[name]"
							>
								<Select
									id="severity-class-{row.uuid}"
									showLabel={false}
									emptyText={translate('specificationCard.allSeverities', 'All Severities')}
									{disabled}
									value={row.severityClass?.id ?? null}
									on:change={e => {
										const newSeverityId = parseInt(getEventValue(e), 10)
										const newSeverity = newSeverityId ? (severityClasses.find(severityClass => severityClass.id === newSeverityId) ?? null) : null
										updateSpecificationKeypath(originalIndex, 'severityClass', newSeverity)
									}}
								>
									{#each getApplicableSeverityClasses(row) as severityClass}
										<option value={severityClass.id}>{severityClass.name}</option>
									{/each}
								</Select>
							</Td>
							<Td
								stopPropagation
								enterGoesDown
								property="boundaryType"
							>
								<Select
									id="boundary-type-{row.uuid}"
									showLabel={false}
									showEmptyOption={false}
									{disabled}
									value={row.boundaryType}
									on:change={e => updateSpecificationKeypath(originalIndex, 'boundaryType', getEventValueEnum(e, 'ALLOWED', 'BOUNDARY', 'MARGINAL', 'UNACCEPTABLE'))}
								>
									{#each Object.entries(thresholdsWhenMap) as [key, value]}
										<option value={key}>{value}</option>
									{/each}
								</Select>
							</Td>
							<Td
								stopPropagation
								enterGoesDown
								property="constraint"
							>
								<Select
									showLabel={false}
									showEmptyOption={false}
									{disabled}
									value={row.constraint}
									on:change={e => updateSpecificationKeypath(originalIndex, 'constraint', getEventValueEnum(e, 'NONE', 'MAXIMUM', 'MINIMUM', 'NOT_EQUAL'))}
								>
									{#each getConstraintsForValueType(constraintsMap, row.analysisOption?.valueType) as [key, value]}
										<option value={key}>{value}</option>
									{/each}
								</Select>
							</Td>
							<Td
								stopPropagation
								enterGoesDown
								property="choice"
							>
								{#if row.analysisOption}
									<!-- This input is used to specify *new* choices, so turn any "CHOICE" type options to "TEXT" -->
									<MightyMorphingInput
										id="specification-choice-{row.uuid}"
										placeholder={translate('specificationCard.choiceBoundryValuePlaceholder', 'Choice/Boundary Value')}
										showLabel={false}
										valueType={row.analysisOption?.valueType === 'CHOICE' ? 'TEXT' : row.analysisOption?.valueType}
										{disabled}
										choices={row.analysisOption?.valueType === 'CHOICE' ? choiceLoader(row.analysisOption.id) : []}
										value={row.choice}
										on:change={e => updateSpecificationKeypath(originalIndex, 'choice', e.detail.value)}
									/>
								{:else}
									<i class="text-muted">{translate('specificationCard.missingOption', 'Select an Option')}</i>
								{/if}
							</Td>
							<Td
								stopPropagation
								enterGoesDown
								property="requiredAnalysisOption[option]"
							>
								{#if row.analysisOption?.valueType === 'CHOICE'}
									<Select
										id="specification-required-analysis-option-{row.uuid}"
										emptyText="-- {translate('specificationCard.emptyRequiredOption', 'Select Required Option')} -- "
										showLabel={false}
										{disabled}
										value={row.requiredAnalysisOption?.id ?? null}
										on:change={e => {
											const newOptionId = parseInt(getEventValue(e), 10) || null
											const newOption =
												newOptionId && row.analysisOption?.analysis.id ? (analysesById[row.analysisOption.analysis.id]?.options.find(option => option.id === newOptionId) ?? null) : null
											updateSpecificationKeypath(originalIndex, 'requiredAnalysisOption', newOption)
										}}
									>
										{#if row.analysisOption.analysis.id && analysesById[row.analysisOption.analysis.id]}
											{#each Object.values(analysesById[row.analysisOption?.analysis.id].options) as analysisOption}
												{#if analysisOption.id !== row.analysisOption.id}
													<option value={analysisOption.id}>{analysisOption.option}</option>
												{/if}
											{/each}
										{/if}
									</Select>
								{:else}
									<i class="text-muted">N/A</i>
								{/if}
							</Td>
							<Td
								stopPropagation
								enterGoesDown
								property="requiredConstraint"
							>
								{#if row.analysisOption?.valueType === 'CHOICE'}
									<Select
										id="specification-required-constraint-{row.uuid}"
										showLabel={false}
										showEmptyOption={false}
										{disabled}
										value={row.requiredConstraint}
										on:change={e => updateSpecificationKeypath(originalIndex, 'requiredConstraint', getEventValueEnum(e, 'NONE', 'MAXIMUM', 'MINIMUM', 'NOT_EQUAL'))}
									>
										{#each Object.entries(constraintsMap) as [key, value]}
											<option value={key}>{value}</option>
										{/each}
									</Select>
								{:else}
									<i class="text-muted">N/A</i>
								{/if}
							</Td>
							<Td
								stopPropagation
								enterGoesDown
								property="requiredChoice"
							>
								{#if row.analysisOption?.valueType === 'CHOICE'}
									<!-- This input is used to pick *existing* choices, so keep CHOICE options as CHOICE -->
									<MightyMorphingInput
										id="specification-required-choice-{row.uuid}"
										placeholder={translate('specificationCard.requiredChoicePlaceholder', 'Required Choice')}
										showLabel={false}
										valueType={row.requiredAnalysisOption?.valueType ?? 'CHOICE'}
										choices={row.requiredAnalysisOption?.valueType === 'CHOICE' ? choiceLoader(row.requiredAnalysisOption.id) : []}
										disabled={disabled || !row.requiredAnalysisOption}
										value={row.requiredChoice ?? ''}
										on:change={event => updateSpecificationKeypath(originalIndex, 'requiredChoice', event.detail.value)}
									/>
								{:else}
									<i class="text-muted">N/A</i>
								{/if}
							</Td>
						</tr>
					{/each}
				{/if}
			{/snippet}
		</Table>
	</div>
	<div class="card-footer d-flex justify-content-start">
		<Button
			outline
			size="sm"
			color="success"
			class="mr-1"
			iconClass="plus"
			title={translate('specificationCard.newSpecificationButtonTitle', 'Create a new specification for use.')}
			disabled={!canEditSpecifications}
			on:click={() => addSpecification()}
		>
			{translate('specificationCard.newSpecificationButton', 'New Specification')}
		</Button>
		{#if selectedSpecification && specificationCrudStore.isDeleted(selectedSpecification)}
			<Button
				outline
				size="sm"
				color="danger"
				iconClass="trash"
				disabled={!selectedSpecification || !canEditSpecifications}
				title={translate('specificationCard.restoreSpecificationButtonTitle', 'Restore the selected specification.')}
				on:click={() => selectedSpecification && restoreSpecification(selectedSpecification)}
			>
				{translate('specificationCard.restoreSpecificationButton', 'Restore Specification')}
			</Button>
		{:else}
			<Button
				outline
				size="sm"
				color="danger"
				iconClass="trash"
				disabled={!selectedSpecification || !canEditSpecifications}
				title={translate('specificationCard.deleteSpecificationButtonTitle', 'Delete the selected specification. This will remove it from all items that use it.')}
				on:click={() => selectedSpecification && deleteSpecification(selectedSpecification)}
			>
				{translate('specificationCard.deleteSpecificationButton', 'Delete')}...
			</Button>
		{/if}
	</div>
</div>
