import type { ClientSession } from 'stores/session'
import sessionStore from 'stores/session'
import { graphql } from '$houdini'

const loginQuery = graphql(`
	mutation Login($userName: String!, $passPhrase: String!, $plantId: PositiveInt!) {
		createSession(userName: $userName, password: $passPhrase, plantId: $plantId) {
			sessionToken
			userName
			userAccountId
			user {
				workEmail
				recoveryEmail
				firstName
				fullName
				lastName
				permissions: userRoles {
					permissionName
					value
				}
				authorizedPlants {
					id
					code
					name
					timezone: computedTimezone
				}
				userGroups {
					id
				}
			}
			plant {
				id
				code
				name
				timezone: computedTimezone
			}
		}
	}
`)

interface MakeLoginRequest {
	username: string
	password: string
	plantId: number
}

export async function makeLoginRequest({ username, password, plantId }: MakeLoginRequest): Promise<string> {
	const res = await loginQuery.mutate({
		userName: username,
		passPhrase: password,
		plantId,
	})

	if (!res?.data?.createSession?.sessionToken || !res.data.createSession.user) {
		throw new Error('Invalid login')
	}

	const { sessionToken, user, userAccountId, ...session } = res.data.createSession

	//I know this seems dumb, but I need to type narrow these and you can't type narrow object props
	if (!user || !userAccountId) {
		throw new Error('Invalid login')
	}
	sessionStore.set({
		authToken: sessionToken,
		...session,
		userAccountId,
		siteId: plantId,
		user: {
			...user,
			workEmail: user.workEmail ?? null,
			recoveryEmail: user.recoveryEmail ?? null,
			firstName: user.firstName ?? null,
			lastName: user.lastName ?? null,
			permissions: user.permissions.reduce((acc, { permissionName, value }) => {
				acc[permissionName] = value.toUpperCase()
				return acc
			}, {}) as { [permissionName: string]: 'PLANT' | 'GLOBAL' | 'NONE' },
			userGroupIds: user.userGroups.map(g => g.id) ?? [],
		},
		authorizedPlantIDs: user.authorizedPlants.map(p => p.id) ?? [],
	})

	return sessionToken
}
