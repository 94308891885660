import pProps from 'p-props'
import { v4 as uuid } from '@lukeed/uuid'
import Timezone from 'timezone-enum'

import { stringToBoolean } from '@isoftdata/utility-string'

import { getSession } from 'stores/session'
import { graphql } from '$houdini'
import { plantsQuery } from 'utility/plants'
import { getUserSettingValue } from 'utility/setting'

import { convertToScheduleTypeEnum } from 'types/automatic-work-order'
import type { SvelteDomApi, AppContext } from 'types/common'

import component from './AutomaticWorkOrder.svelte'
import showErrorAndRedirect from 'utility/show-error-and-redirect'

const gqlPagninationAllPages = { pagination: { pageSize: 0, pageNumber: 1 } }

export default ({ mediator, stateRouter, i18next: { t: translate }, hasPermission }: AppContext) => {
	stateRouter.addState({
		name: 'app.work-order.automatic',
		route: 'automatic',
		template: {
			svelte: true,
			component,
		},
		canLeaveState(domApi) {
			return (
				!(domApi as SvelteDomApi & component).unsavedChanges ||
				confirm(translate('common:canLeaveState', 'You have unsaved changes. Are you sure you want to leave? All unsaved changes will be lost.'))
			)
		},
		async resolve(_data, _parameters) {
			const { siteId } = getSession()

			if (!hasPermission('WORK_ORDERS_CAN_MANAGE_AUTOMATIC', siteId)) {
				throw showErrorAndRedirect(mediator, 'Error Loading Automatic Work Orders', 'You do not have permission to view this page.')
			}

			const [automaticWorkOrders, plants, workOrderTypes, groups, interfaceSettings] = await Promise.all([
				automaticWorkOrdersQuery.fetch({ variables: gqlPagninationAllPages }).then(res => res?.data?.automaticWorkOrders?.data ?? []),
				plantsQuery.fetch({ variables: gqlPagninationAllPages }).then(res => res?.data?.plants?.data ?? []),
				workOrderTypesQuery.fetch().then(res => res.data?.workOrderTypes?.data ?? []),
				groupsQuery.fetch().then(res => res.data?.groups?.data ?? []),
				loadUserSettings(),
			])

			const mappedAutomaticWorkOrders = automaticWorkOrders.map(automaticWorkOrder => {
				return {
					...automaticWorkOrder,
					scheduleType: convertToScheduleTypeEnum(automaticWorkOrder.scheduleType),
					nextOccurance: automaticWorkOrder?.nextOccurance ? new Date(automaticWorkOrder?.nextOccurance) : null,
					uuid: uuid(),
				}
			})

			const timezones = Object.values(Timezone)
				.filter(value => typeof value === 'string')
				.map(value => value as string)
				.concat('SYSTEM')

			return {
				automaticWorkOrders: mappedAutomaticWorkOrders,
				timezones,
				plants,
				workOrderTypes,
				groups,
				selectedPlantId: siteId,
				interfaceSettings,
			}
		},
	})
}

const automaticWorkOrdersQuery = graphql(`
	query AutomaticWorkOrders($filter: AutomaticWorkOrderFilter, $pagination: PaginatedInput) {
		automaticWorkOrders(filter: $filter, pagination: $pagination) {
			data {
				id
				workOrderId
				scheduleType
				monthOffset
				timeOffset
				dayOffset
				timezone
				active
				description
				dateCreated
				dayOfWeek
				rank
				cloneAdditionalData
				cloneMostRecent
				nextOccurance
				workOrder {
					id
					plant {
						id
						name
						code
					}
				}
			}
		}
	}
`)

async function loadUserSettings() {
	const { showOpenDocuments, showClosedDocuments, workOrdersPerPage, dateRangeFilter, showInactive } = await pProps({
		showOpenDocuments: getUserSettingValue({
			category: 'Work Orders',
			name: 'Automatic WOs: show open documents',
			settingType: 'INTERFACE_HISTORY',
			defaultValue: 'True',
		}).then(value => value ?? 'True'),
		showClosedDocuments: getUserSettingValue({
			category: 'Work Orders',
			name: 'Automatic WOs: show closed documents',
			settingType: 'INTERFACE_HISTORY',
			defaultValue: 'True',
		}).then(value => value ?? 'True'),
		workOrdersPerPage: getUserSettingValue({
			category: 'Work Orders',
			name: 'Automatic WOs: Work orders per page',
			settingType: 'INTERFACE_PREFERENCE',
			defaultValue: '100',
		}).then(value => value ?? '100'),
		dateRangeFilter: getUserSettingValue({
			category: 'Work Orders',
			name: 'Automatic WOs: date range filter',
			settingType: 'INTERFACE_HISTORY',
			defaultValue: 'Last 30 Days',
		}).then(value => value ?? 'Last 30 Days'),
		showInactive: getUserSettingValue({
			category: 'Work Orders',
			name: 'Automatic WOs: show inactive',
			settingType: 'INTERFACE_HISTORY',
			defaultValue: 'True',
		}).then(value => value ?? 'True'),
	})

	return {
		showOpenDocuments: stringToBoolean(showOpenDocuments),
		showClosedDocuments: stringToBoolean(showClosedDocuments),
		workOrdersPerPage: parseInt(workOrdersPerPage, 10) || 100,
		dateRangeFilter,
		showInactive: stringToBoolean(showInactive),
	}
}

const workOrderTypesQuery = graphql(`
	query WorkOrderTypes {
		workOrderTypes {
			data {
				id
				name
			}
		}
	}
`)

const groupsQuery = graphql(`
	query Groups {
		groups {
			data {
				id
				name
			}
		}
	}
`)

const getUserSettingQuery = graphql(`
	query GetUserSetting($lookup: SettingLookup!) {
		getCurrentUserSetting(lookup: $lookup) {
			value
		}
	}
`)
