import { QueryStore } from '../runtime/stores/query'
import artifact from '$houdini/artifacts/ShGetSearchDescription'
import { initClient } from '$houdini/plugins/houdini-svelte/runtime/client'

export class ShGetSearchDescriptionStore extends QueryStore {
	constructor() {
		super({
			artifact,
			storeName: "ShGetSearchDescriptionStore",
			variables: true,
		})
	}
}

export async function load_ShGetSearchDescription(params) {
	await initClient()

	const store = new ShGetSearchDescriptionStore()

	await store.fetch(params)

	return {
		ShGetSearchDescription: store,
	}
}
