export default {
    "name": "GetSettingsWithValues",
    "kind": "HoudiniQuery",
    "hash": "501dfca17d65689d7e677ea62f1741a40fdd9835140a8866287b7f21de720276",

    "raw": `query GetSettingsWithValues {
  getSettingsWithValues {
    id
    name
    category: location
    cascade
    value
    scope
    defaultValue
    type: settingType
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "getSettingsWithValues": {
                "type": "SettingEntityValue",
                "keyRaw": "getSettingsWithValues",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "name": {
                            "type": "String",
                            "keyRaw": "name",
                            "visible": true
                        },

                        "category": {
                            "type": "String",
                            "keyRaw": "category",
                            "visible": true
                        },

                        "cascade": {
                            "type": "Boolean",
                            "keyRaw": "cascade",
                            "visible": true
                        },

                        "value": {
                            "type": "String",
                            "keyRaw": "value",
                            "nullable": true,
                            "visible": true
                        },

                        "scope": {
                            "type": "Scope",
                            "keyRaw": "scope",
                            "visible": true
                        },

                        "defaultValue": {
                            "type": "String",
                            "keyRaw": "defaultValue",
                            "nullable": true,
                            "visible": true
                        },

                        "type": {
                            "type": "SettingType",
                            "keyRaw": "type",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=4cea240f946e4d6c6358a38d8c1cabeb00a844ccdca48bccfa0eabb681c08c2a";