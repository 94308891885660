export default {
    "name": "ShProximities",
    "kind": "HoudiniQuery",
    "hash": "2da70a19996fecdd387a5406739ff48d94002cce38fc0ddaf992c0ad86404e13",

    "raw": `query ShProximities($filter: ProductProximityFilter) {
  productProximities(filter: $filter, pagination: {pageSize: 0}) {
    data {
      id
      plantId
      name
    }
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "productProximities": {
                "type": "ProductProximityResponse",
                "keyRaw": "productProximities(filter: $filter, pagination: {pageSize: 0})",

                "selection": {
                    "fields": {
                        "data": {
                            "type": "ProductProximity",
                            "keyRaw": "data",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "plantId": {
                                        "type": "PositiveInt",
                                        "keyRaw": "plantId",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "filter": "ProductProximityFilter"
        },

        "types": {
            "ProductProximityFilter": {
                "name": "NonEmptyString",
                "plantIds": "PositiveInt",
                "plants": "PositiveInt"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=eb9dae0c4464aeda101100980b1fac7168cd465371d409757c8c8b2561d505a8";