<script lang="ts">
	import type { i18n, SvelteAsr, Mediator } from 'types/common'
	import { type SamplingHistoryData$result, type SampleFilter, type ValueType$options, graphql, type ResultStatus$options } from '$houdini'
	import type { Analysis, DisplaySavedSearch, Location, ProcessZone, Product, ProductBatch, Proximity, SavedSearchState, ScopeObject, SelectedFilters, WorkOrderType } from './sampling-history'
	import type { SetNonNullable } from 'type-fest'

	import Input from '@isoftdata/svelte-input'
	import Checkbox from '@isoftdata/svelte-checkbox'
	import Button from '@isoftdata/svelte-button'
	import { Dropdown, DropdownItem } from '@isoftdata/svelte-dropdown'
	import DateRange from '@isoftdata/svelte-date-range'
	import CollapsibleCard from '@isoftdata/svelte-collapsible-card'
	import { AsrNavTabBar } from '@isoftdata/svelte-nav-tab-bar'
	import WildSelect from 'components/WildSelect.svelte'
	import AnalysesModal from './AnalysesModal.svelte'
	import SavedSearchModal from './SavedSearchModal.svelte'
	import Fieldset from '@isoftdata/svelte-fieldset'

	import session from 'stores/session'
	import { getContext, onMount } from 'svelte'
	import { valueAcceptabilityMap, inverseValueAcceptabilityMap } from 'utility/value-acceptability-map'
	import hasPermission from 'utility/has-permission'
	import { stringToBoolean } from '@isoftdata/utility-string'
	import { buildUrl } from '@isoftdata/utility-url'
	import { reduceToTruthyValues as originalReduceToTruthyValues } from '@isoftdata/utility-object'
	import { addDays, differenceInDays, differenceInWeeks, formatISO } from 'date-fns'
	import { rangeFromDates } from '@isoftdata/utility-date-time'
	import { klona } from 'klona'
	import { v4 as uuid } from '@lukeed/uuid'
	import { entityOptGroupToArray, type EntityOptGroup, type EntityOptGroupResult } from 'utility/entity-proxy-map'
	import { getEventChecked, getEventValue } from '@isoftdata/browser-event'
	import camelCase from 'just-camel-case'
	import { upsert } from '@isoftdata/utility-array'

	type Plant = SamplingHistoryData$result['plants']['data'][number]

	type MyProps = {
		asr: SvelteAsr
		defaultSelectedFilters: Readonly<SelectedFilters>
		performedByUserList: SamplingHistoryData$result['userAccounts']['data']
		plantList: Array<Plant>
		savedSearchList: SamplingHistoryData$result['userSavedSampleSearches']['data']
		scheduleList: SamplingHistoryData$result['schedules']['data']
		selectedFilters: SelectedFilters
		locationProxy: EntityOptGroup<Location>
		proximityProxy: EntityOptGroup<Proximity>
		processZoneProxy: EntityOptGroup<ProcessZone>
		productProxy: EntityOptGroup<Product>
		analysisProxy: EntityOptGroup<Analysis>
		workOrderTypeProxy: EntityOptGroup<WorkOrderType>
		productBatchProxy: EntityOptGroup<ProductBatch>
	}

	let {
		asr,
		defaultSelectedFilters,
		performedByUserList,
		plantList,
		savedSearchList,
		scheduleList,
		selectedFilters = $bindable(),
		// lists :o
		locationProxy,
		proximityProxy,
		processZoneProxy,
		productProxy,
		analysisProxy,
		workOrderTypeProxy,
		productBatchProxy,
	}: MyProps = $props()

	const authorizedPlantIds = new Set(plantList.map(plant => plant.id))
	/** selectedFilters.selectedPlants, filtered to just the user's authorized plants*/
	const selectedPlants = $derived(selectedFilters.selectedPlants ?? [].filter(plantId => authorizedPlantIds.has(plantId)))
	const locationMap = $derived(locationProxy(selectedPlants))
	const proximityMap = $derived(proximityProxy(selectedPlants))
	const processZoneMap = $derived(processZoneProxy(selectedPlants))
	const productMap = $derived(productProxy(selectedPlants))
	const workOrderTypeMap = $derived(workOrderTypeProxy(selectedPlants))
	const productBatchMap = $derived(productBatchProxy(selectedPlants))
	const analysisMap = $derived(analysisProxy(selectedPlants))
	let loadingSavedSearch: number | null = $state(null)
	let runningSavedSearch: number | null = $state(null)

	// All this list stuff is still pretty ugly, so TODO make it less ugly
	let productList: Array<Product> = $state([])
	let analysisList: Array<Analysis> = $state([])
	$effect(() => {
		productMap.then(map => (productList = entityOptGroupToArray(map)))
	})
	$effect(() => {
		analysisMap.then(map => (analysisList = entityOptGroupToArray(map)))
	})

	$inspect('selecetdFilters', selectedFilters)

	let lastLoadedSavedSearchId: number | null = $state(null)
	let savedSearchCardExpanded = $state(false)
	let searchFiltersShown = $state(true)
	let createSavedSearchLoading = $state(false)

	// TODO apparently this doesn't need $state? Seems sus
	let analysesModal: AnalysesModal | undefined = undefined
	let savedSearchModal: SavedSearchModal | undefined = undefined
	const { t: translate } = getContext<i18n>('i18next')
	const canEditOthersSavedSearches = hasPermission('SAMPLING_CAN_MANAGE_SAVED_SEARCHES', $session.plant.id)
	const shareableScope: Map<'USER' | 'SITE' | 'GLOBAL', ScopeObject> = new Map([
		['USER', { key: 'USER', show: true, display: translate('`samplingHistory.savedSearches.modal.shareWithOptions.user', 'Just You'), iconClass: 'fa-user', icon: 'user' }],
		['SITE', { key: 'SITE', show: true, display: translate('`samplingHistory.savedSearches.modal.shareWithOptions.site.', 'Your Plant'), iconClass: 'fa-industry-windows', icon: 'industry-windows' }],
		['GLOBAL', { key: 'GLOBAL', show: true, display: translate('`samplingHistory.savedSearches.modal.shareWithOptions.global', 'Everyone'), iconClass: 'fa-globe', icon: 'globe' }],
	])

	const optionValueTypes: Record<
		ValueType$options,
		{
			element: 'input' | 'select'
			type?: 'number' | 'text' | 'date' | 'time' | 'datetime-local'
			inputMode?: 'numeric' | 'decimal'
			showToField: boolean
			parse: (val: string | undefined) => string | number | boolean | undefined
		}
	> = {
		BOOLEAN: { element: 'select', showToField: false, parse: val => stringToBoolean(val ?? 'False') },
		INTEGER: { element: 'input', type: 'number', inputMode: 'numeric', showToField: true, parse: val => (val ? parseInt(val, 10) : undefined) },
		NUMBER: { element: 'input', type: 'number', inputMode: 'decimal', showToField: true, parse: val => (val ? parseFloat(val) : undefined) },
		CURRENCY: { element: 'input', type: 'number', inputMode: 'decimal', showToField: true, parse: val => (val ? parseFloat(val) : undefined) },
		DATE: { element: 'input', type: 'date', showToField: true, parse: val => val }, // TODO parse
		TEXT: { element: 'input', type: 'text', showToField: false, parse: val => val },
		CHOICE: { element: 'select', showToField: false, parse: val => val },
		TIME: { element: 'input', type: 'time', showToField: false, parse: val => val }, // TODO parse
		DATETIME: { element: 'input', type: 'datetime-local', showToField: true, parse: val => val }, // TODO parse
	}

	const mediator = getContext<Mediator>('mediator')
	type SelectedFilterAnalysis = SelectedFilters['analysisList'][number]
	type FilterOption = SelectedFilterAnalysis['options'][number]

	const searchFilters = Object.freeze({
		fromSavedSearch: async (savedSearch: SavedSearchState): Promise<SelectedFilters> => {
			let selectedFilters: Partial<SelectedFilters> = {}

			// Limit plants to only those the user has access to. This is also done in the backend, but might as well do it here too.
			const filteredPlants = Array.isArray(savedSearch.plantIds) ? savedSearch.plantIds.filter(plantId => authorizedPlantIds.has(plantId)) : Array.from(authorizedPlantIds)

			const newAnalysisProxy = analysisProxy(filteredPlants)

			if (savedSearch.dateRange) {
				const today = new Date()
				const offsets = {
					valueFrom: savedSearch.dateRange.valueFrom,
					valueTo: savedSearch.dateRange.valueTo,
				}
				selectedFilters.dates = {
					from: typeof offsets.valueFrom === 'number' ? formatISO(addDays(today, offsets.valueFrom), { representation: 'date' }) : null,
					to: typeof offsets.valueTo === 'number' ? formatISO(addDays(today, offsets.valueTo), { representation: 'date' }) : null,
				}
				selectedFilters.dateRangeType = {
					created: savedSearch.dateRange.type === 'CREATED',
					performed: savedSearch.dateRange.type === 'PERFORMED',
				}

				selectedFilters.range =
					!selectedFilters.dates.from && !selectedFilters.dates.to
						? 'Always'
						: selectedFilters.dates.to && selectedFilters.dates.from
							? rangeFromDates(selectedFilters.dates.from, selectedFilters.dates.to) || 'Custom'
							: 'Custom'
			} else {
				selectedFilters.dates = { from: null, to: null }
				selectedFilters.range = 'Always'
			}

			// Load Analyses and Option Values
			let selectedAnalysisList: SelectedFilters['analysisList'] = []
			if (savedSearch.analyses?.length) {
				// Get analysis list for newly selected plants - This also "pre-loads" them for the rest of the UI since it's cached per-plant
				const analysisList = entityOptGroupToArray(await newAnalysisProxy)
				selectedAnalysisList = savedSearch.analyses.reduce((acc: Array<SelectedFilterAnalysis>, ssAnalysis) => {
					const foundAnalysis = analysisList.find(listAnalysis => listAnalysis.id === ssAnalysis.id) ?? null
					if (foundAnalysis?.options) {
						const filterAnalysis: SelectedFilterAnalysis = {
							id: ssAnalysis.id,
							name: foundAnalysis.name,
							category: foundAnalysis.category,
							active: foundAnalysis.active,
							analysisType: foundAnalysis.analysisType,
							selected: true,
							options:
								foundAnalysis.options?.reduce((optionAcc: Array<FilterOption>, option) => {
									const searchOption = ssAnalysis?.options?.find(searchOption => searchOption.id === option.id)
									if (searchOption) {
										if (['BOOLEAN', 'CHOICE'].includes(option.valueType)) {
											searchOption.valueFilter = searchOption.valueFrom ?? searchOption.valueTo
											searchOption.valueFrom = undefined
											searchOption.valueTo = undefined
										} else {
											searchOption.valueFrom = optionValueTypes[option.valueType].parse(searchOption.valueFrom) as string
											searchOption.valueTo = optionValueTypes[option.valueType].parse(searchOption.valueTo) as string
										}

										const optionalFilters = reduceToTruthyValues({
											valueFilter: searchOption.valueFilter ?? undefined,
											valueFilterFrom: searchOption.valueFrom ?? null,
											valueFilterTo: searchOption.valueTo ?? null,
											lotNumber: searchOption.lotNumber ?? '',
											resultStatus: searchOption.resultStatus ? inverseValueAcceptabilityMap.get(searchOption.resultStatus) : undefined,
											expirationFilterFrom: searchOption.expirationFrom ? formatISO(addDays(new Date(), searchOption.expirationFrom), { representation: 'date' }) : null,
											expirationFilterTo: searchOption.expirationTo ? formatISO(addDays(new Date(), searchOption.expirationTo), { representation: 'date' }) : null,
										})

										optionAcc?.push({
											...option,
											...optionalFilters,
											selected: true,
										})
									}
									return optionAcc
								}, []) ?? [],
						}
						acc.push({ ...filterAnalysis, resultStatus: ssAnalysis.resultStatus })
					}
					return acc
				}, [])
			}

			selectedFilters = reduceToTruthyValues<Partial<SelectedFilters>>({
				...selectedFilters,
				analysisList: selectedAnalysisList ?? [],
				selectedPlants: filteredPlants,
				samplingComments: savedSearch.samplingComments ?? '',
				testingComments: savedSearch.testingComments ?? '',
				workOrder: {
					id: savedSearch.workOrderNumber ? parseInt(savedSearch.workOrderNumber) : null, //TODO change to workOrderId
					title: savedSearch.workOrderTitle ?? '',
					typeId: savedSearch.workOrderTypeId ? parseInt(savedSearch.workOrderTypeId, 10) : null,
					typeName: savedSearch.workOrderTypeName ?? '',
					productBatch: savedSearch.productBatchName ?? '',
					internalNotes: savedSearch.workOrderInternalNotes ?? '',
				},
				tagNumber: savedSearch.tagNumber ?? '',
				productId: savedSearch.productId ?? null,
				productName: savedSearch.productName ?? '',
				processZoneId: savedSearch.processZoneId ?? null,
				processZoneName: savedSearch.processZoneName ?? '',
				productProximityId: savedSearch.productProximityId ?? null,
				productProximityName: savedSearch.productProximityName ?? '',
				performedByUserId: savedSearch.performedByUserId,
				performedByUserName: savedSearch.performedByUserName,
				locationName: savedSearch.locationName ?? '',
				sampleStatuses: savedSearch.sampleStatuses ?? [],
				scheduleName: savedSearch.scheduleName,
				matchAllOptionFilters: !!savedSearch.matchAllOptionFilters,
				isRetest: savedSearch.isRetest,
			})

			if (!selectedFilters.workOrder || !Object.keys(selectedFilters.workOrder).length) {
				delete selectedFilters.workOrder
			}

			return klona({
				...defaultSelectedFilters,
				...selectedFilters,
			})
		},
		toSavedSearch: (searchFilters: SelectedFilters): SavedSearchState => {
			let dateRange: SavedSearchState['dateRange'] = undefined
			if (searchFilters?.dates?.from || searchFilters?.dates?.to) {
				dateRange = {
					from: searchFilters.dates.from ? differenceInDays(new Date(searchFilters.dates.from), new Date()) : undefined,
					to: searchFilters.dates.to ? differenceInDays(new Date(searchFilters.dates.to), new Date()) : undefined,
					type: searchFilters.dateRangeType?.created ? 'CREATED' : 'PERFORMED',
				}
			}

			const analyses = searchFilters.analysisList?.map?.(analysis => {
				return {
					id: analysis.id,
					resultStatus: analysis.resultStatus,
					options: analysis.options?.map(option => {
						return {
							id: option.id,
							expirationDateRange: 'Custom: Relative' as const,
							resultStatus: option.resultStatus || undefined,
							expirationFrom: option.expirationFilterFrom ? differenceInDays(new Date(option.expirationFilterFrom), new Date()) : undefined,
							expirationTo: option.expirationFilterTo ? differenceInDays(new Date(option.expirationFilterTo), new Date()) : undefined,
							lotNumber: option.lotNumber ?? undefined,
							valueFrom: option.valueFilterFrom?.toString?.(),
							valueTo: option.valueFilterTo?.toString?.(),
						}
					}),
				}
			})

			/**
			 * Because we specify the type of the input object, only values that should be nullish will be nullish.
			 *
			 * Therefore, since we're only removing nullish values, asserting the return as SavedSearchState here is safe.
			 */
			return reduceToTruthyValues<SavedSearchState>(
				klona<SavedSearchState>({
					dateRange,
					analyses: analyses?.length ? analyses : undefined,
					locationName: searchFilters.locationName,
					plantIds: searchFilters.selectedPlants ?? [],
					processZoneId: searchFilters.processZoneId ?? undefined,
					processZoneName: searchFilters.processZoneName,
					productId: searchFilters.productId ?? undefined,
					productName: searchFilters.productName,
					productProximityId: searchFilters.productProximityId ?? undefined,
					productProximityName: searchFilters.productProximityName,
					performedByUserId: searchFilters.performedByUserId ?? undefined,
					performedByUserName: searchFilters.performedByUserName ?? undefined,
					samplingComments: searchFilters.samplingComments,
					tagNumber: searchFilters.tagNumber,
					testingComments: searchFilters.testingComments,
					matchAllOptionFilters: searchFilters.matchAllOptionFilters,
					workOrderNumber: searchFilters.workOrder?.id?.toString?.(),
					workOrderInternalNotes: searchFilters.workOrder?.internalNotes,
					workOrderTitle: searchFilters.workOrder?.title,
					workOrderTypeId: searchFilters.workOrder?.typeId ? searchFilters.workOrder.typeId.toString() : undefined,
					workOrderTypeName: searchFilters.workOrder?.typeName,
					productBatchName: searchFilters.workOrder?.productBatch,
					statuses: searchFilters.sampleStatuses,
					scheduleName: searchFilters.scheduleName ?? undefined,
					isRetest: searchFilters.isRetest,
				}),
			) as SavedSearchState
		},
	})

	//#region derived
	const isAtChildState = $derived(asr.getActiveState().name.indexOf('app.sampling-history.') > -1)
	const graphUrl = $derived.by(() => {
		const authToken = $session.authToken
		const dateKey = selectedFilters.dateRangeType?.created ? 'created' : 'performed'

		const graphingParams = {
			plant_id: selectedFilters.selectedPlants,
			location_name: selectedFilters.locationName,
			product_name: selectedFilters.productId ? productList.find(product => product.id === selectedFilters.productId)?.name : selectedFilters.productName,
			analysis: selectedFilters.analysisList?.length
				? JSON.stringify({
						analyses: selectedFilters.analysisList.map(analysis => {
							return {
								id: analysis.id,
								status: analysis.resultStatus ? valueAcceptabilityMap.get(analysis.resultStatus)?.graphValue : undefined,
							}
						}),
					})
				: undefined,
			analysis_option: selectedFilters.analysisList?.length
				? JSON.stringify({
						options: selectedFilters.analysisList
							.filter(analysis => analysis.options?.length)
							.map(analysis => {
								return analysis.options?.map(option => {
									return {
										id: option.id,
										status: option.resultStatus ? valueAcceptabilityMap.get(option.resultStatus)?.graphValue : undefined,
										from: option.valueFilterFrom,
										to: option.valueFilterTo,
										filter: option.valueFilter || undefined,
									}
								})
							})
							.flat(),
					})
				: undefined,
			date_type: `date_${dateKey}`,
			start_date: selectedFilters.dates?.from ? `${new Date(`${selectedFilters.dates.from}T00:00:00Z`).getTime()}` : undefined,
			end_date: selectedFilters.dates?.to ? `${new Date(`${selectedFilters.dates.to}T23:59:59Z`).getTime()}` : undefined,
			process_zone_id: selectedFilters.processZoneId,
			process_zone_name: selectedFilters.processZoneName,
			product_proximity_id: selectedFilters.productProximityId,
			product_proximity_name: selectedFilters.productProximityName,
			collected_by_id: selectedFilters.performedByUserId,
			collected_by_name: selectedFilters.performedByUserName,
			tag_number: selectedFilters.tagNumber,
			wo_id: selectedFilters.workOrder?.id,
			wo_title: selectedFilters.workOrder?.title,
			wo_type_id: selectedFilters.workOrder?.typeId,
			wo_type_name: selectedFilters.workOrder?.typeName,
			batch_name: selectedFilters.workOrder?.productBatch, //This should always be name
			comments: selectedFilters.samplingComments,
			findings: selectedFilters.testingComments,
			wo_internal_notes: selectedFilters.workOrder?.internalNotes,
			all_filters: selectedFilters.matchAllOptionFilters ? 'true' : 'false',
			schedule_name: selectedFilters.scheduleName,
		}

		type GraphingParams = typeof graphingParams
		type FixedGraphingParams = Partial<SetNonNullable<GraphingParams>>

		return buildUrl(
			`/dashboard/samplegraph.php`,
			{
				chart_type: 'scatter1',
				theme: 'large',
				footer: 'hide',
				show_thresholds: false,
				session_token: authToken,
				// Nullish values are removed (making that property optional) so assert the proper type for the buildUrl function
				...(reduceToTruthyValues(graphingParams) as FixedGraphingParams),
			},
			'#',
		)
	})

	const displaySavedSearchList: Array<DisplaySavedSearch> = $derived.by(() => {
		const today = new Date()

		return savedSearchList.map((savedSearch): DisplaySavedSearch => {
			const createdOn = new Date(savedSearch.createdOn)
			let displayCreated: string

			if (differenceInWeeks(today, createdOn) > 1) {
				displayCreated = `on ${createdOn.toLocaleDateString()}`
			} else {
				const relativeTimeFormatter = new Intl.RelativeTimeFormat('en', { style: 'narrow' })
				displayCreated = relativeTimeFormatter.format(differenceInDays(createdOn, today), 'day')
			}

			return {
				...savedSearch,
				createdOn,
				displayCreated,
				sharedWith: shareableScope.get(savedSearch.scope),
			}
		})
	})
	const displayEditableSavedSearchList = $derived(displaySavedSearchList.filter(savedSearch => savedSearch.createdByUser.id === $session.userAccountId))
	const searchArgs = $derived.by(() => {
		let args: SampleFilter = {
			location: {
				productProximity: {
					name: selectedFilters.productProximityName || undefined,
				},
				processZone: {
					name: selectedFilters.processZoneName || undefined,
				},
			},
			locationName: selectedFilters.locationName,
			performedByUserIds: selectedFilters.performedByUserId ? [selectedFilters.performedByUserId] : undefined,
			performedByUserName: selectedFilters.performedByUserName,
			plantIds: selectedFilters.selectedPlants,
			productName: selectedFilters.productName,
			processZoneIds: selectedFilters.processZoneId ? [selectedFilters.processZoneId] : undefined,
			productIds: selectedFilters.productId ? [selectedFilters.productId] : undefined,
			productProximityIds: selectedFilters.productProximityId ? [selectedFilters.productProximityId] : undefined,
			samplingComments: selectedFilters.samplingComments,
			scheduleName: selectedFilters.scheduleName,
			statuses: selectedFilters.sampleStatuses,
			tagNumber: selectedFilters.tagNumber,
			testingComments: selectedFilters.testingComments,
		}

		const dateKey = selectedFilters.dateRangeType?.created ? 'created' : 'performed'
		if (selectedFilters.dates?.from) {
			args[`${dateKey}From`] = `${selectedFilters.dates.from}T00:00:00Z`
		}
		if (selectedFilters.dates?.to) {
			args[`${dateKey}To`] = `${selectedFilters.dates.to}T23:59:59Z`
		}

		if (selectedFilters.workOrder && Object.keys(reduceToTruthyValues(selectedFilters.workOrder)).length > 0) {
			args.workOrder = reduceToTruthyValues<SampleFilter['workOrder']>({
				id: selectedFilters?.workOrder?.id?.toString?.(),
				title: selectedFilters?.workOrder?.title,
				type: {
					id: selectedFilters?.workOrder?.typeId || undefined,
				},
				productBatch: selectedFilters?.workOrder?.productBatch
					? {
							name: selectedFilters?.workOrder?.productBatch,
						}
					: undefined,
				internalNotes: selectedFilters?.workOrder?.internalNotes,
			})
		}

		if (selectedFilters.analysisList?.length) {
			const optionValueTypeMutations = new Map([['DATETIME', val => val.replace('T', ' ')]])

			const getResultFilter = ({
				valueFilter,
				valueFilterFrom,
				valueFilterTo,
				valueType,
			}: {
				valueFilter?: string | null
				valueFilterFrom?: string | null
				valueFilterTo?: string | null
				valueType: ValueType$options
			}) => {
				let result = reduceToTruthyValues({
					eq: valueFilter ? `${valueFilter}` : undefined,
					gte: !valueFilter && valueFilterFrom ? `${valueFilterFrom}` : undefined,
					lte: !valueFilter && valueFilterTo ? `${valueFilterTo}` : undefined,
				})

				if (Object.keys(result).length > 0) {
					for (const property in result) {
						result[property] = optionValueTypeMutations.get(valueType) ? optionValueTypeMutations.get(valueType)?.(result[property]) : result[property]
					}
					return result
				} else {
					return undefined
				}
			}

			args.analyses = selectedFilters.analysisList.map(({ id, options, resultStatus }) => {
				return {
					id,
					options: options
						?.filter(option => option.resultStatus || option.valueFilter || option.valueFilterFrom || option.valueFilterTo)
						.map(option => {
							return {
								id: option.id,
								lot: option.lotNumber ? option.lotNumber.toString() : undefined,
								result: getResultFilter(option),
								resultStatuses: option.resultStatus ? [option.resultStatus] : undefined,
								// apparently no API filters for expiration dates
							}
						}),
					resultStatuses: resultStatus ? [resultStatus] : undefined,
					matchAllOptionFilters: selectedFilters.matchAllOptionFilters,
				}
			})
		}

		return reduceToTruthyValues(args)
	})
	// Needed to reload the selected options when we refresh the page
	const selectedOptions = $derived(
		selectedFilters.analysisList
			.reduce((ids: Array<number>, analysis) => {
				if (analysis.selected) {
					const optionIds = analysis.options?.reduce((optionIds: Array<number>, option) => {
						if (option.selected) {
							optionIds.push(option.id)
						}
						return optionIds
					}, [])
					ids.push(...optionIds)
				}

				return ids
			}, [])
			.join(','),
	)
	const lastLoadedSavedSearch = $derived(lastLoadedSavedSearchId ? (savedSearchList.find(savedSearch => savedSearch.id === lastLoadedSavedSearchId) ?? null) : null)
	// #endregion
	//#region functions
	function reduceToTruthyValues<T>(object: T): Partial<NonNullable<T>> {
		let obj = originalReduceToTruthyValues({ object, options: { onlyNoNullish: true, objectRecursive: true } })

		//TODO remove this by adding a feature to reduceToTruthyValues to allow for treating empty strings as falsy, in additional to nullish.
		return Object.entries(obj).reduce((acc: Partial<NonNullable<T>>, [key, value]) => {
			if (typeof value === 'string' && !value) {
				return acc
			} else {
				return {
					...acc,
					[key]: value,
				}
			}
		}, {})
	}

	async function loadSavedSearch(savedSearchId: number) {
		loadingSavedSearch = savedSearchId
		const savedSampleSearch = await loadSavedSearchQuery
			.fetch({
				variables: {
					savedSampleSearchId: savedSearchId.toString(),
				},
			})
			.then(({ data }) => data?.savedSampleSearch)

		if (!savedSampleSearch) {
			mediator.call('showMessage', {
				heading: translate('samplingHistory.errorLoadingSavedSearchHeading', 'Error loading saved search'),
				message: translate('samplingHistory.errorLoadingSavedSearchMessage', 'No data was returned from the server'),
				type: 'danger',
				time: false,
			})
			return
		}

		const searchRes = JSON.parse(savedSampleSearch.searchState) as SavedSearchState
		savedSearchCardExpanded = false
		searchFiltersShown = true
		lastLoadedSavedSearchId = savedSearchId
		selectedFilters = reduceToTruthyValues(await searchFilters.fromSavedSearch(searchRes)) as SelectedFilters // Should be safe because we're only removing nullish values
		loadingSavedSearch = null
	}

	async function runSavedSearch(savedSearch: { id: number; name: string }) {
		runningSavedSearch = savedSearch.id
		await loadSavedSearch(savedSearch.id)
		search()
		// TODO, maybe remove before merge if people hate it, but I want it here for ease of testing
		mediator.call('activity', {
			icon: 'flask-vial',
			title: `Search (${savedSearch.name})`,
			type: 'SAMPLING_HISTORY',
			route: 'app.sampling-history.results',
			name: 'Saved Search',
			parameters: {
				filter: JSON.stringify(searchArgs),
				uuid: uuid(),
				pageNumber: '1',
				fromDate: selectedFilters.dates?.from ?? undefined,
				toDate: selectedFilters.dates?.to ?? undefined,
				selectedOptions,
			},
		})
		runningSavedSearch = null
	}

	function search() {
		if (!selectedFilters.selectedPlants.length) {
			mediator.call('showMessage', {
				heading: translate('samplingHistory.noPlantsSelectedHeading', 'No Plants Selected'),
				message: translate('samplingHistory.noPlantsSelectedMessage', 'Please select at least one plant to search.'),
				type: 'danger',
				time: false,
			})
			return
		}

		asr.go('app.sampling-history.results', {
			filter: JSON.stringify(searchArgs),
			uuid: uuid(),
			pageNumber: 1,
			fromDate: selectedFilters.dates?.from ?? undefined,
			toDate: selectedFilters.dates?.to ?? undefined,
			selectedOptions,
		})
	}

	async function createSavedSearch() {
		const searchState = searchFilters.toSavedSearch(selectedFilters)
		createSavedSearchLoading = true
		const generatedDescription = await savedSearchModal?.getGeneratedDescription(searchState)
		createSavedSearchLoading = false

		savedSearchModal?.open({
			action: 'ADD',
			search: searchState,
			description: generatedDescription,
		})
	}

	function editSavedSearch(savedSearchId: number) {
		const savedSearchToEdit = savedSearchList.find(savedSearch => savedSearch.id === savedSearchId)
		if (!savedSearchToEdit) {
			return
		}

		savedSearchModal?.open({
			action: 'EDIT',
			search: null,
			description: savedSearchToEdit?.description ?? undefined,
			scope: savedSearchToEdit?.scope,
			searchId: savedSearchId,
			searchName: savedSearchToEdit?.name,
		})
	}

	function replaceSavedSearch() {
		let replaceSavedSearchId: number | null = null
		let existingSavedSearch: DisplaySavedSearch | null = null
		if (lastLoadedSavedSearch) {
			existingSavedSearch = displayEditableSavedSearchList.find(savedSearch => savedSearch.id === lastLoadedSavedSearch.id) ?? null
			replaceSavedSearchId = existingSavedSearch?.id ?? null
		}

		savedSearchModal?.open({
			action: 'REPLACE',
			search: searchFilters.toSavedSearch(selectedFilters),
			description: existingSavedSearch?.description ?? undefined,
			scope: existingSavedSearch?.scope,
			searchId: replaceSavedSearchId,
			searchName: existingSavedSearch?.name,
		})
	}

	function clearFilters() {
		if (confirm(translate('samplingHistory.resetToDefaultFiltersConfirm', 'Are you sure you want to clear all search filters?'))) {
			selectedFilters = klona(defaultSelectedFilters)
			asr.go('app.sampling-history')
		}
	}

	function deleteSavedSearch(savedSearch: { id: number; name: string }) {
		// This is a straight port of the Ractive version, including the commented out code.
		if (confirm(`"${savedSearch.name}"\n\n${translate('samplingHistory.savedSearches.deleteConfirmation', 'Are you sure you want to delete this saved search?')}`)) {
			try {
				throw new Error('Not implemented')
				/* 						await mediator.call('apiFetch', `#graphql
							mutation DeleteSavedSampleSearch($deleteSavedSampleSearchId: Float!) {
								deleteSavedSampleSearch(id: $deleteSavedSampleSearchId)
							}
						`, { deleteSavedSampleSearchId: savedSearch.id })

						this.splice('savedSearchList', this.get('savedSearchList').findIndex(search => search.id === savedSearch.id), 1) */
			} catch (err) {
				console.error(err)
				alert(translate('samplingHistory.savedSearches.deleteError', 'There was an error deleting the saved search'))
			}
		}
	}

	// #endregion

	// #region houdini

	const loadSavedSearchQuery = graphql(`
		query ShLoadSavedSearch($savedSampleSearchId: ID!) {
			savedSampleSearch(id: $savedSampleSearchId) {
				id
				name
				searchState
			}
		}
	`)

	// #endregion
</script>

<div class="card border-0">
	<h4
		class="card-header"
		style="border-radius: unset;"
	>
		<i
			class="fas fa-flask-vial fa-fw"
			aria-hidden="true"
		></i>
		{translate('samplingHistory.title', 'Sampling History')}
	</h4>
	<div class="card-body p-1">
		<CollapsibleCard
			cardClass="w-100 mb-1"
			entireHeaderToggles
			bind:bodyShown={savedSearchCardExpanded}
			cardHeaderClass="card-header d-flex justify-content-between h5"
			bodyClass="p-0"
			on:show={() => {
				// TODO this won't work until we wait for the animation to finish
				if (savedSearchCardExpanded && lastLoadedSavedSearchId) {
					document.getElementById(`savedSearchId${lastLoadedSavedSearchId}`)?.scrollIntoView({ behavior: 'smooth' })
				}
			}}
		>
			<svelte:fragment slot="cardHeader">
				<div inert>
					<span class="d-block">{translate('samplingHistory.savedSearches.title', 'Saved Searches')}</span>
					{#if lastLoadedSavedSearch}
						<span
							class="d-block"
							style="font-size: x-small">{translate('samplingHistory.savedSearches.lastLoaded', 'Last Loaded')}: {lastLoadedSavedSearch.name}</span
						>
					{/if}
				</div>
			</svelte:fragment>
			<div
				id="savedSearchList"
				class="list-group list-group-flush"
				style="max-height: 500px; overflow-y: scroll;"
			>
				{#each displaySavedSearchList as search}
					{@const loadingThisSearch = loadingSavedSearch === search.id && !runningSavedSearch}
					{@const runningThisSearch = runningSavedSearch === search.id}
					<div
						id="savedSearchId{search.id}"
						class="list-group-item"
						class:list-group-item-primary={search.id === lastLoadedSavedSearchId}
					>
						<div class="d-flex justify-content-between">
							<h6 class="mb-1">{search.name}</h6>
							<small class="d-none d-sm-inline-block"
								>{translate('samplingHistory.savedSearches.createdBy', 'Created by')}
								{#if search.createdByUser.id === $session.userAccountId}
									{translate('common:you', 'You')}
								{:else}
									{search.createdByUser.fullName}
								{/if}
								{search.displayCreated}
							</small>
						</div>
						<p
							class="mb-1"
							style="font-size: smaller;"
						>
							{search.description}
						</p>
						<div class="d-flex justify-content-between align-items-end">
							<div>
								{#if search.sharedWith}
									<span
										style="font-size: x-small;"
										class="d-none d-sm-block badge badge-pill badge-primary"
										><i class="fa-solid {search.sharedWith.iconClass}"></i>
										{translate('samplingHistory.savedSearches.sharedWith', 'Shared with')}
										{translate(`samplingHistory.savedSearches.sharedWith${camelCase(search.sharedWith.key)}`, search.sharedWith.display)}</span
									>
								{/if}
							</div>
							<div>
								<Dropdown
									split
									size="sm"
									isLoading={loadingThisSearch}
									icon={{
										prefix: loadingThisSearch ? 'fas' : 'fak',
										class: 'fa-solid-floppy-disk-magnifying-glass',
									}}
									on:click={() => loadSavedSearch(search.id)}
								>
									{translate('common:load', 'Load')}
									<svelte:fragment slot="dropdownItems">
										<h6 class="dropdown-header">{translate('samplingHistory.savedSearches.moreActions', 'More Actions')}...</h6>
										<DropdownItem
											icon="pencil"
											disabled={search.createdByUser.id !== $session.userAccountId && !canEditOthersSavedSearches}
											on:click={() => editSavedSearch(search.id)}>{translate('common:edit', 'Edit')}</DropdownItem
										>
										<DropdownItem
											icon="trash"
											disabled={search.createdByUser.id !== $session.userAccountId && !canEditOthersSavedSearches}
											on:click={() => deleteSavedSearch(search)}>{translate('common:delete', 'Delete')}</DropdownItem
										>
									</svelte:fragment>
								</Dropdown>
								<Button
									size="sm"
									color="success"
									iconClass="play"
									isLoading={runningThisSearch}
									on:click={() => runSavedSearch(search)}
									>{translate('common:run', 'Run')}
								</Button>
							</div>
						</div>
					</div>
				{:else}
					{translate('samplingHistory.noSavedSearchesPlaceholder', 'Build a search below. After saving, it will appear in this area.')}
				{/each}
			</div>
		</CollapsibleCard>

		<CollapsibleCard
			cardClass="w-100"
			entireHeaderToggles
			headerText={translate('samplingHistory.sampleFilters', 'Sample Filters')}
			bodyShown={searchFiltersShown}
			cardHeaderClass="card-header d-flex justify-content-between h5"
		>
			<div class="form-row">
				<div class="col-lg-8">
					<Checkbox
						showLabel={false}
						type="radio"
						radioButtonColor="primary"
						trueLabel={translate('samplingHistory.dateCreated', 'Date Created')}
						falseLabel={translate('samplingHistory.datePerformed', 'Date Performed')}
						checked={!!selectedFilters.dateRangeType?.created}
						on:change={event => {
							const checked = stringToBoolean(getEventValue(event))
							selectedFilters.dateRangeType = {
								created: checked,
								performed: !checked,
							}
						}}
					></Checkbox>
					<DateRange
						allowNone
						colClass="col-md-4"
						excludedRanges={selectedFilters.range === 'Always' ? [] : ['Always']}
						range={selectedFilters.range}
						rangeLabel={translate('common:dateRange', 'Date Range')}
						fromLabel={translate('common:from', 'From')}
						toLabel={translate('common:to', 'To')}
						bind:dates={() => ({ from: selectedFilters.dates?.from ?? '', to: selectedFilters.dates?.to ?? '' }), v => (selectedFilters.dates = v)}
					></DateRange>
					<div class="form-row">
						<div class="col-md-6">
							<WildSelect
								useOptGroup
								valueProp="location"
								labelProp="location"
								label={translate('samplingHistory.location', 'Location')}
								emptyValue=""
								options={locationMap}
								emptyText="-- {translate('samplingHistory.locationSelect', 'Select Location')} --"
								bind:inputValue={() => selectedFilters.locationName ?? '', v => (selectedFilters.locationName = v)}
								bind:selectValue={() => selectedFilters.locationName ?? '', v => (selectedFilters.locationName = v)}
							/>
						</div>
						<div class="col-md-6">
							<WildSelect
								useOptGroup
								selectLabelProp="name"
								selectValueProp="name"
								inputLabelProp="name"
								inputValueProp="name"
								emptyValue=""
								label={translate('samplingHistory.product', 'Product')}
								options={productMap}
								emptyText="-- {translate('samplingHistory.productSelect', 'Select Product')} --"
								bind:inputValue={() => selectedFilters.productName ?? '', v => (selectedFilters.productName = v)}
								bind:selectValue={() => selectedFilters.productId ?? null, v => (selectedFilters.productId = v)}
							/>
						</div>
						<div class="col-md-6">
							<WildSelect
								useOptGroup
								label={translate('proximity', 'Proximity')}
								selectLabelProp="name"
								selectValueProp="id"
								inputLabelProp="name"
								inputValueProp="name"
								options={proximityMap}
								emptyText="-- {translate('samplingHistory.proximitySelect', 'Select Proximity')} --"
								bind:inputValue={selectedFilters.productProximityName}
								bind:selectValue={selectedFilters.productProximityId}
							/>
						</div>
						<div class="col-md-6">
							<WildSelect
								useOptGroup
								label={translate('processZone', 'Process Zone')}
								selectLabelProp="name"
								selectValueProp="id"
								inputLabelProp="name"
								inputValueProp="name"
								options={processZoneMap}
								emptyText="-- {translate('samplingHistory.processZoneSelect', 'Select Process Zone')} --"
								bind:inputValue={selectedFilters.processZoneName}
								bind:selectValue={selectedFilters.processZoneId}
							/>
						</div>
						<div class="col-md-6">
							<WildSelect
								label="Schedule"
								selectLabelProp="name"
								selectValueProp="name"
								inputLabelProp="name"
								inputValueProp="name"
								emptyText="-- {translate('samplingHistory.scheduleSelect', 'Select Schedule')} --"
								emptyValue=""
								options={scheduleList}
								bind:inputValue={selectedFilters.scheduleName}
								bind:selectValue={selectedFilters.scheduleName}
							/>
						</div>
						<div class="col-md-2">
							<Input
								label={translate('sampleTagNumber', 'Sample Tag #')}
								bind:value={() => selectedFilters.tagNumber ?? null, v => (selectedFilters.tagNumber = v ?? undefined)}
							/>
						</div>
						<div class="col-md-4">
							<WildSelect
								label={translate('sampledByUser', 'Performed by User')}
								selectLabelProp="name"
								selectValueProp="id"
								inputLabelProp="name"
								inputValueProp="name"
								options={performedByUserList}
								emptyText="-- {translate('samplingHistory.performedByUserSelect', 'Select Performed by User')} --"
								bind:inputValue={selectedFilters.performedByUserName}
								bind:selectValue={selectedFilters.performedByUserId}
							/>
						</div>
						<div class="col-md-6">
							<Input
								label={translate('samplingComments', 'Sampling Comments')}
								placeholder={translate('wildcardHint', 'Use * to wildcard')}
								bind:value={() => selectedFilters.samplingComments ?? '', v => (selectedFilters.samplingComments = v ?? undefined)}
							/>
						</div>
						<div class="col-md-6">
							<Input
								label={translate('testingComments', 'Testing Comments')}
								placeholder={translate('wildcardHint', 'Use * to wildcard')}
								bind:value={() => selectedFilters.testingComments ?? '', v => (selectedFilters.testingComments = v ?? undefined)}
							/>
						</div>

						<div class="col-12 mt-3">
							<div class="card">
								<div class="card-header"><h6 class="mb-0">{translate('common:workOrder', 'Work Order')}</h6></div>
								<div class="card-body">
									<div class="form-row">
										<div class="col-xl-2 col-md-6">
											<Input
												label="#"
												placeholder={translate('common:workOrderNumber', 'Work Order #')}
												bind:value={selectedFilters.workOrder.id}
											/>
										</div>
										<div class="col-xl-5 col-md-6">
											<Input
												label="{translate('title', 'Title')} "
												placeholder={translate('common:workOrderTitle', 'Work Order Title')}
												bind:value={selectedFilters.workOrder.title}
											/>
										</div>
										<div class="col-xl-5 col-md-6">
											<WildSelect
												useOptGroup
												label={translate('samplingHistory.workOrder.type', 'Type')}
												selectLabelProp="name"
												selectValueProp="id"
												inputLabelProp="name"
												inputValueProp="name"
												options={workOrderTypeMap}
												emptyText="-- {translate('samplingHistory.workOrder.typeSelect', 'Select Type')} --"
												bind:inputValue={selectedFilters.workOrder.typeName}
												bind:selectValue={selectedFilters.workOrder.typeId}
											/>
										</div>
										<div class="col-md-6">
											<WildSelect
												useOptGroup
												label={translate('productBatch', 'Product Batch')}
												valueProp="name"
												labelProp="name"
												emptyValue=""
												options={productBatchMap}
												emptyText="-- {translate('samplingHistory.productBatchSelect', 'Select Product Batch')} --"
												bind:inputValue={selectedFilters.workOrder.productBatch}
												bind:selectValue={selectedFilters.workOrder.productBatch}
											/>
										</div>
										<div class="col-md-6">
											<Input
												label={translate('internalNotes', 'Internal Notes')}
												placeholder={translate('wildcardHint', 'Use * to wildcard')}
												bind:value={selectedFilters.workOrder.internalNotes}
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<!-- TODO replace hardcoded height with cool flexbox stuff? -->
				<div
					class="col-lg-4 d-flex flex-column"
					style="height: 643px;"
				>
					<div class="d-flex justify-content-between align-items-baseline">
						<label
							class="col-form-label font-weight-bold"
							id="plants-label"
							for="plants"
							title="Plants">*&nbsp;{translate('plants', 'Plants')}</label
						>
						{#if !selectedFilters.selectedPlants.length}
							<small class="ml-1 text-danger">Required</small>
						{/if}
						<small class="ml-auto">{translate('multiSelectInstructions', 'Shift + Click to select a range')}</small>
					</div>
					<select
						multiple
						id="plants"
						class="form-control form-control-sm mb-1"
						style="height: 25%"
						bind:value={selectedFilters.selectedPlants}
					>
						{#each plantList as { id, code, name }}
							<option value={id}>{code} - {name}</option>
						{/each}
					</select>
					<div class="d-flex justify-content-between">
						<label
							class="col-form-label"
							id="analysis-label"
							for="analysis"
							title="Analysis"
							>{translate('common:analyses', 'Analyses')} ({selectedFilters.matchAllOptionFilters
								? translate('common:matchesAll', 'Matches All')
								: translate('common:matchesAny', 'Matches Any')})</label
						>
						<div>
							<Button
								outline
								size="sm"
								color="success"
								iconClass="pencil"
								on:click={() => analysesModal?.open(selectedFilters.analysisList, selectedFilters.matchAllOptionFilters)}>{translate('samplingHistory.editSelections', 'Edit Selections')}</Button
							>
						</div>
					</div>
					{#snippet resultStatusBadge({ resultStatus }: { resultStatus: ResultStatus$options | undefined })}
						{@const acceptability = resultStatus && valueAcceptabilityMap.get(resultStatus)}
						{#if acceptability}
							<span class="ml-1 badge badge-{acceptability.colorClass}">
								{acceptability.label}
							</span>
						{/if}
					{/snippet}
					<div
						class="card"
						style="height: 65%; overflow-y: auto;"
					>
						<ul class="list-group list-group-flush">
							{#each selectedFilters.analysisList as analysis (analysis.id)}
								<li class="list-group-item">
									<div class="d-flex align-items-center">
										<span class="font-weight-bold">{analysis.name}</span>
										{@render resultStatusBadge({ resultStatus: analysis.resultStatus })}
									</div>
									<ul>
										{#each analysis.options as option (option.id)}
											<li>
												{option.option}
												{@render resultStatusBadge({ resultStatus: option.resultStatus })}
												{#if option.valueFilter}
													<div class="mb-1 font-italic ml-3 small">
														{translate('samplingHistory.isFilterHint', 'Is {{- valueFilter}}', {
															valueFilter: option.valueFilter,
														})}
													</div>
												{/if}
												{#if option.valueFilterFrom && option.valueFilterTo}
													<div class="mb-1 font-italic ml-3 small">
														{translate('samplingHistory.fromToFilterHint', 'From {{- valueFilterFrom}} to {{- valueFilterTo}}', {
															valueFilterFrom: option.valueFilterFrom,
															valueFilterTo: option.valueFilterTo,
														})}
													</div>
												{:else if option.valueFilterFrom}
													<div class="mb-1 font-italic ml-3 small">
														{translate('samplingHistory.fromFilterHint', 'From {{- valueFilterFrom}}', {
															valueFilterFrom: option.valueFilterFrom,
														})}
													</div>
												{:else if option.valueFilterTo}
													<div class="mb-1 font-italic ml-3 small">
														{translate('samplingHistory.upToFilterHint', 'Up to {{- valueFilterTo}}', {
															valueFilterTo: option.valueFilterTo,
														})}
													</div>
												{/if}
												{#if option.lotNumber}
													<div class="mb-1 font-italic ml-3 small">
														{translate('samplingHistory.lotFilterHint', 'Lot {{- lotNumber}}', {
															lotNumber: option.lotNumber,
														})}
													</div>
												{/if}
												{#if option.expirationFilterFrom && option.expirationFilterTo}
													<div class="mb-1 font-italic ml-3 small">
														{translate('samplingHistory.expiresFromToFilterHint', 'Expires from {{- expirationFilterFrom}} to {{- expirationFilterTo}}', {
															expirationFilterFrom: option.expirationFilterFrom,
															expirationFilterTo: option.expirationFilterTo,
														})}
													</div>
												{:else if option.expirationFilterFrom}
													<div class="mb-1 font-italic ml-3 small">
														{translate('samplingHistory.expiresFromFilterHint', 'Expires from {{- expirationFilterFrom}}', {
															expirationFilterFrom: option.expirationFilterFrom,
														})}
													</div>
												{:else if option.expirationFilterTo}
													<div class="mb-1 font-italic ml-3 small">
														{translate('samplingHistory.expiresToFilterHint', 'Expires up to {{- expirationFilterTo}}', {
															expirationFilterTo: option.expirationFilterTo,
														})}
													</div>
												{/if}
											</li>
										{:else}
											<i>All Options</i>
										{/each}
									</ul>
								</li>
							{:else}
								<li class="list-group-item font-italic">{translate('editSelectionHelpText', "Use the 'Edit Selections' button to add analyses & options")}</li>
							{/each}
						</ul>
					</div>
					<Fieldset legendText={translate('common:status', 'Status')}>
						<Checkbox
							inline
							label={translate('common:closed', 'Closed')}
							checked={selectedFilters.sampleStatuses.includes('CLOSED')}
							on:change={event => {
								const checked = getEventChecked(event)
								if (checked) {
									selectedFilters.sampleStatuses.push('CLOSED')
								} else {
									selectedFilters.sampleStatuses = selectedFilters.sampleStatuses.filter(status => status !== 'CLOSED')
								}
							}}
						/>
						<Checkbox
							inline
							label={translate('common:open', 'Open')}
							checked={selectedFilters.sampleStatuses.includes('OPEN')}
							on:change={event => {
								const checked = getEventChecked(event)
								if (checked) {
									selectedFilters.sampleStatuses.push('OPEN')
								} else {
									selectedFilters.sampleStatuses = selectedFilters.sampleStatuses.filter(status => status !== 'OPEN')
								}
							}}
						/>
						<Checkbox
							inline
							label={translate('common:cancelled', 'Cancelled')}
							checked={selectedFilters.sampleStatuses.includes('CANCELLED')}
							on:change={event => {
								const checked = getEventChecked(event)
								if (checked) {
									selectedFilters.sampleStatuses.push('CANCELLED')
								} else {
									selectedFilters.sampleStatuses = selectedFilters.sampleStatuses.filter(status => status !== 'CANCELLED')
								}
							}}
						/>
						<Checkbox
							inline
							label={translate('common:sampled', 'Sampled')}
							checked={selectedFilters.sampleStatuses.includes('SAMPLED')}
							on:change={event => {
								const checked = getEventChecked(event)
								if (checked) {
									selectedFilters.sampleStatuses.push('SAMPLED')
								} else {
									selectedFilters.sampleStatuses = selectedFilters.sampleStatuses.filter(status => status !== 'SAMPLED')
								}
							}}
						/>
					</Fieldset>
				</div>
			</div>
		</CollapsibleCard>
	</div>
	<div class="card-footer">
		<div class="d-flex justify-content-between">
			<div>
				<Button
					outline
					size="sm"
					color="secondary"
					iconClass="floppy-disk"
					isLoading={createSavedSearchLoading}
					on:click={createSavedSearch}>{translate('samplingHistory.saveSearch', 'Save Search')}...</Button
				>
				<Button
					outline
					size="sm"
					color="secondary"
					iconClass="floppy-disk-circle-arrow-right"
					on:click={() => replaceSavedSearch()}
					disabled={savedSearchList.length < 1}>{translate('samplingHistory.replaceSearch', 'Replace Search')}...</Button
				>
			</div>
			<div>
				<Button
					outline
					size="sm"
					color="secondary"
					iconClass="broom"
					on:click={clearFilters}
					style="width: 12rem;">{translate('samplingHistory.resetToDefaultFilters', 'Reset to Default Filters')}</Button
				>
				<Button
					size="sm"
					iconClass="search"
					style="width: 8rem;"
					on:click={search}>{translate('common:search', 'Search')}</Button
				>
			</div>
		</div>
	</div>
	<div
		class="card border-left-0 border-right-0"
		style="border-radius: 0"
	>
		<div class="card-header">
			<AsrNavTabBar
				{asr}
				tabs={[
					{
						name: 'app.sampling-history.results',
						title: translate('common:results', 'Results'),
						disabled: !isAtChildState,
						inherit: true,
					},
					{
						name: 'app.sampling-history.graph',
						parameters: { url: graphUrl },
						title: translate('common:graph', 'Graph'),
						inherit: true,
					},
				]}
				breakpoint="md"
			/>
		</div>
		<uiView></uiView>
	</div>
</div>

<AnalysesModal
	{analysisList}
	confirm={({ matchAllOptionFilters, selectedAnalyses }) => {
		selectedFilters.analysisList = selectedAnalyses
		selectedFilters.matchAllOptionFilters = matchAllOptionFilters
	}}
	bind:this={analysesModal}
></AnalysesModal>

<SavedSearchModal
	{displaySavedSearchList}
	{shareableScope}
	confirm={search => {
		lastLoadedSavedSearchId = search.id
		upsert(savedSearchList, 'id', search)
	}}
	bind:this={savedSearchModal}
></SavedSearchModal>
