<script lang="ts">
	import type { i18n } from 'i18next'
	import type { RecalculateSamplesModalAnalyses$result, RecalculateSamplesModalLocations$result } from '$houdini'
	import type { Plant } from 'utility/analysis-management-helper'
	import type { NamedDateRange } from '@isoftdata/utility-date-time'

	type Analysis = RecalculateSamplesModalAnalyses$result['analyses']['data'][number]
	type Location = RecalculateSamplesModalLocations$result['locations']['data'][number]

	import { graphql } from '$houdini'
	import { makeGroupedEntityLoader } from 'utility/entity-proxy-map'

	import Modal from '@isoftdata/svelte-modal'
	import DateRange from '@isoftdata/svelte-date-range'
	import Select from '@isoftdata/svelte-select'

	import hasPermission from 'utility/has-permission'

	import { getContext } from 'svelte'

	export let plants: Array<Plant>
	export let showDirtyWarning = false

	const i18next = getContext<i18n>('i18next')
	const { t: translate } = i18next

	let show = false
	let analysisLoader = makeGroupedEntityLoader([], -1, async plantId => {
		const { data } = await getAnalyses.fetch({
			variables: {
				filter: {
					plantIds: [plantId],
					activeOnly: true,
					type: 'TESTING',
				},
			},
		})
		return data?.analyses.data ?? []
	})
	let locationsLoader = makeGroupedEntityLoader([], -1, async plantId => {
		const { data } = await getLocations.fetch({
			variables: {
				filter: {
					plantIds: [plantId],
					activeOnly: true,
				},
			},
		})
		return data?.locations.data ?? []
	})

	let range: NamedDateRange | 'Custom' = 'Today'
	let dates: { from: string; to: string }
	let error: string = ''
	let selectedPlant: Plant | null = null
	let selectedLocation: Location | null = null
	let selectedAnalysis: Analysis | null = null

	$: locationsLoading = $getLocations.fetching
	$: analysesLoading = $getAnalyses.fetching
	$: analyses = selectedPlant ? analysisLoader(selectedPlant.id) : []
	$: locations = selectedPlant ? locationsLoader(selectedPlant.id) : []

	$: validPlants = plants.filter(plant => hasPermission('ANALYSIS_CAN_RECALCULATE_SAMPLE_HISTORY', plant.id))
	$: subtitle = computeSubtitle({
		plant: selectedPlant,
		location: selectedLocation,
		analysis: selectedAnalysis,
		range,
		dates,
		show,
	})

	export function open(plantId: number, selectedAnalysisId: number | null = null) {
		show = true
		selectedPlant = validPlants.find(plant => plant.id === plantId) ?? null
		// realistically, this should resolve immediately
		Promise.resolve(analyses).then(analyses => {
			if (selectedAnalysisId) {
				selectedAnalysis = analyses.find(analysis => analysis.id === selectedAnalysisId) ?? null
			}
		})
	}

	async function confirm() {
		try {
			await recalculateSamples.mutate({
				input: {
					plantId: selectedPlant?.id ?? null,
					locationId: selectedLocation?.id ?? null,
					analysisId: selectedAnalysis?.id ?? null,
					dateFrom: dates.from,
					dateTo: dates.to,
				},
			})
			show = false
		} catch (err: any) {
			error = err.message
		}
	}

	function computeSubtitle({
		plant,
		location,
		analysis,
		range,
		dates,
		show,
	}: {
		plant: Plant | null
		location: Location | null
		analysis: Analysis | null
		range: NamedDateRange | 'Custom'
		dates: { from: string; to: string }
		show: boolean
	}) {
		try {
			// Honestly not huge on just not showing the subtitle if the language isn't English, but idk how I'm supposed to translate this
			if (!show || i18next.resolvedLanguage !== 'en') {
				return ''
			}

			let dateString = 'invalid date range'
			if (range === 'Always') {
				dateString = 'of all time'
			} else if (range !== 'Custom' && range.includes('Last')) {
				dateString = `in the ${range.toLowerCase()}`
			} else if (range.includes('Previous')) {
				dateString = `sampled ${dateString.replace('Previous', 'last').toLowerCase()}`
			} else if (range !== 'Custom') {
				dateString = `sampled ${range.toLowerCase()}`
			} else if (range === 'Custom' && dates.from && dates.to) {
				dateString = `sampled between ${dates.from} and ${dates.to}`
			} else if (range === 'Custom' && dates.from) {
				dateString = `sampled on or after ${dates.from}`
			} else if (range === 'Custom' && dates.to) {
				dateString = `sampled on or before ${dates.to}`
			}

			return `${analysis?.name ?? 'All analyses'} at ${location?.location ?? 'all locations'} at ${plant?.name ?? 'all plants'} ${dateString}`
		} catch (err) {
			console.warn('Could not compute recalculate samples modal subtitle', err)
			return ''
		}
	}

	//#region GraphQL Queries

	const getLocations = graphql(`
		query RecalculateSamplesModalLocations($filter: LocationFilter) {
			locations(filter: $filter, pagination: { pageSize: 0 }) {
				data {
					id
					plantId
					code
					location
				}
			}
		}
	`)

	const getAnalyses = graphql(`
		query RecalculateSamplesModalAnalyses($filter: AnalysisFilter) {
			analyses(filter: $filter, pagination: { pageSize: 0 }) {
				data {
					...AnalysisReturnData
				}
			}
		}
	`)

	const recalculateSamples = graphql(`
		mutation RecalculateSamples($input: RecalculateSamplesInput!) {
			recalculateSamples(input: $input)
		}
	`)
	// #endregion
</script>

<Modal
	bind:show
	modalSize="xl"
	title={translate('recalculateSamples.title', 'Recalculate Historical Sample Values')}
	{subtitle}
	confirmButtonIsLoading={$recalculateSamples.fetching}
	confirmButtonText="Recalculate"
	confirmButtonIcon="check"
	on:confirm={confirm}
	on:close={() => (show = false)}
>
	<span> {translate('recalculateSamples.instructionsLine1', 'Use the filters below to choose a range of historical samples to recalculate.')} </span>
	<p>{translate('recalculateSamplees.instructionsLine1', 'Note: All values on matching samples will have their acceptability recalcuated based on current thresholds')}</p>
	<div class="form-row">
		<div class="col-12 col-md-2">
			<Select
				label={translate('common:plant', 'Plant')}
				showEmptyOption={hasPermission('ANALYSIS_CAN_RECALCULATE_SAMPLE_HISTORY', null)}
				emptyText={translate('common:allPlants', 'All Plants')}
				disabled={validPlants.length < 2}
				options={validPlants}
				bind:value={selectedPlant}
				on:change={() => {
					selectedLocation = null
					selectedAnalysis = null
				}}
				let:option
			>
				{#if option}
					<option value={option}>{option.code} - {option.name}</option>
				{/if}
			</Select>
		</div>
		<div class="col-12 col-md-2">
			<Select
				label={translate('common:Location', 'Location')}
				emptyText={translate('common:allLocations', 'All Locations')}
				isLoading={locationsLoading}
				options={locations}
				bind:value={selectedLocation}
				let:option
			>
				{#if option}
					<option value={option}>{option.code} - {option.location}</option>
				{/if}
			</Select>
		</div>
		<div class="col-12 col-md-2">
			<Select
				label={translate('common:analysis', 'Analysis')}
				showEmptyOption={true}
				emptyText={translate('common:allAnalyses', 'All Analyses')}
				isLoading={analysesLoading}
				options={analyses}
				bind:value={selectedAnalysis}
				let:option
			>
				{#if option}
					<option value={option}>{option.name}</option>
				{/if}
			</Select>
		</div>
		<div class="col-12 col-md-6">
			<DateRange
				allowNone
				colClass="col-12 col-md-4"
				fromLabel={translate('recalculateSamples.fromLabel', 'Sampled On or After')}
				toLabel={translate('recalculateSamples.toLabel', 'Sampled On or Before')}
				rangeLabel={translate('recalculateSamples.rangeLabel', 'Sampled Range')}
				bind:dates
				bind:range
			/>
		</div>
	</div>
	{#if error}
		<div class="alert alert-danger">
			<h4 class="alert-heading">{translate('recalculateSamples.errorHeading', 'Error Recalculating Historical Sample Values')}</h4>
			<p>{error}</p>
		</div>
	{/if}

	{#if showDirtyWarning}
		<div class="w-100 text-center">
			<span class="text-info">{translate('recalculateSamples.unsavedWarning', 'Unsaved changes will not affect recalculation of historical sample values.')}</span>
		</div>
	{/if}
</Modal>
