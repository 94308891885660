import { graphql } from '$houdini'

export const plantsQuery = graphql(`
	query PlantsForDropdown($pagination: PaginatedInput) {
		plants(pagination: $pagination) {
			data {
				id
				name
				code
			}
		}
	}
`)
