export default {
    "name": "getCityStateAbbvCountryFromZip",
    "kind": "HoudiniQuery",
    "hash": "dad760141db2bad841a51039ce0562a5e7c6c587f0368bb668969c996866a7a8",

    "raw": `query getCityStateAbbvCountryFromZip($zipcode: String!) {
  getCityStateAbbvCountryFromZip(zipcode: $zipcode) {
    city
    country
    stateAbbreviation
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "getCityStateAbbvCountryFromZip": {
                "type": "ZipLocation",
                "keyRaw": "getCityStateAbbvCountryFromZip(zipcode: $zipcode)",
                "nullable": true,

                "selection": {
                    "fields": {
                        "city": {
                            "type": "NonEmptyString",
                            "keyRaw": "city",
                            "nullable": true,
                            "visible": true
                        },

                        "country": {
                            "type": "NonEmptyString",
                            "keyRaw": "country",
                            "nullable": true,
                            "visible": true
                        },

                        "stateAbbreviation": {
                            "type": "NonEmptyString",
                            "keyRaw": "stateAbbreviation",
                            "nullable": true,
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "zipcode": "String"
        },

        "types": {},
        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=ad959fcdff26d6839e3cdcecc740fce8bc225eb6bb07627b4dc6079eb0267261";