import { QueryStore } from '../runtime/stores/query'
import artifact from '$houdini/artifacts/PlantsForDropdown'
import { initClient } from '$houdini/plugins/houdini-svelte/runtime/client'

export class PlantsForDropdownStore extends QueryStore {
	constructor() {
		super({
			artifact,
			storeName: "PlantsForDropdownStore",
			variables: false,
		})
	}
}

export async function load_PlantsForDropdown(params) {
	await initClient()

	const store = new PlantsForDropdownStore()

	await store.fetch(params)

	return {
		PlantsForDropdown: store,
	}
}
