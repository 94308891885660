<script lang="ts">
	import type { DisplaySavedSearch, SavedSearch, SavedSearchState, ScopeObject } from './sampling-history'
	import type { i18n } from 'i18next'

	import Modal from '@isoftdata/svelte-modal'
	import TextArea from '@isoftdata/svelte-textarea'
	import Select from '@isoftdata/svelte-select'
	import Button from '@isoftdata/svelte-button'
	import Input from '@isoftdata/svelte-input'
	import Icon from '@isoftdata/svelte-icon'

	import { getContext, tick } from 'svelte'
	import session from 'stores/session'
	import { graphql, type CreateSavedSampleSearchInput, type SampleSearchStateInput } from '$houdini'

	type Props = {
		displaySavedSearchList: Array<DisplaySavedSearch>
		shareableScope: Map<'USER' | 'SITE' | 'GLOBAL', ScopeObject>
		confirm(search: SavedSearch): void
	}

	let { confirm: confirmSave, displaySavedSearchList, shareableScope }: Props = $props()

	let show = $state(false)
	let action = $state<'ADD' | 'EDIT' | 'REPLACE'>('ADD')
	let searchState: SavedSearchState | null = $state(null)
	let description: string | null = $state(null)
	let searchId: number | null = $state(null)
	let scope: 'USER' | 'SITE' | 'GLOBAL' = $state('USER')
	let searchName: string = $state('')
	let saveError: string | null = $state(null)
	let isSaving = $state(false)
	let isGeneratingDescription = $state(false)

	let nameInput: HTMLInputElement | undefined = $state(undefined)
	let searchSelect: HTMLSelectElement | undefined = $state(undefined)

	const title = $derived.by(() => {
		if (action === 'ADD') {
			return translate('samplingHistory.savedSearches.modal.title.create', 'Create New Saved Search')
		} else if (action === 'EDIT') {
			return translate('samplingHistory.savedSearches.modal.title.edit', 'Edit Saved Search')
		} else if (action === 'REPLACE') {
			return translate('samplingHistory.savedSearches.modal.title.replace', 'Replace Existing Saved Search')
		}
	})
	const replacingSearch = $derived(action === 'REPLACE')
	const displayEditableSavedSearchList: Array<DisplaySavedSearch> = $derived(displaySavedSearchList.filter(savedSearch => savedSearch.createdByUser.id === $session.userAccountId))

	const { t: translate } = getContext<i18n>('i18next')

	type OpenOptions = {
		action: 'ADD' | 'EDIT' | 'REPLACE'
		/** Editing an existing saved search doesn't require the search state*/
		search: SavedSearchState | null
		searchId?: number | null
		description?: string
		scope?: 'USER' | 'SITE' | 'GLOBAL'
		searchName?: string
	}

	export async function open(args: OpenOptions) {
		show = true
		action = args.action
		searchState = args.search
		description = args.description ?? null
		searchId = args.searchId ?? null
		scope = args.scope ?? 'USER'
		searchName = args.searchName ?? ''
		await tick()
		if (!searchId && action === 'REPLACE') {
			searchSelect?.focus()
		} else {
			nameInput?.focus()
		}
	}

	export async function getGeneratedDescription(inputSearchState: SavedSearchState) {
		const res = await getGeneratedDescriptionQuery.fetch({
			variables: {
				searchState: toSearchStateInput(inputSearchState),
			},
		})
		return res.data?.generateSampleSearchDescription ?? ''
	}

	// TODO update the SavedSearchState type to be match this type better
	function toSearchStateInput(inputSearchState: SavedSearchState): SampleSearchStateInput {
		return {
			...inputSearchState,
			analyses: inputSearchState.analyses?.map(analysis => ({
				...analysis,
				options: analysis.options.map(option => ({
					...option,
					expirationFrom: option.expirationFrom?.toString() ?? null,
					expirationTo: option.expirationTo?.toString() ?? null,
				})),
			})),
			workOrderTypeId: inputSearchState.workOrderTypeId ? parseInt(inputSearchState.workOrderTypeId) : inputSearchState.workOrderTypeId === undefined ? undefined : null,
		}
	}

	async function useGeneratedDescription(savedSearch: { id: number; description?: string | null } | SavedSearchState) {
		if (
			!('description' in savedSearch) ||
			!savedSearch?.description?.trim?.() ||
			confirm(translate('samplingHistory.savedSearches.useGeneratedDescriptionConfirmation', 'Are you sure you want to replace your existing search description with a generated one?'))
		) {
			isGeneratingDescription = true
			// If true, we're dealing with DisplaySavedSearch and have to look it up by ID
			try {
				if ('id' in savedSearch && savedSearch.id) {
					const { data } = await useGeneratedDescriptionQuery.fetch({
						variables: {
							savedSampleSearchId: savedSearch.id.toString(),
						},
					})
					description = data?.savedSampleSearch?.generatedDescription ?? ''
				} else if (!('id' in savedSearch)) {
					// we have SavedSearchState, we can just generate the description
					description = await getGeneratedDescription(savedSearch)
				}
			} catch (err: any) {
				saveError = err?.message ?? translate('samplingHistory.savedSearch.errorGeneratingDescription', 'Unknown error generating search description')
			} finally {
				isGeneratingDescription = false
			}
		}
	}

	function reset() {
		show = false
		action = 'ADD'
		searchState = null
		description = null
		searchId = null
		scope = 'USER'
		searchName = ''
	}

	async function confirmSaveSearch(event?: SubmitEvent) {
		event?.preventDefault()

		const searchSave: Omit<CreateSavedSampleSearchInput, 'search'> = {
			name: searchName,
			scope: scope,
			description: description,
		}

		// We won't have the search state if we're editing an existing saved search
		const search = searchState ? toSearchStateInput(searchState) : undefined
		isSaving = true
		try {
			if (action === 'ADD' && search) {
				const { data } = await createSavedSearchMutation.mutate({ input: { ...searchSave, search } })
				if (!data) {
					throw new Error('No data returned from createSavedSearchMutation')
				}

				confirmSave(data.createSavedSampleSearch)
			} else if (searchId && ['EDIT', 'REPLACE'].includes(action)) {
				const { data } = await updateSavedSearchMutation.mutate({
					input: {
						id: searchId,
						search,
						...searchSave,
					},
				})

				if (!data) {
					throw new Error('No data returned from updateSavedSearchMutation')
				}
				confirmSave(data.updateSavedSampleSearch)
			} else {
				console.warn('Did not save search, invalid action or searchId', action, searchId)
			}

			reset()
		} catch (err: any) {
			saveError = err?.message || 'An unknown error occured. Please contact Presage support.'
		} finally {
			isSaving = false
		}
	}

	const updateSavedSearchMutation = graphql(`
		mutation UpdateSavedSampleSearch($input: UpdateSavedSampleSearchInput!) {
			updateSavedSampleSearch(input: $input) {
				...SavedSearchFields
			}
		}
	`)

	const createSavedSearchMutation = graphql(`
		mutation CreateSavedSampleSearch($input: CreateSavedSampleSearchInput!) {
			createSavedSampleSearch(input: $input) {
				...SavedSearchFields
			}
		}
	`)

	const getGeneratedDescriptionQuery = graphql(`
		query ShGetGeneratedDescription($searchState: SampleSearchStateInput!) {
			generateSampleSearchDescription(searchState: $searchState)
		}
	`)

	const useGeneratedDescriptionQuery = graphql(`
		query ShGetSearchDescription($savedSampleSearchId: ID!) {
			savedSampleSearch(id: $savedSampleSearchId) {
				generatedDescription
			}
		}
	`)
</script>

<Modal
	bind:show
	{title}
	confirmButtonText={translate('common:save', 'Save')}
	cancelButtonText={translate('common:cancel', 'Cancel')}
	confirmButtonIcon="check"
	confirmButtonDisabled={!searchName || (replacingSearch && !searchId)}
	confirmButtonType="submit"
	confirmButtonFormId="saveSearchFormId"
	confirmButtonIsLoading={isSaving}
	on:close={reset}
>
	<form
		id="saveSearchFormId"
		onsubmit={confirmSaveSearch}
	>
		{#if replacingSearch}
			<Select
				id="replaceSavedSearchSelect"
				label={translate('samplingHistory.savedSearches.modal.replaceSavedSearch', 'Replace Saved Search')}
				emptyText="-- {translate('samplingHistory.savedSearches.modal.selectASavedSearchToReplace', 'Select a Saved Search to Replace')} --"
				class="mb-3"
				bind:select={searchSelect}
				bind:value={searchId}
				on:change={() => {
					// swap all the stuff to the new search
					const newSearch = displayEditableSavedSearchList.find(search => search.id === searchId)
					if (newSearch) {
						// Keep the searchState but update the meta fields, since we'll be replacing that search's state
						searchId = newSearch.id
						description = newSearch.description
						scope = newSearch.scope
						searchName = newSearch.name
					}
				}}
			>
				{#each displayEditableSavedSearchList as { id, name }}
					<option value={id}>{name}</option>
				{/each}
			</Select>
			<hr />
		{/if}
		<Input
			id="savedSearchNameInput"
			label={translate('common:name', 'Name')}
			class="mb-3"
			disabled={replacingSearch && !searchId}
			bind:value={searchName}
			bind:input={nameInput}
		/>
		<TextArea
			label={translate('common:description', 'Description')}
			rows="6"
			disabled={replacingSearch && !searchId}
			bind:value={description}
		/>
		<div class="d-block mb-3">
			<Button
				outline
				size="xs"
				iconClass="computer-classic"
				isLoading={isGeneratingDescription}
				disabled={(replacingSearch && !searchId) || (!searchState && !searchId)}
				on:click={() => {
					if (searchState) {
						useGeneratedDescription(searchState)
					} else if (searchId) {
						useGeneratedDescription({ id: searchId, description })
					}
				}}
			>
				{translate('samplingHistory.savedSearches.modal.useGeneratedDescription', 'Use Generated Description')}
			</Button>
		</div>
		<label
			class="col-form-label"
			for="shareWithSelect">{translate('samplingHistory.savedSearches.modal.shareWith', 'Share With')}</label
		>
		<Select
			id="shareWithSelect"
			style="border-top-right-radius: 0.2rem; border-bottom-right-radius: 0.2rem;"
			showLabel={false}
			labelParentDivClass="input-group input-group-sm"
			showEmptyOption={false}
			disabled={replacingSearch && !searchId}
			options={Array.from(shareableScope.values())}
			bind:value={scope}
			let:option
		>
			<svelte:fragment slot="prepend">
				<span class="input-group-text text-primary">
					<Icon
						fixedWidth
						icon={shareableScope.get(scope)?.icon}
					></Icon>
				</span>
			</svelte:fragment>
			{#if option}
				<option value={option.key}>{option.display}</option>
			{/if}
		</Select>
		{#if saveError}
			<div class="alert alert-danger mt-3">
				{saveError}
			</div>
		{/if}
	</form>
</Modal>
