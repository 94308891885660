import component from './Dashboard.svelte'
import { getSession } from 'stores/session'

export default ({ stateRouter }) => {
	stateRouter.addState({
		name: 'app.home.dashboard',
		route: 'dashboard',
		template: {
			svelte: true,
			component,
		},
		resolve(_data, _parameters) {
			const session = getSession()
			return Promise.resolve({ authToken: session.authToken })
		},
	})
}
