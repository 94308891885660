export default {
    "name": "SamplingHistorySettings",
    "kind": "HoudiniQuery",
    "hash": "1305784b7c3bb492b403594274bfea0653dff5b55fb773eb15521bb4e7ea6fc8",

    "raw": `query SamplingHistorySettings {
  session {
    user {
      optionsAndValues: getUserSetting(
        lookup: {category: "Sampling", name: "Show analysis options and values in sample detail", settingType: INTERFACE_HISTORY, defaultValue: "True"}
      ) {
        value
      }
      collectionInformation: getUserSetting(
        lookup: {category: "Sampling", name: "Show collection information in sample detail", settingType: INTERFACE_HISTORY, defaultValue: "True"}
      ) {
        value
      }
      workOrderInformation: getUserSetting(
        lookup: {category: "Sampling", name: "Show work order information in sample detail", settingType: INTERFACE_HISTORY, defaultValue: "True"}
      ) {
        value
      }
      testingInformation: getUserSetting(
        lookup: {category: "Sampling", name: "Show testing information in sample detail", settingType: INTERFACE_HISTORY, defaultValue: "True"}
      ) {
        value
      }
      showImageThumbnails: getUserSetting(
        lookup: {category: "Sampling", name: "Show image thumbnails in sample detail", settingType: INTERFACE_HISTORY, defaultValue: "True"}
      ) {
        value
      }
      showOnlyApplicableThresholds: getUserSetting(
        lookup: {category: "Work Orders", name: "Test Thresholds: Show only applicable thresholds", defaultValue: "True", settingType: INTERFACE_HISTORY}
      ) {
        value
      }
      id
    }
  }
  allowShowThresholdsTable: getGlobalSetting(
    lookup: {category: "Scanner", name: "showthresholds", settingType: INTERFACE_PREFERENCE, defaultValue: "False"}
  ) {
    value
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "session": {
                "type": "Session",
                "keyRaw": "session",
                "nullable": true,

                "selection": {
                    "fields": {
                        "user": {
                            "type": "UserAccount",
                            "keyRaw": "user",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "optionsAndValues": {
                                        "type": "SettingResult",
                                        "keyRaw": "optionsAndValues(lookup: {category: \"Sampling\", name: \"Show analysis options and values in sample detail\", settingType: INTERFACE_HISTORY, defaultValue: \"True\"})",

                                        "selection": {
                                            "fields": {
                                                "value": {
                                                    "type": "String",
                                                    "keyRaw": "value",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "collectionInformation": {
                                        "type": "SettingResult",
                                        "keyRaw": "collectionInformation(lookup: {category: \"Sampling\", name: \"Show collection information in sample detail\", settingType: INTERFACE_HISTORY, defaultValue: \"True\"})",

                                        "selection": {
                                            "fields": {
                                                "value": {
                                                    "type": "String",
                                                    "keyRaw": "value",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "workOrderInformation": {
                                        "type": "SettingResult",
                                        "keyRaw": "workOrderInformation(lookup: {category: \"Sampling\", name: \"Show work order information in sample detail\", settingType: INTERFACE_HISTORY, defaultValue: \"True\"})",

                                        "selection": {
                                            "fields": {
                                                "value": {
                                                    "type": "String",
                                                    "keyRaw": "value",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "testingInformation": {
                                        "type": "SettingResult",
                                        "keyRaw": "testingInformation(lookup: {category: \"Sampling\", name: \"Show testing information in sample detail\", settingType: INTERFACE_HISTORY, defaultValue: \"True\"})",

                                        "selection": {
                                            "fields": {
                                                "value": {
                                                    "type": "String",
                                                    "keyRaw": "value",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "showImageThumbnails": {
                                        "type": "SettingResult",
                                        "keyRaw": "showImageThumbnails(lookup: {category: \"Sampling\", name: \"Show image thumbnails in sample detail\", settingType: INTERFACE_HISTORY, defaultValue: \"True\"})",

                                        "selection": {
                                            "fields": {
                                                "value": {
                                                    "type": "String",
                                                    "keyRaw": "value",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "showOnlyApplicableThresholds": {
                                        "type": "SettingResult",
                                        "keyRaw": "showOnlyApplicableThresholds(lookup: {category: \"Work Orders\", name: \"Test Thresholds: Show only applicable thresholds\", defaultValue: \"True\", settingType: INTERFACE_HISTORY})",

                                        "selection": {
                                            "fields": {
                                                "value": {
                                                    "type": "String",
                                                    "keyRaw": "value",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "allowShowThresholdsTable": {
                "type": "SettingResult",
                "keyRaw": "allowShowThresholdsTable(lookup: {category: \"Scanner\", name: \"showthresholds\", settingType: INTERFACE_PREFERENCE, defaultValue: \"False\"})",

                "selection": {
                    "fields": {
                        "value": {
                            "type": "String",
                            "keyRaw": "value",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=8d25eb163ab0f690cfbaf99bbbfc8b73880ff3cee1a8b8b1c5142beafb2ef9a5";