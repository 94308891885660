import type { AppContext, ResolveParameters } from 'types/common'

import { graphql } from '$houdini'
import component from './Reports.svelte'
import showErrorAndRedirect from 'utility/show-error-and-redirect'
import loadReports from 'utility/report-viewer-helper'
import makeFavoriteReportsStore from 'stores/favorite-reports'

type StateParameters = {
	selectedReportId: number | null
	selectedCategory: string | null
}

const settingsQuery = graphql(`
	query ReportSettings {
		session {
			user {
				favoriteReports: getUserSetting(lookup: { category: "Report Viewer", settingType: INTERFACE_HISTORY, name: "Favorite reports", defaultValue: "" }) {
					value
				}
			}
		}
	}
`)

export default function ({ mediator, stateRouter, i18next: { t: translate } }: AppContext) {
	stateRouter.addState({
		name: 'app.report-viewer.reports',
		route: 'reports',
		defaultChild: 'preview',
		template: {
			svelte: true,
			component,
		},
		async resolve(_data, stateParameters: ResolveParameters<StateParameters>) {
			const selectedReportId = parseInt(stateParameters.selectedReportId, 10) || null

			const [reportList, settings] = await Promise.all([loadReports({ filter: { userVisible: true }, pagination: { pageNumber: 1, pageSize: 0 } }, translate), settingsQuery.fetch()])

			if (!reportList.length) {
				throw showErrorAndRedirect(
					mediator,
					translate('reportViewer.failedToLoadHeading', 'Failed to load Report Viewer'),
					translate('reportViewer.failedToLoadMessage', 'No reports returned from server'),
					{ name: 'app' }
				)
			}

			return {
				reportList,
				selectedReportId,
				selectedCategory: stateParameters.selectedCategory,
				favorites: makeFavoriteReportsStore(settings?.data?.session?.user?.favoriteReports.value ?? ''),
			}
		},
	})
}
