export default {
    "name": "SavedSearchFields",
    "kind": "HoudiniFragment",
    "hash": "fda9321d386660c23b02981da053809e1230a758a873ea248d3f6740f0f3d6d4",

    "raw": `fragment SavedSearchFields on SavedSampleSearch {
  id
  createdOn
  name
  description
  scope
  plant {
    id
    code
  }
  createdByUser {
    id
    fullName
  }
  __typename
}
`,

    "rootType": "SavedSampleSearch",
    "stripVariables": [],

    "selection": {
        "fields": {
            "id": {
                "type": "PositiveInt",
                "keyRaw": "id",
                "visible": true
            },

            "createdOn": {
                "type": "DateTime",
                "keyRaw": "createdOn",
                "visible": true
            },

            "name": {
                "type": "String",
                "keyRaw": "name",
                "visible": true
            },

            "description": {
                "type": "String",
                "keyRaw": "description",
                "nullable": true,
                "visible": true
            },

            "scope": {
                "type": "SeachScope",
                "keyRaw": "scope",
                "visible": true
            },

            "plant": {
                "type": "Plant",
                "keyRaw": "plant",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "code": {
                            "type": "String",
                            "keyRaw": "code",
                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "createdByUser": {
                "type": "UserAccount",
                "keyRaw": "createdByUser",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "fullName": {
                            "type": "NonEmptyString",
                            "keyRaw": "fullName",
                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "__typename": {
                "type": "String",
                "keyRaw": "__typename",
                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    }
};

"HoudiniHash=7c76e842c87fe490b34c462080a6d8a70dfd0693a33361d6c0705259a1722bd6";