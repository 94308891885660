<script lang="ts">
	import type { OutcomeOrNone$options, ValueType$options } from '$houdini'
	import type { DisplaySample, Sample, WorkOrder } from '../states/work-order/work-order'
	import type { AnalysisOptionChoice, DocumentVersion } from 'client/states/work-order/edit/edit'
	import type { Mediator, i18n } from 'types/common'
	import type { GroupedEntityLoader } from 'utility/entity-proxy-map'
	import type { Merge } from 'type-fest'

	type SampleValue = Merge<
		Pick<Sample['sampleValues'][number], 'id' | 'result' | 'resultStatus' | 'filledOut' | 'lastModified' | 'analysisOption' | 'filledOut' | 'lastModified'>,
		{ analysisOption: Merge<Sample['sampleValues'][number]['analysisOption'], { ' $fragments'?: unknown }> }
	>

	import { v4 as uuid } from '@lukeed/uuid'
	import { stringToBoolean } from '@isoftdata/utility-string'
	import { graphql } from '$houdini'

	import valueAcceptabilityMap, { acceptabilityToResultStatus } from 'utility/value-acceptability-map'
	import { slide } from 'svelte/transition'
	import financialNumber from 'financial-number'
	import { createEventDispatcher, getContext, tick } from 'svelte'

	import Label from '@isoftdata/svelte-label'
	import Input from '@isoftdata/svelte-input'
	import Button from '@isoftdata/svelte-button'
	import Icon from '@isoftdata/svelte-icon'
	import Select from '@isoftdata/svelte-select'
	import ThresholdTable from './ThresholdTable.svelte'
	import Popover from '@isoftdata/svelte-popover'
	import Checkbox from '@isoftdata/svelte-checkbox'

	interface Props {
		workOrder: { plant: { id: number } | null; productBatch: { id: number } | null }
		sample:
			| Sample
			| DisplaySample
			| {
					plant: { id: number } | null
					location: { severityClass: { id: number } | null } | null
					product: { id: number } | null
					status: string
					sampleValues?: Array<SampleValue>
			  }
		// even though technically this should always be defined, I'm getting some weird errors about it being undefined sometimes, probably due to a race condition - it might get an undefined SV before it can un-render this component
		sampleValue: SampleValue | null | undefined
		id?: string
		labelType?: 'COMPACT' | 'NORMAL'
		disabled?: boolean
		showModifiedIcons?: boolean
		showLabel?: boolean
		restriction?: OutcomeOrNone$options
		/** This should match the global setting Scanner: showthresholds*/
		allowShowThresholdsTable?: boolean
		showOnlyApplicableThresholds?: boolean
		required?: false | 'PERFORM' | 'CLOSE'
		document?: DocumentVersion | null
		choicesLoader?: GroupedEntityLoader<AnalysisOptionChoice> | undefined
		readonly?: boolean
	}

	let {
		workOrder,
		sample = $bindable(),
		sampleValue = $bindable(),
		id = uuid(),
		labelType = 'COMPACT',
		disabled = false,
		showModifiedIcons = true,
		showLabel = true,
		restriction = 'NONE',
		allowShowThresholdsTable = false,
		showOnlyApplicableThresholds = $bindable(true),
		required = false,
		document = null,
		choicesLoader = undefined,
		readonly = false,
	}: Props = $props()
	let isLoading = $state(false)
	let thresholdTable: ThresholdTable | undefined = $state(undefined)
	let showThresholdTable = $state(false)
	let showDocument = $state(false)

	const dispatch = createEventDispatcher<{
		change: { value: string }
	}>()

	const { t: translate } = getContext<i18n>('i18next')

	const mediator = getContext<Mediator>('mediator')
	// Mot so small that it's unreadable, but leaves enough room for the edited/document icons on the right of the inputs
	const MIN_INPUT_WIDTH_PX = 40

	// We only care about the choices if the valueType === 'CHOICE', and only for displaying them in a dropdown, so load them just-in-time
	let choices: Array<AnalysisOptionChoice> = $state([])

	function validator<T>(value: string | T) {
		return !!value ? true : showLabel ? `${requiredTooltip}` : ''
	}

	function makeOptions(choices: Array<AnalysisOptionChoice>, result: string) {
		const options = choices?.filter(shouldShowChoice).map(choice => choice.choice) ?? []
		if (result && !options.includes(result)) {
			options.push(result)
		}
		return options
	}

	function shouldShowChoice(choice: AnalysisOptionChoice) {
		// Plant must match if present (null plantId = global)
		if (choice.plantId && choice.plantId !== sample.plant?.id) {
			return false
		}
		// If no location, only show choices with no SC or default SC
		if (!sample.location && choice.severityClass && !choice.severityClass.default) {
			return false
		}
		// If location, only show choices with no SC or matching SC
		if (sample.location && choice.severityClass?.id && choice.severityClass?.id !== sample.location.severityClass?.id) {
			return false
		}
		// Product must match if present
		if (choice.productId && choice.productId !== sample.product?.id) {
			return false
		}
		// Product batch must match if present
		if (choice.productBatchId && choice.productBatchId !== workOrder.productBatch?.id) {
			return false
		}
		// Required Option/Choice/Constraint must match if present
		if (choice.requiredAnalysisOptionId && choice.requiredChoice && choice.requiredConstraint) {
			const requiredOptionValue = sample.sampleValues?.find(sv => sv && sv.analysisOption.id === choice.requiredAnalysisOptionId)
			let result = requiredOptionValue?.result?.toString() ?? ''
			if (
				(choice.requiredConstraint === 'MAXIMUM' && !(result < choice.requiredChoice)) ||
				(choice.requiredConstraint === 'MINIMUM' && !(result > choice.requiredChoice)) ||
				// Seems like the desktop treats absent values as '0' or '' when testing equality so we need to check for both
				(choice.requiredConstraint === 'NOT_EQUAL' && (result === choice.requiredChoice || (result || '0') === choice.requiredChoice)) ||
				(choice.requiredConstraint === 'NONE' && result !== choice.requiredChoice && (result || '0') !== choice.requiredChoice)
			) {
				return false
			}
		}
		return true
	}

	async function onAcceptabilityClick() {
		showThresholdTable = !showThresholdTable && allowShowThresholdsTable
		await getThresholdTableData()
	}

	function onDocumentClick() {
		showDocument = !showDocument && !!document
	}

	async function getThresholdTableData() {
		await tick() // tick so data & UI are up to date
		if (showThresholdTable && sampleValue) {
			await thresholdTable?.loadData?.({
				analysisOptionId: sampleValue.analysisOption.id,
				currentResult: sampleValue.result,
				onlyApplicable: showOnlyApplicableThresholds,
				productBatchId: workOrder.productBatch?.id,
				productId: sample.product?.id,
				severityClassId: sample.location?.severityClass?.id,
				plantId: workOrder.plant?.id,
			})
		}
	}

	async function onValueChange(value: string) {
		if (!sampleValue) {
			return
		}
		isLoading = true
		try {
			// WO screen will update acceptability for all SVs, but we still need to fetch it for the table here.
			const [{ data }] = await Promise.all([
				getValueAcceptabilityQuery.fetch({
					variables: {
						currentResult: (value ?? '').toString(),
						productBatchId: workOrder.productBatch?.id,
						analysisOptionId: sampleValue.analysisOption.id,
						productId: sample.product?.id,
						plantId: workOrder.plant?.id,
						severityClassId: sample.location?.severityClass?.id,
					},
				}),
				await getThresholdTableData(),
				tick(),
			])
			if (data) {
				sampleValue.resultStatus = acceptabilityToResultStatus[data.getValueAcceptability]
				acceptabilityObject = valueAcceptabilityMap.get(sampleValue.resultStatus)!
			}
		} catch (err) {
			mediator.call('showMessage', {
				heading: translate('workOrder.errorFetchingAcceptabilityHeading', 'Error Fetching Acceptability'),
				message: (err as Error).message,
				type: 'danger',
				time: false,
			})
		}

		await tick()
		if (sample.status === 'OPEN') {
			sample.status = 'SAMPLED'
		}
		// If it's saved, update the lastModified date so they see the pencil. This isn't sent to the API, it just updates the UI
		if (sampleValue.id && sampleValue.filledOut && sampleValue.lastModified && value !== sampleValue.result) {
			sampleValue.lastModified = new Date().toISOString()
		}
		// appparently setting isLoading before triggering the event is load bearing, or else the spinner gets stuck?
		isLoading = false
		dispatch('change', { value })
	}

	function getInputType(valueType: ValueType$options): 'text' /* | 'select' */ | 'number' | 'date' | 'time' | 'datetime-local' {
		switch (valueType) {
			case 'TEXT':
				return 'text'
			case 'NUMBER':
			case 'INTEGER':
			case 'CURRENCY':
				return 'number'
			case 'DATE':
				return 'date'
			case 'TIME':
				return 'time'
			case 'DATETIME':
				return 'datetime-local'
			default:
				return 'text'
		}
	}

	const getValueAcceptabilityQuery = graphql(`
		query getValueAcceptability($currentResult: String!, $productBatchId: PositiveInt, $analysisOptionId: PositiveInt!, $productId: PositiveInt, $severityClassId: PositiveInt, $plantId: PositiveInt) {
			getValueAcceptability(
				currentResult: $currentResult
				productBatchId: $productBatchId
				analysisOptionId: $analysisOptionId
				productId: $productId
				severityClassId: $severityClassId
				plantId: $plantId
			)
		}
	`)

	$effect(() => {
		if (choicesLoader && sampleValue?.analysisOption.valueType === 'CHOICE') {
			Promise.resolve(choicesLoader(sampleValue.analysisOption.id) ?? []).then(value => (choices = value))
		}
	})
	// selects can't be readonly, so just make them inputs instead.
	let valueType = $derived(sampleValue?.analysisOption.valueType === 'CHOICE' && readonly ? 'TEXT' : (sampleValue?.analysisOption.valueType ?? 'TEXT'))
	let edited = $derived(showModifiedIcons && !!sampleValue?.filledOut && sampleValue?.filledOut !== sampleValue?.lastModified)
	let acceptabilityObject = $derived(valueAcceptabilityMap.get(sampleValue?.resultStatus ?? 'NOT_CALCULATED')!)
	let { icon: iconClass, colorClass: color, label } = $derived(acceptabilityObject)
	let icon = $derived(
		edited && iconClass
			? {
					prefix: 'fak' as const,
					class: `fa-lg fa-solid-${iconClass}-pen`,
				}
			: {
					class: 'fa-lg',
					prefix: 'fas' as const,
					icon: edited ? ('pen' as const) : iconClass,
				},
	)
	let inputType = $derived(getInputType(valueType))
	let labelClass = $derived(labelType === 'COMPACT' ? 'badge px-0' : '')
	let optionLabel = $derived(sampleValue?.analysisOption.unit ? `${sampleValue.analysisOption.option} (${sampleValue.analysisOption.unit})` : (sampleValue?.analysisOption.option ?? ''))
	let isDisabled = $derived(disabled || restriction === 'HIDDEN' || restriction === 'INACTIVE')
	let acceptabilityButtonDisabled = $derived(isDisabled || (!allowShowThresholdsTable && !edited))
	let isRequired = $derived(required === 'PERFORM' || required === 'CLOSE' || restriction === 'REQUIRED_TO_CLOSE' || restriction === 'REQUIRED_TO_PERFORM')
	// for some reason isReadonly = false still makes the input readonly
	let isReadonly = $derived(readonly || restriction === 'READONLY' ? true : undefined)
	let boolIsValid = $derived(valueType === 'BOOLEAN' && (!isRequired || sampleValue?.result === 'True' || sampleValue?.result === 'False'))
	let requiredTooltip = $derived(
		required === 'PERFORM' || restriction === 'REQUIRED_TO_PERFORM' ? translate('workOrder.requiredToPerform', 'Required to Perform') : translate('workOrder.requiredToClose', 'Required to Close'),
	)
	let formattedLastModified = $derived(sampleValue?.lastModified ? new Date(sampleValue.lastModified).toLocaleString() : '')
	let formattedFilledOut = $derived(sampleValue?.filledOut ? new Date(sampleValue.filledOut).toLocaleString() : '')
</script>

{#if document && showDocument}
	<div
		class="border bg-white mb-1"
		style="min-width: 300px;"
		transition:slide={{ duration: 100 }}
	>
		<object
			class="w-100 h-100"
			title={document.file.name}
			data={document.file.path}
			type={document.file.mimeType}
		></object>
	</div>
{/if}
{#if !sampleValue}
	<!-- todo? -->
	<i class="text-muted">N/A</i>
{:else if restriction === 'INACTIVE'}
	<Input
		disabled={isDisabled}
		{showLabel}
		{labelClass}
		label={optionLabel}
		title={translate('workOrder.inactiveOptionTitle', 'This option is inactive')}
		value={sampleValue.result}
	>
		<svelte:fragment slot="prepend">
			<Button
				disabled
				style="min-width: 35.5px;"
			></Button>
		</svelte:fragment>
	</Input>
{:else if valueType == 'CHOICE'}
	<Select
		disabled={isDisabled}
		readonly={isReadonly}
		{showLabel}
		label={optionLabel}
		title={optionLabel}
		showAppend={edited || !!document}
		required={isRequired}
		{labelClass}
		emptyValue=""
		validation={{ validator, value: sampleValue.result }}
		style="min-width: {MIN_INPUT_WIDTH_PX}px;"
		labelParentClass="flex-nowrap-hack"
		options={makeOptions(choices, sampleValue.result)}
		bind:value={sampleValue.result}
		on:change={() => onValueChange(sampleValue.result)}
	>
		<svelte:fragment slot="prepend">
			{@render prepends()}
		</svelte:fragment>
		<svelte:fragment slot="append">
			{@render appends()}
		</svelte:fragment>
	</Select>
{:else if valueType === 'BOOLEAN'}
	<Label
		controlFor={id}
		label={optionLabel}
		{labelClass}
		required={isRequired}
		hint={isRequired && !sampleValue.result ? requiredTooltip : undefined}
		hintClass="text-danger font-60"
		{showLabel}
	>
		<div
			{id}
			class="btn-group btn-group-toggle w-100"
			data-toggle="buttons"
		>
			{@render prepends()}
			<label
				class="btn btn-sm cursor-pointer text-truncate"
				class:btn-secondary={stringToBoolean(sampleValue.result)}
				class:btn-outline-secondary={boolIsValid && !stringToBoolean(sampleValue.result)}
				class:btn-outline-danger={!boolIsValid}
				class:active={stringToBoolean(sampleValue.result)}
				style:font-weight={boolIsValid ? 'normal' : 'bold'}
				class:disabled={isDisabled || isReadonly}
				style:cursor={isDisabled || isReadonly ? 'unset' : 'pointer'}
				style:min-width="{MIN_INPUT_WIDTH_PX / 2}px"
			>
				<input
					id="radio-true-{id}"
					type="radio"
					autocomplete="off"
					name="options"
					disabled={isDisabled}
					readonly={isReadonly}
					value={true}
					onclick={() => {
						// Hack because radio buttons can't be readonly?
						if (!isReadonly && !isDisabled) {
							sampleValue.result = sampleValue.result === 'True' ? '' : 'True'
							onValueChange(sampleValue.result)
						}
					}}
				/>
				{translate('workOrder.true', 'True')}
			</label>
			<label
				class="btn btn-sm cursor-pointer text-truncate"
				class:btn-secondary={boolIsValid && !stringToBoolean(sampleValue.result) && sampleValue.result !== ''}
				class:btn-outline-secondary={(boolIsValid && stringToBoolean(sampleValue.result)) || sampleValue.result === ''}
				class:btn-outline-danger={!boolIsValid}
				class:active={!stringToBoolean(sampleValue.result) && sampleValue.result !== ''}
				class:disabled={isDisabled || isReadonly}
				style:font-weight={boolIsValid ? 'normal' : 'bold'}
				style:cursor={isDisabled || isReadonly ? 'unset' : 'pointer'}
				style:min-width="{MIN_INPUT_WIDTH_PX / 2}px"
			>
				<input
					id="radio-false-{id}"
					type="radio"
					autocomplete="off"
					name="options"
					disabled={isDisabled}
					readonly={isReadonly}
					value={false}
					onclick={() => {
						// Hack because radio buttons can't be readonly?
						if (!isReadonly && !isDisabled) {
							sampleValue.result = sampleValue.result === 'False' ? '' : 'False'
							onValueChange(sampleValue.result)
						}
					}}
				/>
				{translate('workOrder.false', 'False')}
			</label>
			{#if !boolIsValid}
				<span
					class="input-group-text text-danger border-danger border-left-0"
					style="border-top-left-radius: 0; border-bottom-left-radius: 0; padding: .25rem .5rem; border-left: none;"
					title={translate('workOrder.missingRequiredBooleanValue', 'Value is required, but is not filled out.')}
				>
					<Icon
						prefix="far"
						icon="circle-exclamation"
					></Icon>
				</span>
			{:else if edited || !!document}
				{@render appends()}
			{/if}
		</div>
	</Label>
{:else}
	<Input
		disabled={isDisabled}
		readonly={isReadonly}
		{showLabel}
		{labelClass}
		label={optionLabel}
		showAppend={edited || !!document}
		required={isRequired}
		type={inputType}
		validation={{ validator, value: sampleValue.result }}
		id="thresholdsButton{id}"
		style="min-width: {MIN_INPUT_WIDTH_PX}px;"
		labelParentClass="flex-nowrap-hack"
		maxlength={100}
		bind:value={sampleValue.result}
		on:change={() => {
			if (sampleValue.analysisOption.valueType === 'INTEGER') {
				const parsed = parseInt(sampleValue.result, 10)
				sampleValue.result = Number.isNaN(parsed) ? '' : parsed.toString()
			} else if (sampleValue.analysisOption.valueType === 'NUMBER') {
				sampleValue.result = financialNumber(sampleValue.result).toString(6)
			} else if (sampleValue.analysisOption.valueType === 'CURRENCY') {
				sampleValue.result = financialNumber(sampleValue.result).toString(2)
			}
			onValueChange(sampleValue.result)
		}}
	>
		<svelte:fragment slot="prepend">
			{@render prepends()}
		</svelte:fragment>
		<svelte:fragment slot="append">
			{@render appends()}
		</svelte:fragment>
	</Input>
{/if}

{#snippet prepends()}
	<Popover
		autoUpdate
		ignoreMaxWidth
		tabindex={-1}
		size="sm"
		class="p-1"
		{color}
		{icon}
		{isLoading}
		colorGreyDisabled={false}
		disabled={acceptabilityButtonDisabled}
		id="thresholdsButton{id}"
		title={translate('workOrder.acceptabilityButtonTitle', 'The acceptability ({{acceptability}}) for this sample. Click to view detailed acceptability information.', {
			acceptability: label,
		})}
		style="min-width: 35.5px; max-width: 35.5px;"
		open={onAcceptabilityClick}
		close={() => (showThresholdTable = false)}
	>
		<svelte:fragment slot="popover">
			<div
				class="popover-body"
				style="max-height: 400px; width: min-content; min-width: 300px;"
			>
				{#if showThresholdTable}
					<Checkbox
						inline
						label={translate('workOrder.onlyShowApplicableThresholds', 'Only show applicable thresholds')}
						bind:checked={showOnlyApplicableThresholds}
						on:change={() => {
							getThresholdTableData()
						}}
					></Checkbox>
					<ThresholdTable bind:this={thresholdTable}></ThresholdTable>
				{/if}
				{#if edited}
					{translate('workOrder.lastModifiedPencilTitle', 'Value was edited on {{- formattedLastModified}}, and was first filled out on {{- formattedFilledOut}}.', {
						formattedLastModified,
						formattedFilledOut,
					})}
				{/if}
			</div>
		</svelte:fragment>
	</Popover>
{/snippet}

{#snippet appends()}
	<!-- {#if edited}
		<span
			class="input-group-text"
			style="min-width: 35.5px; max-width: 35.5px;"
			title={translate('workOrder.lastModifiedPencilTitle', 'Value was edited on {{- formattedLastModified}}, and was first filled out on {{- formattedFilledOut}}.', {
				formattedLastModified,
				formattedFilledOut,
			})}
		>
			<Icon icon="pencil"></Icon>
		</span>
	{/if} -->
	{#if document}
		<Button
			outline
			size="sm"
			tabindex={-1}
			iconClass="info"
			title={translate('workOrder.documentButtonTitle', 'Click to view document')}
			style="min-width: 35.5px; max-width: 35.5px;"
			on:click={onDocumentClick}
		></Button>
	{/if}
{/snippet}

<style>
	/* Temp hack to make font sizes at the very least consistent */
	:global(small.font-60) {
		font-size: 60%;
	}

	:global(.form-group.flex-nowrap-hack .input-group) {
		flex-wrap: nowrap !important;
	}

	/* Fix for radio buttons that are label>btn.btn-secondary>input not showing that they are focused */
	.btn-secondary:focus-within,
	.btn-outline-secondary:focus-within {
		border-color: #545b62;
		box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
	}

	.btn-outline-danger:focus-within {
		border-color: #dc3545;
		box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
	}
</style>
