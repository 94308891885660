import { QueryStore } from '../runtime/stores/query'
import artifact from '$houdini/artifacts/ShProcessZones'
import { initClient } from '$houdini/plugins/houdini-svelte/runtime/client'

export class ShProcessZonesStore extends QueryStore {
	constructor() {
		super({
			artifact,
			storeName: "ShProcessZonesStore",
			variables: false,
		})
	}
}

export async function load_ShProcessZones(params) {
	await initClient()

	const store = new ShProcessZonesStore()

	await store.fetch(params)

	return {
		ShProcessZones: store,
	}
}
