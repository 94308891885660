export default {
    "name": "ShWorkOrderTypes",
    "kind": "HoudiniQuery",
    "hash": "8492cb301c1353d723c7d6e60122c6612026fe7f0b13331274db866b9c9db1bd",

    "raw": `query ShWorkOrderTypes($filter: WorkOrderTypeFilter) {
  workOrderTypes(filter: $filter, pagination: {pageSize: 0}) {
    data {
      id
      name
    }
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "workOrderTypes": {
                "type": "WorkOrderTypeResponse",
                "keyRaw": "workOrderTypes(filter: $filter, pagination: {pageSize: 0})",

                "selection": {
                    "fields": {
                        "data": {
                            "type": "WorkOrderType",
                            "keyRaw": "data",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "filter": "WorkOrderTypeFilter"
        },

        "types": {
            "WorkOrderTypeFilter": {
                "id": "PositiveInt",
                "name": "NonEmptyString",
                "plantIds": "PositiveInt"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=71547a37716aeb5b7b0133fdcf509b9f49b8cfe1d5797239f4278a4564f22af6";