import type { SetUserSetting$input } from '$houdini'
import { graphql } from '$houdini'

export const setUserSettingMutation = graphql(`
	mutation SetUserSetting($value: SettingChange!) {
		setUserSetting(value: $value)
	}
`)

export default async (value: SetUserSetting$input['value']) => await setUserSettingMutation.mutate({ value })
