export default {
    "name": "SetSettingsDefaultValues",
    "kind": "HoudiniMutation",
    "hash": "54e25a8c74e5bd2e81ff9c644f23815c3dc7d0aa1a00f2261592ba51f90c3cbc",

    "raw": `mutation SetSettingsDefaultValues($updateDefaultValueInput: UpdateDefaultValuesInput!) {
  setSettingsDefaultValues(updateDefaultValueInput: $updateDefaultValueInput)
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "setSettingsDefaultValues": {
                "type": "Void",
                "keyRaw": "setSettingsDefaultValues(updateDefaultValueInput: $updateDefaultValueInput)",
                "nullable": true,
                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "input": {
        "fields": {
            "updateDefaultValueInput": "UpdateDefaultValuesInput"
        },

        "types": {
            "UpdateDefaultValueInput": {
                "newDefaultValue": "String",
                "settingId": "NonNegativeInt"
            },

            "UpdateDefaultValuesInput": {
                "settings": "UpdateDefaultValueInput"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=fa29315c241cd71550eb59a6c00d923b50e24cfd562a3e2b249d377610572c40";