import artifact from '$houdini/artifacts/SetSettingsDefaultValues'
import { MutationStore } from '../runtime/stores/mutation'

export class SetSettingsDefaultValuesStore extends MutationStore {
	constructor() {
		super({
			artifact,
		})
	}
}
