import { QueryStore } from '../runtime/stores/query'
import artifact from '$houdini/artifacts/GetPasswordRules'
import { initClient } from '$houdini/plugins/houdini-svelte/runtime/client'

export class GetPasswordRulesStore extends QueryStore {
	constructor() {
		super({
			artifact,
			storeName: "GetPasswordRulesStore",
			variables: false,
		})
	}
}

export async function load_GetPasswordRules(params) {
	await initClient()

	const store = new GetPasswordRulesStore()

	await store.fetch(params)

	return {
		GetPasswordRules: store,
	}
}
