export default {
    "name": "GetServerInformation",
    "kind": "HoudiniQuery",
    "hash": "905bfe71ec9875aacda03b293e6feac781816d07532619024dde414cfdf32ebb",

    "raw": `query GetServerInformation {
  getServerInformation {
    releaseVersionNumber
    schemaVersion
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "getServerInformation": {
                "type": "ServerInformation",
                "keyRaw": "getServerInformation",

                "selection": {
                    "fields": {
                        "releaseVersionNumber": {
                            "type": "String",
                            "keyRaw": "releaseVersionNumber",
                            "visible": true
                        },

                        "schemaVersion": {
                            "type": "String",
                            "keyRaw": "schemaVersion",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=71ead5521fd7effa18599aceaf65c5768d90a411d56f64e516fe29b45259a59d";