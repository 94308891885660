import type { AppContext, ResolveParameters } from 'types/common'
import component from './History.svelte'
import showErrorAndRedirect from 'utility/show-error-and-redirect'
import { graphql, type ReportQueueStatus$options, ReportQueueOrderBy, type ReportQueueOrderBy$options, type ReportQueueJobs$result } from '$houdini'
import { stringToBoolean } from '@isoftdata/utility-string'
import { translateReport } from 'utility/report-viewer-helper'
import { getSession } from 'stores/session'
import makeSettingValueStore from 'stores/setting-value'

type StateParameters = {
	showSentJobs: boolean
	showCancelledJobs: boolean
	pageNumber: number
	sortDirection: 'ASC' | 'DESC'
	sortColumnProperty: string
}

export default function ({ mediator, stateRouter, i18next }: AppContext) {
	stateRouter.addState({
		name: 'app.report-viewer.history',
		route: 'history',
		defaultParameters: {
			pageNumber: '1',
			sortDirection: 'DESC',
			sortColumnProperty: 'dateCreated',
		},
		querystringParameters: ['showSentJobs', 'showCancelledJobs', 'showReadyJobs', 'showFailedJobs', 'showDelayedJobsOnly', 'pageNumber', 'sortDirection', 'sortColumnProperty', 'lastRefreshTime'],
		template: {
			svelte: true,
			component,
		},
		async resolve(_data, parameters: ResolveParameters<StateParameters>) {
			const session = getSession()

			const { data: settingsData } = await settingsQuery.fetch({
				variables: {
					userId: session.userAccountId,
				},
			})
			if (!settingsData) {
				throw showErrorAndRedirect(
					mediator,
					i18next.t('reportViewerHistory.failedToLoadSettingsHeader', 'Failed to load Print Job History Settings'),
					i18next.t('common:noDataReturnedError', 'No data returned from server'),
					{ name: 'app.report-viewer' }
				)
			}

			const showSentJobs = stringToBoolean(parameters.showSentJobs ?? settingsData.showSentJobs.value)
			const showCancelledJobs = stringToBoolean(parameters.showCancelledJobs ?? settingsData.showCancelledJobs.value)
			const showReadyJobs = stringToBoolean(parameters.showReadyJobs ?? settingsData.showReadyJobs.value)
			const showFailedJobs = stringToBoolean(parameters.showFailedJobs ?? settingsData.showFailedJobs.value)
			const showDelayedJobsOnly = stringToBoolean(parameters.showDelayedJobsOnly ?? settingsData.showDelayedJobsOnly.value)
			const pageNumber = parseInt(parameters.pageNumber, 10) || 1
			const pageSize = parseInt(settingsData.pageSize.value, 10) || 100
			const sortDirection = parameters.sortDirection ?? 'ASC'
			const sortColumnProperty = parameters.sortColumnProperty ?? 'dateCreated'

			const statusFilter: Array<ReportQueueStatus$options> = []

			if (showSentJobs) {
				statusFilter.push('SENT')
			}

			if (showCancelledJobs) {
				statusFilter.push('CANCELLED')
			}

			if (showReadyJobs) {
				statusFilter.push('READY')
			}

			if (showFailedJobs) {
				statusFilter.push('FAILED')
			}

			let orderBy: Array<ReportQueueOrderBy$options> = []

			const orderByString = `${sortColumnProperty}_${sortDirection}`

			if (orderByString in ReportQueueOrderBy) {
				orderBy = [orderByString as ReportQueueOrderBy$options]
			}

			let history: ReportQueueJobs$result['reportQueueJobs']['data'] = []
			let totalItemsCount = 0

			if (statusFilter.length > 0) {
				const { data } = await reportQueueJobsQuery.fetch({
					variables: {
						pagination: {
							pageNumber,
							pageSize,
						},
						filter: {
							status: statusFilter,
							delayed: showDelayedJobsOnly ? true : undefined,
						},
						orderBy,
					},
				})
				if (!data) {
					throw showErrorAndRedirect(
						mediator,
						i18next.t('reportViewerHistory.failedToLoadHeader', 'Failed to load Print Job History Settings'),
						i18next.t('common:noDataReturnedError', 'No data returned from server'),
						{ name: 'app.report-viewer' }
					)
				}
				history = data.reportQueueJobs.data.map(job => {
					return {
						...job,
						name: job.name ? translateReport({ name: job.name }, i18next.t).name : null,
					}
				})
				totalItemsCount = data.reportQueueJobs.info.totalItems
			}

			return {
				history,
				currentPageNumber: pageNumber,
				sortDirection,
				sortColumnProperty,
				perPageCount: pageSize,
				totalItemsCount,
				showSentJobs: makeSettingValueStore({
					category: 'Printing',
					settingType: 'INTERFACE_HISTORY',
					name: 'Print Queue: show previously printed print jobs',
					scope: 'USER',
					initialValue: stringToBoolean(settingsData.showSentJobs.value),
				}),
				showCancelledJobs: makeSettingValueStore({
					category: 'Printing',
					settingType: 'INTERFACE_HISTORY',
					name: 'Print Queue: show cancelled print jobs',
					scope: 'USER',
					initialValue: stringToBoolean(settingsData.showCancelledJobs.value),
				}),
				showReadyJobs: makeSettingValueStore({
					category: 'Printing',
					settingType: 'INTERFACE_HISTORY',
					name: 'Print Queue: show ready print jobs',
					scope: 'USER',
					initialValue: stringToBoolean(settingsData.showReadyJobs.value),
				}),
				showFailedJobs: makeSettingValueStore({
					category: 'Printing',
					settingType: 'INTERFACE_HISTORY',
					name: 'Print Queue: show failed print jobs',
					scope: 'USER',
					initialValue: stringToBoolean(settingsData.showFailedJobs.value),
				}),
				showDelayedJobsOnly: makeSettingValueStore({
					category: 'Printing',
					settingType: 'INTERFACE_HISTORY',
					name: 'Print Queue: only delayed print jobs',
					scope: 'USER',
					initialValue: stringToBoolean(settingsData.showDelayedJobsOnly.value),
				}),
			}
		},
	})
}

const reportQueueJobsQuery = graphql(`
	query ReportQueueJobs($pagination: PaginatedInput, $filter: ReportQueueFilter, $orderBy: [ReportQueueOrderBy!]!) {
		reportQueueJobs(pagination: $pagination, filter: $filter, orderBy: $orderBy) {
			data {
				id
				reportId
				type
				name
				quantity
				createdBy
				dateCreated
				destinationType
				destination
				status
				executionTime
				notes
				error
				parameters {
					key: parameterName
					value
				}
			}
			info {
				totalItems
				pageNumber
			}
		}
	}
`)

const settingsQuery = graphql(`
	query ReportViewerHistorySettings($userId: Float!) {
		pageSize: getUserSetting(lookup: { category: "Printing", name: "Print Queue: Number of print jobs to show per page", defaultValue: "100", settingType: INTERFACE_HISTORY, userId: $userId }) {
			value
		}
		showSentJobs: getUserSetting(lookup: { category: "Printing", name: "Print Queue: show previously printed print jobs", defaultValue: "False", settingType: INTERFACE_HISTORY, userId: $userId }) {
			value
		}
		showCancelledJobs: getUserSetting(lookup: { category: "Printing", name: "Print Queue: show cancelled print jobs", defaultValue: "False", settingType: INTERFACE_HISTORY, userId: $userId }) {
			value
		}
		showReadyJobs: getUserSetting(lookup: { category: "Printing", name: "Print Queue: show ready print jobs", defaultValue: "True", settingType: INTERFACE_HISTORY, userId: $userId }) {
			value
		}
		showFailedJobs: getUserSetting(lookup: { category: "Printing", name: "Print Queue: show failed print jobs", defaultValue: "True", settingType: INTERFACE_HISTORY, userId: $userId }) {
			value
		}
		showDelayedJobsOnly: getUserSetting(lookup: { category: "Printing", name: "Print Queue: only delayed print jobs", defaultValue: "False", settingType: INTERFACE_HISTORY, userId: $userId }) {
			value
		}

		# Not implementing this one for now
		# showParameters: getUserSetting(lookup: { category: "Printing", name: "Print Queue: show print job parameters", defaultValue: "True", settingType: INTERFACE_HISTORY, userId: $userId }) {
		# 	value
		# }
	}
`)
