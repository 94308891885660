import type { ResultStatus$options, Acceptability$options, BoundaryType$options } from '$houdini'
import type { IconName } from '@fortawesome/fontawesome-common-types'
import type { ButtonColors } from '@isoftdata/utility-bootstrap'

export const valueAcceptabilityMap = new Map<AcceptabilityOption, AcceptabilityObject>([
	[
		'ALLOWED',
		{
			label: 'Acceptable',
			colorClass: 'success',
			icon: 'check',
			graphValue: 'Allowed',
		},
	],
	[
		'WARNING',
		{
			label: 'Marginal',
			colorClass: 'warning',
			icon: 'exclamation',
			graphValue: 'Warning',
		},
	],
	[
		'ERROR',
		{
			label: 'Unacceptable',
			colorClass: 'danger',
			icon: 'exclamation-triangle',
			graphValue: 'Error',
		},
	],
	[
		'OUT_OF_BOUNDS',
		{
			label: 'Invalid',
			colorClass: 'info',
			icon: 'ban',
			graphValue: 'Out of Bounds',
		},
	],
	[
		'NOT_CALCULATED',
		{
			label: 'Not Calculated',
			colorClass: 'secondary',
			icon: undefined,
			graphValue: 'Not Calculated',
		},
	],
])

export default valueAcceptabilityMap

export const acceptabilityToResultStatus: Record<Acceptability$options, ResultStatus$options> = {
	ALLOWED: 'ALLOWED',
	NOT_CALCULATED: 'NOT_CALCULATED',
	INVALID: 'OUT_OF_BOUNDS',
	MARGINAL: 'WARNING',
	UNACCEPTABLE: 'ERROR',
}

export const boundaryTypeToResultStatus: Record<BoundaryType$options, ResultStatus$options> = {
	ALLOWED: 'ALLOWED',
	BOUNDARY: 'OUT_OF_BOUNDS',
	MARGINAL: 'WARNING',
	UNACCEPTABLE: 'ERROR',
}

export type AcceptabilityOption = ResultStatus$options
export type AcceptabilityObject = {
	label: string
	colorClass: ButtonColors
	icon?: IconName
	graphValue: string
}

export const inverseValueAcceptabilityMap = new Map(Array.from(valueAcceptabilityMap).map(([key, value]) => [value.label, key]))
