import type { AppContext } from 'types/common'
import type { NamedDateRange } from '@isoftdata/utility-date-time'
import { datesFromRange, rangeFromDates } from '@isoftdata/utility-date-time'
import { klona } from 'klona'

import { getSession } from 'stores/session'
import {
	graphql,
	type AnalysisType$options,
	type DocumentStatus$options,
	type ResultStatus$options,
	type SampleFilter,
	type SamplingHistoryData$result,
	type ShAnalyses$result,
	type ShLocations$result,
	type ShProcessZones$result,
	type ShProductBatches$result,
	type ShProducts$result,
	type ShProximities$result,
	type ShWorkOrderTypes$result,
	type ValueType$options,
} from '$houdini'
import component from './SamplingHistory.svelte'
import { SvelteMap } from 'svelte/reactivity'
import { makeEntityOptGroup, entityOptGroupToArray, type EntityOptGroup } from 'utility/entity-proxy-map'
import type { Merge } from 'type-fest'
import type { IconName } from '@fortawesome/fontawesome-common-types'

const filterAnalysisOptionsToActiveOnly = analysis => {
	return {
		...analysis,
		options: analysis.options.filter(option => option.active),
	}
}

export default ({ stateRouter, hasPermission }: AppContext) => {
	stateRouter.addState({
		name: 'app.sampling-history',
		route: 'sampling-history',
		querystringParameters: ['fromDate', 'toDate'],
		template: {
			svelte: true,
			component,
		},
		async resolve(_data, parameters) {
			let session = getSession()

			const { fromDate, toDate, filter } = parameters
			const sampleFilter = filter ? (JSON.parse(parameters.filter) as SampleFilter) : undefined

			if (fromDate === undefined && toDate === undefined) {
				const defaultDates = datesFromRange('Last 365 Days')

				return Promise.reject({
					redirectTo: {
						name: null,
						params: { ...parameters, fromDate: defaultDates.from, toDate: defaultDates.to },
					},
				})
			}

			const t0 = performance.now()

			const {
				//
				savedSampleSearches,
				schedules,
				userAccountList,
				plants,
			} = await dataQuery.fetch().then(({ data }) => {
				return {
					userAccountList: data?.userAccounts.data ?? [],
					savedSampleSearches: data?.userSavedSampleSearches.data ?? [],
					//get just the unique schedule names - names aren't unique, but we only filter by name here
					schedules: Array.from(new Set((data?.schedules.data ?? []).map(schedule => schedule.name))).map(schedule => ({ name: schedule })),
					plants: data?.plants.data ?? [],
				}
			})

			const t1 = performance.now()

			const authorizedPlants = plants.filter(plant => hasPermission('SAMPLING_CAN_SEARCH_HISTORY', plant.id))

			const selectedPlants = [session.siteId]

			const defaultSelectedFilters: Readonly<SelectedFilters> = {
				range: rangeFromDates(fromDate, toDate) || 'Custom',
				dates: { from: fromDate, to: toDate },
				locationName: '',
				product: '',
				productId: null,
				productName: '',
				productProximityId: null,
				productProximityName: '',
				processZoneId: null,
				processZoneName: '',
				tagNumber: '',
				performedByUserId: null,
				performedByUserName: null,
				samplingComments: '',
				testingComments: '',
				selectedPlants,
				analysisList: [],
				dateRangeType: {
					created: false,
					performed: true,
				},
				workOrder: {
					id: null,
					title: '',
					typeId: null,
					typeName: '',
					productBatch: '',
					internalNotes: '',
				},
				matchAllOptionFilters: false,
				sampleStatuses: ['CLOSED', 'OPEN', 'SAMPLED', 'CANCELLED'],
			} as const

			//const savedSearchList = localStorage.getItem('savedSearchList')
			const plantMap = new SvelteMap(authorizedPlants.map(plant => [plant.id, plant]))
			function makeOptGroupLabel(plantId: number) {
				const plant = plantMap.get(plantId)
				return plant ? `${plant.code} - ${plant.name}` : 'Other Plants'
			}

			const analysisProxy = makeEntityOptGroup<Analysis>([], -1, {
				loadFn: async plantId =>
					analysesQuery.fetch({ variables: { filter: { plantIds: [plantId] } }, policy: 'CacheOrNetwork' }).then(({ data }) => (data?.analyses.data ?? []).map(filterAnalysisOptionsToActiveOnly)),
				makeOptGroupLabel,
			})
			// This will pre-load the selected plants' analyses in the proxy, and the list will be used in the results state
			const analysisList = entityOptGroupToArray(await analysisProxy(sampleFilter?.plantIds ?? selectedPlants))
			const selectedOptions = new Set(parameters.selectedOptions ? parameters.selectedOptions.split(',').map(id => parseInt(id, 10)) : [])
			const selectedFilters = sampleFiltersToSelectedFilters({
				sampleFilter,
				defaultFilters: klona(defaultSelectedFilters),
				analysisList,
				selectedOptions,
			})
			console.log(`resolve function took ${t1 - t0} milliseconds.`)
			return {
				analysisList,
				selectedOptions,
				savedSearchList: savedSampleSearches,
				scheduleList: schedules,
				plantList: authorizedPlants || [],
				performedByUserList: userAccountList,
				selectedFilters,
				defaultSelectedFilters,
				analysisProxy,
				locationProxy: makeEntityOptGroup([], -1, {
					loadFn: async plantId => locationsQuery.fetch({ variables: { filter: { plantIds: [plantId] } }, policy: 'CacheOrNetwork' }).then(({ data }) => data?.locations.data ?? []),
					makeOptGroupLabel,
				}),
				proximityProxy: makeEntityOptGroup([], -1, {
					loadFn: async plantId => proximitiesQuery.fetch({ variables: { filter: { plantIds: [plantId] } }, policy: 'CacheOrNetwork' }).then(({ data }) => data?.productProximities.data ?? []),
					makeOptGroupLabel,
				}),
				processZoneProxy: makeEntityOptGroup([], -1, {
					loadFn: async plantId => processZonesQuery.fetch({ variables: { filter: { plantIds: [plantId] } }, policy: 'CacheOrNetwork' }).then(({ data }) => data?.processZones.data ?? []),
					makeOptGroupLabel,
				}),
				productProxy: makeEntityOptGroup([], -1, {
					loadFn: async plantId => productsQuery.fetch({ variables: { filter: { plantIds: [plantId] } }, policy: 'CacheOrNetwork' }).then(({ data }) => data?.products.data ?? []),
					makeOptGroupLabel,
				}),
				productBatchProxy: makeEntityOptGroup([], -1, {
					loadFn: async plantId => productBatchesQuery.fetch({ variables: { filter: { plantIds: [plantId] } }, policy: 'CacheOrNetwork' }).then(({ data }) => data?.productBatches.data ?? []),
					makeOptGroupLabel,
				}),
				workOrderTypeProxy: makeEntityOptGroup([], -1, {
					loadFn: async plantId => workOrderTypesQuery.fetch({ variables: { filter: { plantIds: [plantId] } }, policy: 'CacheOrNetwork' }).then(({ data }) => data?.workOrderTypes.data ?? []),
					makeOptGroupLabel,
				}),
			}
		},
	})
}

/** Re-loads the state / API parameters and converts them to the local parameter type */
function sampleFiltersToSelectedFilters({
	sampleFilter,
	defaultFilters,
	analysisList,
	selectedOptions,
}: {
	sampleFilter?: SampleFilter
	defaultFilters: Readonly<SelectedFilters>
	analysisList: Array<Analysis>
	selectedOptions: Set<number>
}): SelectedFilters {
	if (!sampleFilter) {
		return defaultFilters
	}
	// We set all analyses to the same thing so just take the first one
	const matchAllOptionFilters = sampleFilter.analyses?.[0].matchAllOptionFilters ?? false

	let dateRangeType = defaultFilters.dateRangeType
	let fromDate = defaultFilters.dates?.from
	let toDate = defaultFilters.dates?.to

	if (sampleFilter.createdFrom || sampleFilter.createdTo) {
		toDate = sampleFilter.createdTo ?? ''
		fromDate = sampleFilter.createdFrom ?? ''
		dateRangeType = { created: true, performed: false }
	} else if (sampleFilter.performedFrom || sampleFilter.performedTo) {
		toDate = sampleFilter.performedTo ?? ''
		fromDate = sampleFilter.performedFrom ?? ''
		dateRangeType = { created: false, performed: true }
	}

	const analysisMap = analysisList.reduce((acc: Record<number, Analysis>, analysis) => {
		acc[analysis.id] = analysis
		return acc
	}, {})

	type SelectedFilterAnalysis = SelectedFilters['analysisList'][number]
	type SelectedFilterOption = SelectedFilterAnalysis['options'][number]

	return {
		analysisList:
			sampleFilter.analyses?.map((analysisFilter): SelectedFilterAnalysis => {
				const analysis = analysisMap[analysisFilter.id]
				return {
					id: analysis.id,
					active: analysis.active,
					analysisType: analysis.analysisType,
					category: analysis.category,
					name: analysis.name,
					// only options that have filters on them are sent to the API so we have to start with the list of all options then filter down to just what was selected
					options: analysis.options.reduce((options: Array<SelectedFilterOption>, option) => {
						const optionFilter = analysisFilter.options?.find(optionFilter => optionFilter.id === option.id)
						if (selectedOptions.has(option.id)) {
							options.push({
								id: option.id,
								lotNumber: optionFilter?.lot ?? null,
								resultStatus: optionFilter?.resultStatuses?.[0] ?? undefined,
								selected: true,
								active: option.active,
								choices: option.choices,
								defaultValue: option.defaultValue,
								option: option.option,
								unit: option.unit,
								valueType: option.valueType,
								valueFilter: optionFilter?.result?.eq ?? undefined,
								valueFilterFrom: optionFilter?.result?.gte ?? undefined,
								valueFilterTo: optionFilter?.result?.lte ?? undefined,
								// These aren't in the API filters apparently
								expirationFilterFrom: undefined,
								expirationFilterTo: undefined,
							})
						}

						return options
					}, []),
					resultStatus: analysisFilter.resultStatuses?.[0] ?? undefined,
					selected: true,
				}
			}) ?? [],
		dateRangeType,
		dates: { from: fromDate?.slice(0, 10) ?? null, to: toDate?.slice(0, 10) ?? null },
		isRetest: sampleFilter.isRetest ?? undefined,
		locationName: sampleFilter.locationName ?? undefined,
		matchAllOptionFilters,
		performedByUserId: sampleFilter.performedByUserIds?.[0] ?? null,
		performedByUserName: sampleFilter.performedByUserName ?? null,
		processZoneId: sampleFilter.processZoneIds?.[0] ?? null,
		processZoneName: sampleFilter.location?.processZone?.name ?? '',
		productName: sampleFilter.productName ?? '',
		productId: sampleFilter.productIds?.[0] ?? null,
		productProximityId: sampleFilter.productProximityIds?.[0] ?? null,
		productProximityName: sampleFilter.location?.productProximity?.name ?? '',
		samplingComments: sampleFilter.samplingComments ?? '',
		sampleStatuses: sampleFilter.statuses ?? [],
		scheduleName: sampleFilter.scheduleName ?? null,
		selectedPlants: sampleFilter.plantIds ?? [],
		tagNumber: sampleFilter.tagNumber ?? '',
		testingComments: sampleFilter.testingComments ?? '',
		workOrder: sampleFilter.workOrder
			? {
					id: sampleFilter.workOrder.id ? parseInt(sampleFilter.workOrder.id, 10) : null,
					title: sampleFilter.workOrder.title ?? '',
					typeId: sampleFilter.workOrder.type?.id ?? null,
					typeName: sampleFilter.workOrder.type?.name ?? '',
					productBatch: sampleFilter.workOrder.productBatch?.name ?? '',
					internalNotes: sampleFilter.workOrder.internalNotes ?? '',
			  }
			: defaultFilters.workOrder,
	}
}

/** Client filters type */
export type SelectedFilters = {
	analysisList: Array<{
		id: number
		active: boolean
		name: string
		category: string
		analysisType: AnalysisType$options
		resultStatus?: ResultStatus$options
		selected?: boolean
		options: Array<{
			id: number
			active: boolean
			defaultValue: string | null
			choices: Array<{ id: number; choice: string }>
			option: string
			valueType: ValueType$options
			unit: string
			// filter exclusive stuff
			expirationFilterFrom?: string | null
			expirationFilterTo?: string | null
			lotNumber?: string | null
			resultStatus?: ResultStatus$options
			selected?: boolean
			valueFilterFrom?: string | null
			valueFilterTo?: string | null
			valueFilter?: string
		}>
	}>
	dateRangeType: {
		created: boolean
		performed: boolean
	}
	dates?: { from: string | null; to: string | null }
	isRetest?: boolean
	locationName?: string
	matchAllOptionFilters: boolean
	performedByUserId?: number | null
	performedByUserName?: string | null
	processZoneId?: number | null
	processZoneName?: string
	product?: string
	productId?: number | null
	productName?: string
	productProximityId?: number | null
	productProximityName?: string
	range?: NamedDateRange | 'Custom'
	sampleStatuses: Array<DocumentStatus$options>
	samplingComments?: string
	scheduleName?: string | null
	selectedPlants: number[]
	tagNumber?: string
	testingComments?: string
	workOrder: {
		id: number | null
		title: string
		typeId: number | null
		typeName: string
		productBatch: string
		internalNotes: string
	}
}

/** The Saved Search's filters */
export type SavedSearchState = {
	' $fragments'?: unknown
	dateRange?: {
		// TODO figure out why valueFrom and from are referenced on a saved search
		valueFrom?: number
		valueTo?: number
		from?: number
		to?: number
		type: 'CREATED' | 'PERFORMED'
	}
	analyses?: Array<{
		id: number
		// TODO DocumentStatus$options?
		resultStatus?: ResultStatus$options
		options: Array<{
			id: number
			expirationDateRange: 'Custom: Relative'
			resultStatus?: ResultStatus$options | undefined
			expirationFrom?: number | undefined
			expirationTo?: number | undefined
			lotNumber?: string | undefined
			valueFilter?: string | undefined
			valueFrom?: string | undefined
			valueTo?: string | undefined
		}>
	}>
	plantIds: Array<number>
	samplingComments?: string
	testingComments?: string
	workOrderNumber?: string
	workOrderTitle?: string
	workOrderTypeId?: string
	workOrderTypeName?: string
	productBatchName?: string
	workOrderInternalNotes?: string
	tagNumber?: string
	productId?: number // TODO array?
	productName?: string
	processZoneId?: number
	processZoneName?: string
	productProximityId?: number
	productProximityName?: string
	performedByUserId?: number
	performedByUserName?: string
	locationName?: string
	sampleStatuses?: Array<DocumentStatus$options>
	scheduleName?: string
	matchAllOptionFilters?: boolean
	isRetest?: boolean
	statuses?: Array<DocumentStatus$options>
}

export type ScopeObject = {
	key: 'USER' | 'SITE' | 'GLOBAL'
	show: boolean
	display: string
	iconClass: string
	icon: IconName
}

/** Saved Search straight from the API */
export type SavedSearch = SamplingHistoryData$result['userSavedSampleSearches']['data'][number]

/** SavedSearch with some extra fields for display in the lists */
export type DisplaySavedSearch = Merge<
	SavedSearch,
	{
		createdOn: Date
		displayCreated: string
		sharedWith?: ScopeObject
	}
>

const dataQuery = graphql(`
	query SamplingHistoryData {
		userAccounts(filter: { activeOnly: false }, pagination: { pageSize: 0 }) {
			data {
				id
				name
				firstName
				lastName
				status
			}
		}
		userSavedSampleSearches(pagination: { pageSize: 0 }) {
			data {
				...SavedSearchFields
			}
		}
		schedules(pagination: { pageSize: 0 }) {
			data {
				name
			}
		}
		plants(pagination: { pageSize: 0 }) {
			data {
				id
				code
				name
			}
		}
	}
`)

export type Location = ShLocations$result['locations']['data'][number]
const locationsQuery = graphql(`
	query ShLocations($filter: LocationFilter) {
		locations(filter: $filter, pagination: { pageSize: 0 }) {
			data {
				id
				plantId
				code
				location
			}
		}
	}
`)
export type Proximity = ShProximities$result['productProximities']['data'][number]
const proximitiesQuery = graphql(`
	query ShProximities($filter: ProductProximityFilter) {
		productProximities(filter: $filter, pagination: { pageSize: 0 }) {
			data {
				id
				plantId
				name
			}
		}
	}
`)
export type ProcessZone = ShProcessZones$result['processZones']['data'][number]
const processZonesQuery = graphql(`
	query ShProcessZones($filter: ProcessZoneFilter) {
		processZones(filter: $filter, pagination: { pageSize: 0 }) {
			data {
				id
				plantId
				name
			}
		}
	}
`)
export type Product = ShProducts$result['products']['data'][number]
const productsQuery = graphql(`
	query ShProducts($filter: ProductFilter) {
		products(filter: $filter, pagination: { pageSize: 0 }) {
			data {
				id
				name
				parentProductId
				inUseAtPlantIDs
			}
		}
	}
`)
export type Analysis = ShAnalyses$result['analyses']['data'][number]
const analysesQuery = graphql(`
	query ShAnalyses($filter: AnalysisFilter) {
		analyses(filter: $filter) {
			data {
				id
				active
				analysisType
				name
				category
				options {
					id
					active #TODO maybe make active a server side filter?
					option
					valueType
					unit
					defaultValue
					choices {
						id
						choice
					}
				}
			}
		}
	}
`)
export type WorkOrderType = ShWorkOrderTypes$result['workOrderTypes']['data'][number]
const workOrderTypesQuery = graphql(`
	query ShWorkOrderTypes($filter: WorkOrderTypeFilter) {
		workOrderTypes(filter: $filter, pagination: { pageSize: 0 }) {
			data {
				id
				name
			}
		}
	}
`)
export type ProductBatch = ShProductBatches$result['productBatches']['data'][number]
const productBatchesQuery = graphql(`
	query ShProductBatches($filter: ProductBatchFilter) {
		productBatches(filter: $filter) {
			data {
				id
				name
			}
		}
	}
`)

graphql(`
	fragment SavedSearchFields on SavedSampleSearch {
		id
		createdOn
		name
		description
		scope
		plant {
			id
			code
		}
		createdByUser {
			id
			fullName
		}
	}
`)
