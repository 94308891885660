export default {
    "name": "SamplingHistoryResults",
    "kind": "HoudiniQuery",
    "hash": "d5b9e21ac25d6dbf4d9f1f38c6771ec0898cac0853c8316957f484ba198b8f3b",

    "raw": `query SamplingHistoryResults($filter: SampleFilter, $pagination: PaginatedInput, $orderBy: [SampleOrderBy!]) {
  samples(filter: $filter, pagination: $pagination, orderBy: $orderBy) {
    info {
      pageNumber
      pageSize
      totalItems
    }
    data {
      sampleFiles: attachments {
        file {
          path
          mediaType: type
          id
        }
        rank
        id
      }
      plant {
        id
        name
        code
      }
      product {
        id
        name
      }
      location {
        id
        location
        description
        severityClass {
          id
        }
      }
      collectedBy {
        fullName
        id
      }
      findings
      comments
      status
      tagNumber
      sampleValues {
        id
        result
        resultStatus
        defaultValue
        lastModifiedByUserId
        lastModified
        lot
        expiration
        filledOut
        analysisOptionId
        analysisOption {
          id
          option
          unit
          active
          valueType
          thresholdType
          rank
          defaultType
          defaultValue
        }
      }
      id
      workOrder {
        id
        title
        verificationDue
        verifiedOn
        verifiedByUser {
          fullName
          id
        }
        dateCreated
        due
        internalNotes
        instructions
        scheduled
        assignedToGroup {
          name
          id
        }
        workOrderType {
          name
          showTestingDetail
          id
        }
        productBatch {
          id
          name
        }
        plant {
          id
        }
      }
      analysisId
      incubationBegan
      incubationEnded
      performed
      scheduled
      due
      platesReadBy {
        fullName
        id
      }
    }
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "samples": {
                "type": "SampleResponse",
                "keyRaw": "samples(filter: $filter, orderBy: $orderBy, pagination: $pagination)",

                "selection": {
                    "fields": {
                        "info": {
                            "type": "PageInfo",
                            "keyRaw": "info",

                            "selection": {
                                "fields": {
                                    "pageNumber": {
                                        "type": "Int",
                                        "keyRaw": "pageNumber",
                                        "visible": true
                                    },

                                    "pageSize": {
                                        "type": "Int",
                                        "keyRaw": "pageSize",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "totalItems": {
                                        "type": "Int",
                                        "keyRaw": "totalItems",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "data": {
                            "type": "Sample",
                            "keyRaw": "data",

                            "selection": {
                                "fields": {
                                    "sampleFiles": {
                                        "type": "SampleFile",
                                        "keyRaw": "sampleFiles",

                                        "selection": {
                                            "fields": {
                                                "file": {
                                                    "type": "File",
                                                    "keyRaw": "file",

                                                    "selection": {
                                                        "fields": {
                                                            "path": {
                                                                "type": "String",
                                                                "keyRaw": "path",
                                                                "visible": true
                                                            },

                                                            "mediaType": {
                                                                "type": "FileType",
                                                                "keyRaw": "mediaType",
                                                                "visible": true
                                                            },

                                                            "id": {
                                                                "type": "PositiveInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "rank": {
                                                    "type": "SafeInt",
                                                    "keyRaw": "rank",
                                                    "visible": true
                                                },

                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "plant": {
                                        "type": "Plant",
                                        "keyRaw": "plant",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "name": {
                                                    "type": "String",
                                                    "keyRaw": "name",
                                                    "visible": true
                                                },

                                                "code": {
                                                    "type": "String",
                                                    "keyRaw": "code",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "product": {
                                        "type": "Product",
                                        "keyRaw": "product",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "name": {
                                                    "type": "String",
                                                    "keyRaw": "name",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "location": {
                                        "type": "Location",
                                        "keyRaw": "location",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "location": {
                                                    "type": "String",
                                                    "keyRaw": "location",
                                                    "visible": true
                                                },

                                                "description": {
                                                    "type": "String",
                                                    "keyRaw": "description",
                                                    "visible": true
                                                },

                                                "severityClass": {
                                                    "type": "SeverityClass",
                                                    "keyRaw": "severityClass",
                                                    "nullable": true,

                                                    "selection": {
                                                        "fields": {
                                                            "id": {
                                                                "type": "PositiveInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "collectedBy": {
                                        "type": "UserAccount",
                                        "keyRaw": "collectedBy",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "fullName": {
                                                    "type": "NonEmptyString",
                                                    "keyRaw": "fullName",
                                                    "visible": true
                                                },

                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "findings": {
                                        "type": "String",
                                        "keyRaw": "findings",
                                        "visible": true
                                    },

                                    "comments": {
                                        "type": "String",
                                        "keyRaw": "comments",
                                        "visible": true
                                    },

                                    "status": {
                                        "type": "DocumentStatus",
                                        "keyRaw": "status",
                                        "visible": true
                                    },

                                    "tagNumber": {
                                        "type": "String",
                                        "keyRaw": "tagNumber",
                                        "visible": true
                                    },

                                    "sampleValues": {
                                        "type": "SampleValue",
                                        "keyRaw": "sampleValues",

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "result": {
                                                    "type": "String",
                                                    "keyRaw": "result",
                                                    "visible": true
                                                },

                                                "resultStatus": {
                                                    "type": "ResultStatus",
                                                    "keyRaw": "resultStatus",
                                                    "visible": true
                                                },

                                                "defaultValue": {
                                                    "type": "String",
                                                    "keyRaw": "defaultValue",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "lastModifiedByUserId": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "lastModifiedByUserId",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "lastModified": {
                                                    "type": "DateTime",
                                                    "keyRaw": "lastModified",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "lot": {
                                                    "type": "String",
                                                    "keyRaw": "lot",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "expiration": {
                                                    "type": "Date",
                                                    "keyRaw": "expiration",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "filledOut": {
                                                    "type": "DateTime",
                                                    "keyRaw": "filledOut",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "analysisOptionId": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "analysisOptionId",
                                                    "visible": true
                                                },

                                                "analysisOption": {
                                                    "type": "AnalysisOption",
                                                    "keyRaw": "analysisOption",

                                                    "selection": {
                                                        "fields": {
                                                            "id": {
                                                                "type": "PositiveInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "option": {
                                                                "type": "String",
                                                                "keyRaw": "option",
                                                                "visible": true
                                                            },

                                                            "unit": {
                                                                "type": "String",
                                                                "keyRaw": "unit",
                                                                "visible": true
                                                            },

                                                            "active": {
                                                                "type": "Boolean",
                                                                "keyRaw": "active",
                                                                "visible": true
                                                            },

                                                            "valueType": {
                                                                "type": "ValueType",
                                                                "keyRaw": "valueType",
                                                                "visible": true
                                                            },

                                                            "thresholdType": {
                                                                "type": "ThresholdType",
                                                                "keyRaw": "thresholdType",
                                                                "visible": true
                                                            },

                                                            "rank": {
                                                                "type": "NonNegativeInt",
                                                                "keyRaw": "rank",
                                                                "visible": true
                                                            },

                                                            "defaultType": {
                                                                "type": "DefaultType",
                                                                "keyRaw": "defaultType",
                                                                "visible": true
                                                            },

                                                            "defaultValue": {
                                                                "type": "String",
                                                                "keyRaw": "defaultValue",
                                                                "nullable": true,
                                                                "visible": true
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "workOrder": {
                                        "type": "WorkOrder",
                                        "keyRaw": "workOrder",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "title": {
                                                    "type": "String",
                                                    "keyRaw": "title",
                                                    "visible": true
                                                },

                                                "verificationDue": {
                                                    "type": "DateTime",
                                                    "keyRaw": "verificationDue",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "verifiedOn": {
                                                    "type": "DateTime",
                                                    "keyRaw": "verifiedOn",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "verifiedByUser": {
                                                    "type": "UserAccount",
                                                    "keyRaw": "verifiedByUser",
                                                    "nullable": true,

                                                    "selection": {
                                                        "fields": {
                                                            "fullName": {
                                                                "type": "NonEmptyString",
                                                                "keyRaw": "fullName",
                                                                "visible": true
                                                            },

                                                            "id": {
                                                                "type": "PositiveInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "dateCreated": {
                                                    "type": "DateTime",
                                                    "keyRaw": "dateCreated",
                                                    "visible": true
                                                },

                                                "due": {
                                                    "type": "DateTime",
                                                    "keyRaw": "due",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "internalNotes": {
                                                    "type": "String",
                                                    "keyRaw": "internalNotes",
                                                    "visible": true
                                                },

                                                "instructions": {
                                                    "type": "String",
                                                    "keyRaw": "instructions",
                                                    "visible": true
                                                },

                                                "scheduled": {
                                                    "type": "DateTime",
                                                    "keyRaw": "scheduled",
                                                    "visible": true
                                                },

                                                "assignedToGroup": {
                                                    "type": "Group",
                                                    "keyRaw": "assignedToGroup",
                                                    "nullable": true,

                                                    "selection": {
                                                        "fields": {
                                                            "name": {
                                                                "type": "String",
                                                                "keyRaw": "name",
                                                                "visible": true
                                                            },

                                                            "id": {
                                                                "type": "PositiveInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "workOrderType": {
                                                    "type": "WorkOrderType",
                                                    "keyRaw": "workOrderType",

                                                    "selection": {
                                                        "fields": {
                                                            "name": {
                                                                "type": "String",
                                                                "keyRaw": "name",
                                                                "visible": true
                                                            },

                                                            "showTestingDetail": {
                                                                "type": "Boolean",
                                                                "keyRaw": "showTestingDetail",
                                                                "visible": true
                                                            },

                                                            "id": {
                                                                "type": "PositiveInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "productBatch": {
                                                    "type": "ProductBatch",
                                                    "keyRaw": "productBatch",
                                                    "nullable": true,

                                                    "selection": {
                                                        "fields": {
                                                            "id": {
                                                                "type": "PositiveInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "name": {
                                                                "type": "String",
                                                                "keyRaw": "name",
                                                                "visible": true
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "plant": {
                                                    "type": "Plant",
                                                    "keyRaw": "plant",

                                                    "selection": {
                                                        "fields": {
                                                            "id": {
                                                                "type": "PositiveInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "analysisId": {
                                        "type": "PositiveInt",
                                        "keyRaw": "analysisId",
                                        "visible": true
                                    },

                                    "incubationBegan": {
                                        "type": "DateTime",
                                        "keyRaw": "incubationBegan",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "incubationEnded": {
                                        "type": "DateTime",
                                        "keyRaw": "incubationEnded",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "performed": {
                                        "type": "DateTime",
                                        "keyRaw": "performed",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "scheduled": {
                                        "type": "DateTime",
                                        "keyRaw": "scheduled",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "due": {
                                        "type": "DateTime",
                                        "keyRaw": "due",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "platesReadBy": {
                                        "type": "UserAccount",
                                        "keyRaw": "platesReadBy",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "fullName": {
                                                    "type": "NonEmptyString",
                                                    "keyRaw": "fullName",
                                                    "visible": true
                                                },

                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "filter": "SampleFilter",
            "pagination": "PaginatedInput",
            "orderBy": "SampleOrderBy"
        },

        "types": {
            "ValueComparison": {
                "eq": "NonEmptyString",
                "gt": "NonEmptyString",
                "gte": "NonEmptyString",
                "lt": "NonEmptyString",
                "lte": "NonEmptyString"
            },

            "SampleAnalysisOptionFilter": {
                "id": "PositiveInt",
                "lot": "NonEmptyString",
                "result": "ValueComparison",
                "resultStatuses": "ResultStatus"
            },

            "SampleAnalysisFilter": {
                "id": "PositiveInt",
                "matchAllOptionFilters": "Boolean",
                "options": "SampleAnalysisOptionFilter",
                "resultStatuses": "ResultStatus"
            },

            "ProcessZoneFilter": {
                "name": "NonEmptyString",
                "plantIds": "PositiveInt",
                "plants": "PositiveInt"
            },

            "ProductProximityFilter": {
                "name": "NonEmptyString",
                "plantIds": "PositiveInt",
                "plants": "PositiveInt"
            },

            "LocationFilter": {
                "activeOnly": "Boolean",
                "location": "NonEmptyString",
                "plantIds": "PositiveInt",
                "plants": "PositiveInt",
                "processZone": "ProcessZoneFilter",
                "productProximity": "ProductProximityFilter",
                "testableOnly": "Boolean"
            },

            "ProductBatchFilter": {
                "name": "String",
                "plantIds": "PositiveInt",
                "productIds": "PositiveInt",
                "statuses": "ProductBatchStatus"
            },

            "WorkOrderTypeFilter": {
                "id": "PositiveInt",
                "name": "NonEmptyString",
                "plantIds": "PositiveInt"
            },

            "WorkOrderFilter": {
                "id": "String",
                "internalNotes": "String",
                "productBatch": "ProductBatchFilter",
                "productBatchIds": "PositiveInt",
                "title": "NonEmptyString",
                "type": "WorkOrderTypeFilter"
            },

            "SampleFilter": {
                "analyses": "SampleAnalysisFilter",
                "collectedByUserIds": "PositiveInt",
                "createdFrom": "DateTime",
                "createdTo": "DateTime",
                "ids": "PositiveInt",
                "isRetest": "Boolean",
                "location": "LocationFilter",
                "locationIds": "PositiveInt",
                "locationName": "NonEmptyString",
                "performedByUserIds": "PositiveInt",
                "performedByUserName": "NonEmptyString",
                "performedFrom": "DateTime",
                "performedTo": "DateTime",
                "plantIds": "PositiveInt",
                "processZoneIds": "PositiveInt",
                "product": "NonEmptyString",
                "productIds": "PositiveInt",
                "productName": "NonEmptyString",
                "productProximityIds": "PositiveInt",
                "productProximityName": "NonEmptyString",
                "samplingComments": "NonEmptyString",
                "scheduleIds": "PositiveInt",
                "scheduleName": "NonEmptyString",
                "statuses": "DocumentStatus",
                "tagNumber": "NonEmptyString",
                "tagNumbers": "NonEmptyString",
                "testingComments": "NonEmptyString",
                "workOrder": "WorkOrderFilter",
                "workOrders": "WorkOrderFilter"
            },

            "PaginatedInput": {
                "pageNumber": "PositiveInt",
                "pageSize": "NonNegativeInt"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=8e2007f901f16e564400415a1e252823c807edee9bbea9f8ba984e58aa442441";