<script
	context="module"
	lang="ts"
>
	import type { EntityTagType$options as EntityTagType } from '$houdini'
	/** This type represents an EntityTag with the required metadata to use it with the TagSelection component.*/
	export type MetaTag = { id: null | number; active: boolean; name: string; uuid: string; inUse?: boolean; entityType: EntityTagType }
	type MappedTag = Required<MetaTag>

	//
</script>

<script lang="ts">
	import type { i18n } from 'i18next'

	import Table, { Td } from '@isoftdata/svelte-table'
	import Checkbox from '@isoftdata/svelte-checkbox'
	import Input from '@isoftdata/svelte-input'
	import Icon from '@isoftdata/svelte-icon'
	import Button from '@isoftdata/svelte-button'

	import { v4 as uuid } from '@lukeed/uuid'
	import makeCrudStore from '@isoftdata/svelte-store-crud'
	import { createEventDispatcher, getContext, tick } from 'svelte'

	const dispatch = createEventDispatcher<{
		/** Fired when a tag's "In Use" status changes*/
		tagsInUseChange: void
		/** Fired when a tag is marked as "In Use"*/
		tagsInUseAdd: MetaTag
		/** Fired when a tag is no longer marked as "In Use"*/
		tagsInUseRemove: MetaTag
	}>()

	const { t: translate } = getContext<i18n>('i18next')

	export let disabled: boolean | undefined = false
	export let entityType: EntityTagType
	export let tagsInUse: MetaTag[]
	export let selectedTag: MetaTag | null = null

	export let tags: MetaTag[] = []
	/** If you want to name this card after a specific selected entity, ("TEC Tags") you may specify a title.
	 *
	 * If you just want it to be "Plant Tags" etc., leave it undefined.
	 */
	export let title: string | undefined = undefined
	export let tableParentClass: string = 'mh-50vh'

	const tagTemplate = {
		active: true,
		entityType: entityType,
		id: null,
		name: '',
		inUse: false,
	}
	/** Pass your own CRUD store in if you want tracked changes to persist when the component is destroyed.*/
	export let tagCrudStore = makeCrudStore<MetaTag, 'uuid'>('uuid')

	export let tagsToCreate: MetaTag[] = []
	export let tagsToUpdate: MetaTag[] = []
	export let tagsToDelete: MetaTag[] = []
	export let hasDuplicateTagNames = false
	export let includeCard = true
	$: tagsToUpdate = Object.values($tagCrudStore.updated)
	$: tagsToCreate = Object.values($tagCrudStore.created)
	$: tagsToDelete = Object.values($tagCrudStore.deleted)
	$: hasDuplicateTagNames = tags.some(tag => tagHasDuplicateTagName(tag))
	$: hasEmptyTagNames = tags.some(tag => tag.name.trim() === '')
	$: mappedTags = getMappedTags(tags, tagsInUse)

	let selectedTagIndex: number | null = null

	function getDeleteTagConfirmMessage() {
		switch (entityType) {
			case 'ANALYSIS':
				return translate(
					'tagSelection.confirmDeleteAnalysisTag',
					'Are you sure you want to permanently delete this tag? If you want to remove it from this analysis, use the checkbox instead. \r\n\r\nNote: After saving, this tag will be removed from all items at all plants.',
				)
			case 'LOCATION':
				return translate(
					'tagSelection.confirmDeleteLocationTag',
					'Are you sure you want to permanently delete this tag? If you want to remove it from this location, use the checkbox instead. \r\n\r\nNote: After saving, this tag will be removed from all items at all plants.',
				)
			case 'PLANT':
				return translate(
					'tagSelection.confirmDeletePlantTag',
					'Are you sure you want to permanently delete this tag? If you want to remove it from this plant, use the checkbox instead. \r\n\r\nNote: After saving, this tag will be removed from all items at all plants.',
				)
			case 'PRODUCT':
				return translate(
					'tagSelection.confirmDeleteProductTag',
					'Are you sure you want to permanently delete this tag? If you want to remove it from this product, use the checkbox instead. \r\n\r\nNote: After saving, this tag will be removed from all items at all plants.',
				)
			case 'SUPPLIER':
				return translate(
					'tagSelection.confirmDeleteSupplierTag',
					'Are you sure you want to permanently delete this tag? If you want to remove it from this supplier, use the checkbox instead. \r\n\r\nNote: After saving, this tag will be removed from all items at all plants.',
				)
			default:
				return translate(
					'tagSelection.confirmDeleteTag',
					'Are you sure you want to permanently delete this tag? If you want to remove it from this item, use the checkbox instead. \r\n\r\nNote: After saving, this tag will be removed from all items at all plants.',
				)
		}
	}

	function getCardHeaderText() {
		switch (entityType) {
			case 'ANALYSIS':
				return translate('tagSelection.analysisTags', 'Analysis Tags')
			case 'LOCATION':
				return translate('tagSelection.locationTags', 'Location Tags')
			case 'PLANT':
				return translate('tagSelection.plantTags', 'Plant Tags')
			case 'PRODUCT':
				return translate('tagSelection.productTags', 'Product Tags')
			case 'SUPPLIER':
				return translate('tagSelection.supplierTags', 'Supplier Tags')
			default:
				return translate('tagSelection.tags', 'Tags')
		}
	}

	function getNoTagsFoundText() {
		switch (entityType) {
			case 'ANALYSIS':
				return translate('tagSelection.noAnalysisTagsFound', 'No analysis tags found.')
			case 'LOCATION':
				return translate('tagSelection.noLocationTagsFound', 'No location tags found.')
			case 'PLANT':
				return translate('tagSelection.noPlantTagsFound', 'No plant tags found.')
			case 'PRODUCT':
				return translate('tagSelection.noProductTagsFound', 'No product tags found.')
			case 'SUPPLIER':
				return translate('tagSelection.noSupplierTagsFound', 'No supplier tags found.')
			default:
				return translate('tagSelection.noTagsFound', 'No tags found.')
		}
	}

	function deleteSelectedTag() {
		if (!confirm(getDeleteTagConfirmMessage()) || selectedTagIndex === null) {
			return
		}
		const selectedTag = tags[selectedTagIndex]
		if (selectedTag.uuid in $tagCrudStore.deleted) {
			tagCrudStore.unDelete(selectedTag)
			tags = tags
		} else {
			tags.splice(selectedTagIndex, 1)
			tags = tags
			tagCrudStore.delete(selectedTag)
		}
	}

	async function newTag() {
		const newTag = { ...tagTemplate, uuid: uuid(), entityType }
		tags.push(newTag)
		tags = tags
		selectedTagIndex = tags.length - 1
		selectedTag = newTag
		tagCrudStore.create(newTag)
		await tick()
		const input = document.getElementById(`${entityType}-tag-name-${selectedTagIndex}`) as HTMLInputElement | null
		input?.focus()
	}

	function tagClicked(thisRow: MetaTag & { originalIndex: number }) {
		if (disabled) {
			return
		}
		selectedTagIndex = thisRow.originalIndex
		selectedTag = thisRow
	}

	function useTagClicked(event, tagIndex: number) {
		if (disabled) {
			return
		}
		tags[tagIndex].inUse = (event.target as HTMLInputElement).checked
		if (tags[tagIndex].inUse) {
			// if we just checked the box add it to the list otherwise remove it
			tagsInUse = [...tagsInUse, tags[tagIndex]]
			dispatch('tagsInUseAdd', tags[tagIndex])
		} else {
			tagsInUse = tagsInUse.filter(tag => tag.uuid !== tags[tagIndex].uuid)
			dispatch('tagsInUseRemove', tags[tagIndex])
		}
		dispatch('tagsInUseChange')
	}
	function updateTagName(event, tagIndex: number) {
		tags[tagIndex].name = (event.target as HTMLInputElement).value.trim()
		tagCrudStore.update(tags[tagIndex])
		tags = tags
	}

	function getMappedTags(tags: Array<MetaTag>, activeTags: Array<MetaTag>): Array<MappedTag> {
		return tags.map(tag => ({ ...tag, inUse: activeTags.some(activeTag => activeTag.uuid === tag.uuid) }))
	}

	function tagHasDuplicateTagName(tag: MetaTag) {
		return tags.some(thisTag => thisTag.name.toUpperCase() === tag.name.toUpperCase() && thisTag.uuid !== tag.uuid)
	}
</script>

<div
	class:card={includeCard}
	class:h-100={!includeCard}
	class:d-flex={!includeCard}
	class:flex-column={!includeCard}
>
	{#if includeCard}
		<h5 class="card-header">
			{title ? translate('tagSelection.titleTags', '{{- title}} Tags', { title }) : getCardHeaderText()}
		</h5>
	{/if}
	<div
		class:card-body={includeCard}
		class:flex-grow-1={!includeCard}
	>
		<Table
			responsive
			stickyHeader
			rows={mappedTags}
			columns={[
				{ name: translate('tagSelection.inUseColumn', 'In Use'), width: '1rem', property: 'inUse', align: 'center' },
				{ name: translate('tagSelection.nameColumn', 'Name'), property: 'name' },
			]}
			parentClass={tableParentClass}
		>
			{#snippet children({ row })}
				<tr
					id="{entityType}-tag-row-{row.originalIndex}"
					class:table-primary={selectedTagIndex === row.originalIndex}
					class="cursor-pointer"
					on:click={() => tagClicked(row)}
				>
					<Td property="inUse">
						<Checkbox
							showLabel={false}
							{disabled}
							checked={row.inUse}
							on:change={event => useTagClicked(event, row.originalIndex)}
						/>
					</Td>
					<Td property="name">
						<Input
							required
							id="{entityType}-tag-name-{row.originalIndex}"
							{disabled}
							showLabel={false}
							value={row.name}
							validation={{
								validator: value => (value && !tagHasDuplicateTagName(row) ? true : ''),
							}}
							on:change={event => updateTagName(event, row.originalIndex)}
						/>
					</Td>
				</tr>
			{/snippet}
			{#snippet noRows()}
				<tr>
					<td
						colspan="2"
						class="text-center"
					>
						{getNoTagsFoundText()}
					</td>
				</tr>
			{/snippet}
		</Table>
		{#if hasDuplicateTagNames}
			<div class="alert alert-danger mt-2">
				<Icon icon="exclamation-triangle" />
				<span class="ml-2">{translate('tagSelection.duplicateTagNameError', 'Duplicate tag names are not allowed.')}</span>
			</div>
		{/if}
	</div>
	<div
		class="card-footer"
		style:margin={includeCard ? '' : '0 -1.25rem -1.25rem -1.25rem'}
	>
		<Button
			outline
			size="sm"
			color="success"
			iconClass="plus"
			disabled={disabled || hasEmptyTagNames}
			title={translate('tagSelection.newTagButtonTitle', 'Add a new tag')}
			on:click={newTag}
		>
			{translate('tagSelection.newTagButton', 'New Tag')}
		</Button>
		<Button
			outline
			size="sm"
			color="danger"
			iconClass="plus"
			disabled={!selectedTag || disabled}
			title={translate('tagSelection.deleteTagButtonTitle', 'Delete selected tag. This will remove the tag from all items that use it.')}
			on:click={deleteSelectedTag}
		>
			{translate('tagSelection.deleteTagButton', 'Delete')}...
		</Button>
	</div>
</div>
