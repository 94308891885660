import { graphql } from '$houdini'

const getPasswordRulesQuery = graphql(`
	query GetPasswordRules {
		getPasswordRules {
			minimumPasswordLength
			maximumPasswordLength
			numberOfRequiredLowercaseCharacters
			numberOfRequiredUppercaseCharacters
			numberOfRequiredNumericCharacters
			numberOfRequiredSpecialCharacters
		}
	}
`)

export async function getPasswordRules(): Promise<Record<string, number>> {
	const { data } = await getPasswordRulesQuery.fetch()

	const passwordRules = Object.entries(data?.getPasswordRules ?? {}).reduce((acc, [key, value]) => {
		const numberValue = Number(value)
		if (!isNaN(numberValue)) {
			acc[key] = numberValue
		}
		return acc
	}, {})

	return passwordRules
}
