export default {
    "name": "ShGetSearchDescription",
    "kind": "HoudiniQuery",
    "hash": "6a60ca954a59e64def0378cdfdc618c2e9fb2add8c76dbe37444f15342f14851",

    "raw": `query ShGetSearchDescription($savedSampleSearchId: ID!) {
  savedSampleSearch(id: $savedSampleSearchId) {
    generatedDescription
    id
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "savedSampleSearch": {
                "type": "SavedSampleSearch",
                "keyRaw": "savedSampleSearch(id: $savedSampleSearchId)",
                "nullable": true,

                "selection": {
                    "fields": {
                        "generatedDescription": {
                            "type": "String",
                            "keyRaw": "generatedDescription",
                            "visible": true
                        },

                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "input": {
        "fields": {
            "savedSampleSearchId": "ID"
        },

        "types": {},
        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=651fb707f3e57d3f2e2fd81eda252b1395a827135eb33794594425ec980eb783";