import { QueryStore } from '../runtime/stores/query'
import artifact from '$houdini/artifacts/getCityStateAbbvCountryFromZip'
import { initClient } from '$houdini/plugins/houdini-svelte/runtime/client'

export class getCityStateAbbvCountryFromZipStore extends QueryStore {
	constructor() {
		super({
			artifact,
			storeName: "getCityStateAbbvCountryFromZipStore",
			variables: true,
		})
	}
}

export async function load_getCityStateAbbvCountryFromZip(params) {
	await initClient()

	const store = new getCityStateAbbvCountryFromZipStore()

	await store.fetch(params)

	return {
		getCityStateAbbvCountryFromZip: store,
	}
}
