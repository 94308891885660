import component from './ActivateNewAccount.svelte'
import type { AppContext } from 'types/common'
import { getPasswordRules } from 'utility/get-password-rules'
import logOut from 'utility/log-out'

export default ({ mediator, stateRouter }: AppContext) => {
	stateRouter.addState({
		name: 'activate-new-account',
		route: 'activate-new-account',
		template: {
			svelte: true,
			component,
		},
		querystringParameters: ['username', 'pin'],
		async resolve(_data, parameters: { username?: string; pin?: string }) {
			await logOut()
			try {
				const passwordRules = await getPasswordRules(mediator)
				return {
					passwordRules,
					userName: parameters.username,
					pin: parameters.pin,
				}
			} catch (err) {
				console.error(err)
				return { passwordRules: {} }
			}
		},
	})
}
