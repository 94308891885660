export default {
    "name": "SamplingHistoryData",
    "kind": "HoudiniQuery",
    "hash": "f4c5fb9422a8f02eade00484fd1d9edbc9afd62e2fe13d6c4c086bd041558a5f",

    "raw": `query SamplingHistoryData {
  userAccounts(filter: {activeOnly: false}, pagination: {pageSize: 0}) {
    data {
      id
      name
      firstName
      lastName
      status
    }
  }
  userSavedSampleSearches(pagination: {pageSize: 0}) {
    data {
      ...SavedSearchFields
      id
    }
  }
  schedules(pagination: {pageSize: 0}) {
    data {
      name
      id
    }
  }
  plants(pagination: {pageSize: 0}) {
    data {
      id
      code
      name
    }
  }
}

fragment SavedSearchFields on SavedSampleSearch {
  id
  createdOn
  name
  description
  scope
  plant {
    id
    code
  }
  createdByUser {
    id
    fullName
  }
  __typename
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "userAccounts": {
                "type": "UserAccountResponse",
                "keyRaw": "userAccounts(filter: {activeOnly: false}, pagination: {pageSize: 0})",

                "selection": {
                    "fields": {
                        "data": {
                            "type": "UserAccount",
                            "keyRaw": "data",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    },

                                    "firstName": {
                                        "type": "String",
                                        "keyRaw": "firstName",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "lastName": {
                                        "type": "String",
                                        "keyRaw": "lastName",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "status": {
                                        "type": "UserAccountStatus",
                                        "keyRaw": "status",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "userSavedSampleSearches": {
                "type": "SavedSampleSearchResponse",
                "keyRaw": "userSavedSampleSearches(pagination: {pageSize: 0})",

                "selection": {
                    "fields": {
                        "data": {
                            "type": "SavedSampleSearch",
                            "keyRaw": "data",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "createdOn": {
                                        "type": "DateTime",
                                        "keyRaw": "createdOn",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    },

                                    "description": {
                                        "type": "String",
                                        "keyRaw": "description",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "scope": {
                                        "type": "SeachScope",
                                        "keyRaw": "scope",
                                        "visible": true
                                    },

                                    "plant": {
                                        "type": "Plant",
                                        "keyRaw": "plant",

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "code": {
                                                    "type": "String",
                                                    "keyRaw": "code",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "createdByUser": {
                                        "type": "UserAccount",
                                        "keyRaw": "createdByUser",

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "fullName": {
                                                    "type": "NonEmptyString",
                                                    "keyRaw": "fullName",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "__typename": {
                                        "type": "String",
                                        "keyRaw": "__typename",
                                        "visible": true
                                    }
                                },

                                "fragments": {
                                    "SavedSearchFields": {
                                        "arguments": {}
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "schedules": {
                "type": "ScheduleResponse",
                "keyRaw": "schedules(pagination: {pageSize: 0})",

                "selection": {
                    "fields": {
                        "data": {
                            "type": "Schedule",
                            "keyRaw": "data",

                            "selection": {
                                "fields": {
                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    },

                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "plants": {
                "type": "PlantResponse",
                "keyRaw": "plants(pagination: {pageSize: 0})",

                "selection": {
                    "fields": {
                        "data": {
                            "type": "Plant",
                            "keyRaw": "data",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "code": {
                                        "type": "String",
                                        "keyRaw": "code",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=6392591b9aae07be1bdd5c82729555619344bbc51247663b40f5bf5ccee55d2f";