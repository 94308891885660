export default {
    "name": "CreatePlants",
    "kind": "HoudiniMutation",
    "hash": "8cf252adfdd133a230d9bbb3889ec027183020d4181849a681ed416faba92d92",

    "raw": `mutation CreatePlants($plants: [PlantCreation!]!) {
  createPlants(plants: $plants) {
    id
    name
    code
    private
    street
    city
    state
    zip
    country
    phone
    fax
    timezone
    tags {
      id
      name
      entityType
      active
    }
    getPlantUsageCount {
      analyseCount
      productCount
      tagCount
    }
  }
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "createPlants": {
                "type": "Plant",
                "keyRaw": "createPlants(plants: $plants)",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "name": {
                            "type": "String",
                            "keyRaw": "name",
                            "visible": true
                        },

                        "code": {
                            "type": "String",
                            "keyRaw": "code",
                            "visible": true
                        },

                        "private": {
                            "type": "Boolean",
                            "keyRaw": "private",
                            "visible": true
                        },

                        "street": {
                            "type": "String",
                            "keyRaw": "street",
                            "visible": true
                        },

                        "city": {
                            "type": "String",
                            "keyRaw": "city",
                            "visible": true
                        },

                        "state": {
                            "type": "String",
                            "keyRaw": "state",
                            "visible": true
                        },

                        "zip": {
                            "type": "String",
                            "keyRaw": "zip",
                            "visible": true
                        },

                        "country": {
                            "type": "String",
                            "keyRaw": "country",
                            "visible": true
                        },

                        "phone": {
                            "type": "String",
                            "keyRaw": "phone",
                            "visible": true
                        },

                        "fax": {
                            "type": "String",
                            "keyRaw": "fax",
                            "visible": true
                        },

                        "timezone": {
                            "type": "String",
                            "keyRaw": "timezone",
                            "visible": true
                        },

                        "tags": {
                            "type": "EntityTag",
                            "keyRaw": "tags",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    },

                                    "entityType": {
                                        "type": "EntityTagType",
                                        "keyRaw": "entityType",
                                        "visible": true
                                    },

                                    "active": {
                                        "type": "Boolean",
                                        "keyRaw": "active",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "getPlantUsageCount": {
                            "type": "PlantUsageCount",
                            "keyRaw": "getPlantUsageCount",

                            "selection": {
                                "fields": {
                                    "analyseCount": {
                                        "type": "NonNegativeInt",
                                        "keyRaw": "analyseCount",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "productCount": {
                                        "type": "NonNegativeInt",
                                        "keyRaw": "productCount",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "tagCount": {
                                        "type": "NonNegativeInt",
                                        "keyRaw": "tagCount",
                                        "nullable": true,
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "plants": "PlantCreation"
        },

        "types": {
            "PlantCreation": {
                "city": "String",
                "code": "String",
                "country": "String",
                "fax": "String",
                "logoFileId": "PositiveInt",
                "name": "String",
                "phone": "String",
                "private": "Boolean",
                "state": "String",
                "street": "String",
                "tagIds": "PositiveInt",
                "timezone": "String",
                "zip": "String"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=966dca1d7520860d0d5037d1d25837903e58c26282b445ca30b6faa6a79ace86";