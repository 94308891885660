export default {
    "name": "SetUpdatedValues",
    "kind": "HoudiniMutation",
    "hash": "36ad7e675b129c6ee3a5fe65abaefce819fb5b01d14b38995608d8a0b513e954",

    "raw": `mutation SetUpdatedValues($updateSettingValuesInput: UpdateSettingValuesInput!) {
  setSettingsValues(updateSettingValuesInput: $updateSettingValuesInput)
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "setSettingsValues": {
                "type": "Void",
                "keyRaw": "setSettingsValues(updateSettingValuesInput: $updateSettingValuesInput)",
                "nullable": true,
                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "input": {
        "fields": {
            "updateSettingValuesInput": "UpdateSettingValuesInput"
        },

        "types": {
            "SettingShape": {
                "settingId": "NonNegativeInt",
                "value": "String"
            },

            "UpdateSettingValuesInput": {
                "settings": "SettingShape"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=afdd482f26dc0878d1b7dfc0618e24254a333e2981a764b0dc473378ddc83207";