import Cookies from 'js-cookie'
import sessionStore, { getSession } from 'stores/session'
import { graphql } from '$houdini'

const logOutMutation = graphql(`
	mutation Logout {
		closeSession
	}
`)

export default async function logOut(): Promise<void> {
	const oldSession = getSession()

	if (oldSession?.authToken) {
		try {
			await logOutMutation.mutate(null)
		} catch (err) {
			console.error(err)
		}
	}

	sessionStore.set({})
	Cookies.remove('auth-token')
}
