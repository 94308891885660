export default {
    "name": "AWCopyWorkOrders",
    "kind": "HoudiniMutation",
    "hash": "9bd094af416bbdb1471e81c173853eb26bdd50d310880c211989f2a43d443d9e",

    "raw": `mutation AWCopyWorkOrders($input: [CopyWorkOrderInput!]!) {
  copyWorkOrders(input: $input) {
    success
    lastClonedId
  }
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "copyWorkOrders": {
                "type": "CopyWorkOrderResult",
                "keyRaw": "copyWorkOrders(input: $input)",
                "nullable": true,

                "selection": {
                    "fields": {
                        "success": {
                            "type": "Boolean",
                            "keyRaw": "success",
                            "visible": true
                        },

                        "lastClonedId": {
                            "type": "NonNegativeInt",
                            "keyRaw": "lastClonedId",
                            "nullable": true,
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "input": {
        "fields": {
            "input": "CopyWorkOrderInput"
        },

        "types": {
            "CopyWorkOrderInput": {
                "copyMostRecent": "Boolean",
                "copyValues": "Boolean",
                "id": "PositiveInt"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=65e30fdae7a517a66f1ba3c65b6a408164384131ef83cc835de9c374db28d7ee";