import { QueryStore } from '../runtime/stores/query'
import artifact from '$houdini/artifacts/ShAnalyses'
import { initClient } from '$houdini/plugins/houdini-svelte/runtime/client'

export class ShAnalysesStore extends QueryStore {
	constructor() {
		super({
			artifact,
			storeName: "ShAnalysesStore",
			variables: false,
		})
	}
}

export async function load_ShAnalyses(params) {
	await initClient()

	const store = new ShAnalysesStore()

	await store.fetch(params)

	return {
		ShAnalyses: store,
	}
}
